import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { apiService } from "../../../services/ApiService";
import AccountPageList from "../../blocks/AccountPageList";
import AccountPageMobileList from "../../blocks/AccountPageMobileList";
import TrackOrder from "../AccountPage/TrackOrder";

class TrackYourOrderPage extends Component {
  state = {
    remindMe: false,
  };

  render() {
    const {
      language,
      i18n: { SignOut },
    } = this.props;

    return (
      <Container>
        <div
          className={`account-page ${
            language === "rtl" && "account-page-arabic"
          }`}
        >
          {/* Mobile Side Menu Start */}
          <AccountPageMobileList />
          {/*Mobile Side Menu End */}
          {/*Menu Start */}
          <Row>
            <Col className="board" md={3}>
              {/*username and  logout button start  */}
              {apiService.accessToken !== 0 && (
                <div>
                  <h3>{this.props.currentUser.name}</h3>
                  <a
                    href="/login"
                    className="board__signOut"
                    onClick={() => {
                      localStorage.removeItem("safeerAccessToken");
                      localStorage.removeItem("persist:Safeer");
                    }}
                  >
                    {SignOut}
                  </a>
                </div>
              )}
              {/*username and  logout button end  */}

              {/* web list start  */}
              <AccountPageList />
              {/* web list end  */}
            </Col>
            {/* order section start  */}
            <Col md={9}>
              <TrackOrder />
            </Col>
            {/* order section end  */}
          </Row>
          {/*Menu End */}
        </div>
      </Container>
    );
  }
  closeMenu() {
    this.setState({ menuOpen: false });
  }
  toggleMenu() {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
});
export default connect(mapStateToProps, { setLanguage })(TrackYourOrderPage);
