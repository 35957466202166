import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import Header from "./components/blocks/Header";
import "./assets/style/main.scss";
import HeaderMobile from "./components/blocks/HeaderMobile";
import Home from "./components/Pages/home/Home";
import Footer from "./components/blocks/Footer";
import SignUp from "./components/Pages/SignUp/SignUp";
import Login from "./components/Pages/Login/Login";
import Cart from "./components/Pages/Cart/Cart";
import Checkout from "./components/Pages/Cart/Checkout";
import ProductsCategory from "./components/Pages/productsCategoryPage/ProductsCategory";
import ProductDetails from "./components/Pages/productDetails/ProductDetails";
import ProfilePage from "./components/Pages/profilePage/ProfilePage";
import OrdersPage from "./components/Pages/ordersPage/OrdersPage";
import AddressesPage from "./components/Pages/addressesPage/AddressesPage";
import ReturnsPage from "./components/Pages/returnsPage/ReturnsPage";
import Invoice from "./components/Pages/Invoice/InvoicePage";
import TrackYourOrderPage from "./components/Pages/trackYourOrderPage/TrackYourOrderPage";
import Favourite from "./components/Pages/Favourite/Favourite";
import Search from "./components/Pages/Search/Search";
import Brands from "./components/Pages/Brands/Brands";
import PublicPagesData from "./components/blocks/PublicPagesData";
import { useDispatch, useSelector } from "react-redux";
import { authService } from "./services/AuthService";
// import localIpUrl from "local-ip-url";
import { setUser } from "./redux/actions-reducers/user";
import { useEffect } from "react";
import Compare from "./components/Pages/ComparePage/Compare";
import GeneralProduct from "./components/Pages/GeneralProduct/GeneralProduct";
import BrandProducts from "./components/Pages/Brands/BrandProducts";
import BrandCategoryProducts from "./components/Pages/Brands/BrandCategoryProducts";
import CollectionsProducts from "./components/Pages/collectionsProducts/CollectionsProducts";
import FooterMobile from "./components/blocks/FooterMobile";
import { phoneScreen, tabletScreen } from "./utils/misc";
import Stores from "./components/Pages/Stores/Stores";
import StoreCategoryProducts from "./components/Pages/Stores/StoreCategoryProducts";
import StoreProducts from "./components/Pages/Stores/StoreProducts";

import SocialLogin from "./components/Pages/SignUp/SocialLogin";

function App() {
  const langCode = useSelector((state) => state.language.langCode);
  const guestId = localStorage.getItem("guestId");
  document.documentElement.dir = langCode === "rtl" ? "rtl" : "ltr";
  document.documentElement.lang = langCode === "rtl" ? "ar" : "en";
  document.body.style.textAlign = langCode === "rtl" ? "right" : "left";

  const currentUser = useSelector(({ currentUser }) => currentUser);
  const dispatch = useDispatch();

  const guestLogin = async () => {
    if (guestId) return;
    const { success, data } = await authService.guestLogin({
      device_id: Math.floor(Math.random() * 1000000),
      ip_address: Math.random().toString(),
    });

    if (!success) return;
    localStorage.setItem("guestId", data.guest.id);
    dispatch(setUser({ ...data.guest }));
  };

  useEffect(() => {
    if (!currentUser.last_login && !currentUser.device_id) {
      guestLogin();
    }
  }, [guestLogin, currentUser]);

  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Header />
        <HeaderMobile />
        <Switch>
          <Route component={Home} path="/" exact />
          <Route component={SignUp} path="/SignUp" />
          <Route component={SocialLogin} path="/SocialLogin" />
          <Route component={Login} path="/Login" />
          <Route component={ProductDetails} path="/ProductDetails/:id" />
          <Route
            component={ProductsCategory}
            path="/ProductsCategory/:machineName/:page"
          />
          <Route component={ProfilePage} path="/profile" />
          <Route component={OrdersPage} path="/orders/:page" />
          <Route component={AddressesPage} path="/addresses" />
          <Route component={ReturnsPage} path="/returns" />
          <Route component={TrackYourOrderPage} path="/trackYourOrder/:page" />
          <Route component={Invoice} path="/invoice/:id" />
          <Route component={Cart} path="/Cart" />
          <Route component={Favourite} path="/Favourite" />
          <Route component={Compare} path="/Compare" />
          <Route component={Checkout} path="/Checkout" />
          <Route component={Search} path="/Search/:key/:catId/:page" />
          <Route component={Brands} path="/Brands" />
          <Route component={Stores} path="/Stores" />
          <Route
            component={PublicPagesData}
            path="/PublicPagesData/:machineName"
          />
          <Route
            component={GeneralProduct}
            path="/GeneralProduct/:machineName"
          />
          <Route
            component={CollectionsProducts}
            path="/CollectionProducts/:machineName"
          />

          <Route
            component={BrandCategoryProducts}
            path="/Brand/:category/:machineName/:brandId/:page"
          />
          <Route
            component={BrandProducts}
            path="/BrandProducts/:machineName/:page"
          />

          <Route
            component={StoreCategoryProducts}
            path="/Store/:category/:machineName/:storeId/:page"
          />
          <Route
            component={StoreProducts}
            path="/StoreProducts/:machineName/:page"
          />
        </Switch>
        {tabletScreen ? <FooterMobile /> : <Footer />}
      </BrowserRouter>
    </div>
  );
}

export default App;
