import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { cartService } from "../../../services/CartService";
import FavoriteProductBlock from "./FavoriteProductBlock";
import Skeleton from "react-loading-skeleton";

class Favourite extends Component {
  state = {
    fullView: false,
    cart: [],
    favorites: [],
    payment_methods: [],
    itemsPriceBeforeDiscount: 0,
    itemsPriceAfterDiscount: 0,
    adsData: [
      {
        image: "/images/Group55.png",
        link: "/",
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
    ],
    loaderPage: true,
  };

  componentDidMount() {
    Promise.all([this.getUserFavorites()]);
  }

  getUserFavorites = async () => {
    const { data, success } = await cartService.getUserFavorites();
    if (!success) return;
    this.setState({
      favorites: data,
      loaderPage: false,
    });
  };

  render() {
    const { favorites, loaderPage } = this.state;
    const {
      i18n: { Items, Favourite, favEmpty },
    } = this.props;

    return (
      <div className="Cart">
        <div className="CartTitle">
          <Container>
            <Row>
              <Col>
                <h2>
                  {Favourite}
                  <h4>
                    {favorites.length} {Items}
                  </h4>
                </h2>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <Row>
            {loaderPage ? (
              <>
                <Col md={6}>
                  <Skeleton height={120} />
                </Col>
                <Col md={6}>
                  <Skeleton height={120} />
                </Col>
                <Col md={6}>
                  <Skeleton height={120} className="mt-3" />
                </Col>
              </>
            ) : favorites.length === 0 ? (
              <Col md={12}>
                <div className="empty-products">
                  <img src="/images/Artboard 8.svg" alt="k" />
                  <p> {favEmpty} </p>{" "}
                </div>
              </Col>
            ) : (
              favorites.map((item, index) => {
                return (
                  <Col md={6}>
                    <FavoriteProductBlock
                      data={item}
                      key={index}
                      reFetchData={() => this.getUserFavorites()}
                    />
                  </Col>
                );
              })
            )}
          </Row>
        </Container>
      </div>
    );
  }

  newPrice = (price, discount_percentage, fixed_amount) => {
    let discountAmount = (price * discount_percentage) / 100;
    let priceAfterDiscount = 0;
    if (discountAmount > fixed_amount) {
      priceAfterDiscount = price - fixed_amount;
      return priceAfterDiscount;
    } else {
      priceAfterDiscount = price - discountAmount;
      return priceAfterDiscount;
    }
  };

  changeQty = (qty, index) => {
    const { itemsCount } = this.state;
    const newItemCount = itemsCount;
    newItemCount[index] = qty;
    this.setState({
      itemsCount: newItemCount,
      qtyChangeIndex: index,
    });
  };
  totalItemsCount = () =>
    this.state.itemsCount.reduce((acc, currentValue) => {
      return currentValue + acc;
    }, 0);

  itemsPriceBeforeDiscount = () => {
    const itemsPriceBeforeDiscount = this.state.cart.reduce(
      (acc, currentValue, currentIndex) => {
        return (
          currentValue?.productOption?.price *
            this.state.itemsCount[currentIndex] +
          acc
        );
      },
      0
    );
    return itemsPriceBeforeDiscount;
  };

  itemsPriceAfterDiscount = () => {
    const itemsPriceBeforeDiscount = this.state.cart.reduce(
      (acc, currentValue, currentIndex) => {
        return (
          this.newPrice(
            currentValue?.productOption?.price,
            currentValue?.product_details?.campaign.discount_percentage,
            currentValue?.product_details?.campaign.fixed_amount
          ) *
            this.state.itemsCount[currentIndex] +
          acc
        );
      },
      0
    );

    return itemsPriceBeforeDiscount;
  };

  discountAmount = () => {
    return this.itemsPriceBeforeDiscount() - this.itemsPriceAfterDiscount();
  };
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
});

export default connect(mapStateToProps, { setLanguage })(Favourite);
