import React, { Component } from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { apiService } from "../../../services/ApiService";
import Skeleton from "react-loading-skeleton";
import { tabletScreen } from "../../../utils/misc";

class HomeCarousel extends Component {
  render() {
    const {
      sliders = [],
      sliderLoader,
      // i18n: { DiscoverNow },
      language,
    } = this.props;
    return (
      <div className="home-carousel">
        {sliderLoader ? (
          <Skeleton height={tabletScreen ? 100 : 500} />
        ) : (
          <Carousel
            controls={sliders?.length < 2 ? false : true}
            indicators={sliders?.length < 2 ? false : true}
            interval={null}
          >
            {sliders?.map(
              (
                {
                  image,
                  link,
                  button_text,
                  text,
                  title,
                  mobile_image,
                  alignments,
                },
                index
              ) => {
                return (
                  <Carousel.Item key={index}>
                    <section
                      style={{
                        backgroundImage: `url(${
                          tabletScreen
                            ? apiService.imageLink + mobile_image
                            : apiService.imageLink + image
                        })`,
                        backgroundSize: "100% 100%",
                      }}
                    >
                      <Container>
                        <Row className="align-items-center h-100">
                          <Col
                            className={
                              alignments === "left"
                                ? "d-none"
                                : alignments === "right"
                                ? ""
                                : alignments === "center"
                                ? "d-none"
                                : null
                            }
                            xs={6}
                          ></Col>
                          <Col
                            xs={alignments !== "center" ? 6 : 12}
                            className="d-flex flex-column justify-content-center"
                          >
                            <Carousel.Caption className="w-100">
                              {/* title start  */}
                              {title && <h3 className="w-100">{title}</h3>}
                              {/* title end  */}

                              {/* description  start  */}
                              {text && (
                                <p
                                  className="w-100"
                                  dangerouslySetInnerHTML={{ __html: text }}
                                />
                              )}
                              {/* description  end  */}

                              {/* slider button start  */}
                              {button_text && (
                                <button
                                  className="submit-button"
                                  onClick={() => {
                                    if (link) {
                                      window.open(link, "_blank");
                                    }
                                  }}
                                >
                                  {button_text}
                                </button>
                              )}
                              {/* slider button start  */}
                            </Carousel.Caption>
                          </Col>
                        </Row>
                      </Container>
                    </section>
                  </Carousel.Item>
                );
              }
            )}
          </Carousel>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
});
export default connect(mapStateToProps, { setLanguage })(HomeCarousel);
