import React, { Component } from "react";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setUser } from "../../../redux/actions-reducers/user";
import { productService } from "../../../services/ProductService";
import { apiService } from "../../../services/ApiService";
import { generalServices } from "../../../services/GeneralService";
import { Link } from "react-router-dom";

class FavouriteProductBlock extends Component {
  render() {
    const {
      language,
      data,
      i18n: { Remove, MoveToCart, RemoveFromCart },
      currentUser,
      reFetchData,
    } = this.props;

    return (
      <div
        className={`product fav-product  ${
          language === "rtl" && "product-arabic"
        }`}
      >
        <div className="CartRow">
          <div className="product__img_Container">
            <Link to={`/productDetails/ ${data?.machine_name}`}>
              <img
                src={apiService.imageLink + data?.main_image}
                alt="k"
                className="product__img"
              />
            </Link>
          </div>

          <div className="media-body">
            <Link to={`/productDetails/ + ${data?.machine_name}`}>
              <h5 className="mt-0">{data?.title}</h5>
            </Link>

            <br />
            <div className="CartAction FavAction">
              {data?.option?.qty !== 0 && (
                <div
                  onClick={() => {
                    this.addToCart(
                      data?.option?.id,
                      currentUser.cartIds?.includes(data?.option?.id) ? 0 : 1,
                      reFetchData
                    );
                  }}
                >
                  <img src={`/images/bag.svg`} alt="ss" />
                  &nbsp; &nbsp;
                  {currentUser.cartIds?.includes(data?.option?.id)
                    ? RemoveFromCart
                    : MoveToCart}
                </div>
              )}
              <div
                onClick={() => {
                  this.addRemoveFavorites(
                    data?.id,

                    reFetchData
                  );
                }}
              >
                <img alt="Delete" src="/images/fi-rr-trash.svg" />
                &nbsp; {Remove}
              </div>
            </div>
          </div>
        </div>
        <div className="product__price">
          <div className="product__price__new-price">
            <span>
              <sup>{this.props.country.currency}</sup>{" "}
              {data?.option.price_after_discount}
            </span>
          </div>
        </div>
      </div>
    );
  }
  getCartIDs = async () => {
    const { data, success } = await generalServices.getCartIDs();
    if (!success) return;
    this.props.setUser({
      cartIds: data,
    });
  };
  getFavoritesIDs = async () => {
    const { data, success } = await generalServices.getFavoritesIDs();
    if (!success) return;

    this.props.setUser({
      favoritesId: data,
    });
  };
  getIconData = async () => {
    const { data, success } = await generalServices.getIconData();
    if (!success) return;

    this.props.setUser({
      cart: data.cart_counter,
      fav: data.fav_counter,
      compare: data.compare_counter,
    });
  };

  addToCart = async (id, qty, reFetchData) => {
    const { success } = await productService.addToCart(id, qty);

    if (success) this.getCartIDs();
    if (reFetchData) reFetchData();
  };

  addRemoveFavorites = async (id, reFetchData) => {
    const { success } = await productService.addOrRemoveFavorite(id);
    if (!success) return;
    await reFetchData();
    this.getFavoritesIDs();
    this.getCartIDs();
    this.getIconData();
  };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
  country,
});
export default connect(mapStateToProps, { setUser })(FavouriteProductBlock);
