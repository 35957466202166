import React, { Component } from "react";
import { connect } from "react-redux";
import Global from "../../language/Global.json";
import { setUser } from "../../redux/actions-reducers/user";
import { apiService } from "../../services/ApiService";
import { generalServices } from "../../services/GeneralService";
import { productService } from "../../services/ProductService";
import { displayAlert } from "../../utils/misc";

class Product extends Component {
    state = {
        isFav: this.props.data?.in_favorite,
        // compare: this.props.data?.compare,
        compare: this.props.data?.in_compare,
    };
    render() {
        const {
            data,
            reFetchData,
            language,
            currentUser,
            i18n: { off, outOfStuck, SoldOut },
        } = this.props;

        return (
            <div className={`product  ${language === "rtl" && "product-arabic"}`}>
                <div className="ImgWrap">
                    {/* sold out label start  */}
                    {data.option.qty == 0 && <span className={`${"product-label product-label-soldOut"}`}>{SoldOut}</span>}
                    {/* sold out label end  */}

                    {/* product label start  */}
                    {data.label && data.option.qty > 0 && (
                        <span className={`${"product-label text-capitalize"}`} style={{ backgroundColor: data.label.background_color, color: data.label.text_color }}>
                            {data.label.label}
                        </span>
                    )}
                    {/* product label end  */}

                    {/* fav icon start  */}
                    <div className="product-fav" onClick={() => this.addRemoveFavorites(data?.id, reFetchData)}>
                        {currentUser.favoritesId?.includes(data?.id) ? <img src="/images/heartColor.svg" alt="heart" /> : <img src="/images/heart.svg" alt="heart" />}
                    </div>
                    {/* fav icon end  */}

                    {/* product img start  */}
                    <img onClick={() => (window.location.href = "/productDetails/" + data?.machine_name)} src={data?.main_image ? apiService.imageLink + data?.main_image : "/images/placeholder.png"} alt="k" className="product__img w-100" />
                    {/* product img end  */}
                </div>
                {/* product body start  */}
                <a href={"/productDetails/" + data?.machine_name} className="product__body">
                    {/* product title start  */}
                    <h5 className="text-capitalize">{data?.title} </h5>
                    {/* product title end  */}

                    {/* product description start  */}
                    {/*<strong className="text-capitalize">{data?.store_name} </strong>*/}
                    {/* product description end  */}
                    

                    {/* product description start  */}
                    {/* <p dangerouslySetInnerHTML={{ __html: data?.subtitle }} /> */}
                    {/* product description end  */}

                    <div className="product__price">
                        {/* product price after discount start  */}
                        <div className="product__price__new-price m-0">
                            <span className="m-0">{data?.option.price_after_discount}</span>
                            <span> {this.props.country.currency}</span>
                        </div>
                        {/* product price after discount end  */}

                        {/* product old price and discount percentage start  */}
                        {data?.option.discount_percentage > 0 ?  (
                            <div className="product__price__old-price">
                                <span>{(data?.option.price).toFixed(2)}</span>
                                {
                                    data?.option.discount_percentage  < 100 ? 
<span>
                                    {data?.option.discount_percentage}% {off}
                                </span>
                                    :
<span>
                                    {(data?.option.price).toFixed(2) - parseFloat(data?.option.price_after_discount).toFixed(2)} {this.props.country.currency} {" "} {off}
                                </span>
                                }
                                
                            </div>
                        ): (
                            <div className="product__price__old-price">
                                <div className="empty"></div>
                            </div>
                        )}
                        {/* product old price and discount percentage end  */}
                    </div>
                </a>
                {/* product body end  */}

                {/* product footer start  */}
                <div className="product__footer">
                    <div>
                        {/* add/remove compare start  */}
                        {/* <div onClick={() => this.addRemoveCompares(data?.id, reFetchData)}>
                            <img src={`/images/${currentUser.comparesId?.includes(data?.id) ? "newCompC.svg" : "newComp.svg"}`} alt="ss" />
                        </div> */}
                        {/* add/remove compare end  */}

                        {/* add/remove from cart start  */}
                        <div
                            onClick={() => {
                                if (data?.option?.qty > 0) {
                                    this.addToCart(data?.option.id, currentUser.cartIds?.includes(data?.option?.id) ? 0 : 1, reFetchData);
                                }
                            }}
                            style={data?.option?.qty < 1 ? { cursor: "no-drop" } : null}
                        >
                            {data?.option?.qty > 0 ? <img src={`/images/${currentUser.cartIds?.includes(data?.option?.id) ? "bagC" : "bag"}.svg`} alt="ss" /> : <img src={`/images/qqqq.svg`} alt="ss" style={{ cursor: "no-drop" }} title={outOfStuck} />}
                        </div>
                        {/* add/remove from cart end  */}
                    </div>
                    {/* product rate start  */}
                    {data?.rate > 0 && (
                        <div>
                            <i className="fa fa-star"></i>
                            <span>{data?.rate}</span>
                        </div>
                    )}
                    {/* product rate end  */}
                </div>
                {/* product footer end  */}
            </div>
        );
    }

    // get icon data function start
    getIconData = async () => {
        const { data, success } = await generalServices.getIconData();
        if (!success) return;

        this.props.setUser({
            cart: data.cart_counter,
            fav: data.fav_counter,
            compare: data.compare_counter,
        });
    };

    // get fav IDs function start
    getFavoritesIDs = async () => {
        const { data, success } = await generalServices.getFavoritesIDs();
        if (!success) return;

        this.props.setUser({
            favoritesId: data,
        });
    };

    // get  compare IDs function start
    getComparesIDs = async () => {
        const { data, success } = await generalServices.getComparesIDs();
        if (!success) return;
        this.props.setUser({
            comparesId: data,
        });
    };

    // get CartIDs function start
    getCartIDs = async () => {
        const { data, success } = await generalServices.getCartIDs();
        if (!success) return;
        this.props.setUser({
            cartIds: data,
        });
    };

    // add/remove fav function start
    addRemoveFavorites = async (id, reFetchData) => {
        const { success } = await productService.addOrRemoveFavorite(id);
        if (!success) return;
        this.getFavoritesIDs();
        reFetchData();
        this.getIconData();
    };

    // add/remove compare function start
    addRemoveCompares = async (id, reFetchData) => {
        const { compare } = this.state;
        const { success } = await productService.addRemoveCompares(id);
        const { success: successText, CompareProductDeleted, CompareProductAdded } = this.props.i18n;
        if (!success) return;
        this.setState({ compare: !this.state.compare });
        displayAlert(successText, compare ? CompareProductDeleted : CompareProductAdded, "success", "noButtons", 2000);

        this.getComparesIDs();
        reFetchData();
        this.getIconData();
    };

    // add to cart function start
    addToCart = async (id, qty, reFetchData) => {
        const { success } = await productService.addToCart(id, qty);
        const { success: successText, DeletedFromCart, AddedToCart } = this.props.i18n;
        if (!success) return;

        this.getIconData();

        displayAlert(successText, qty === 0 ? DeletedFromCart : AddedToCart, "success", "noButtons", 2000);

        this.getCartIDs();
        reFetchData();
        this.getIconData();
    };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
    country,
});
export default connect(mapStateToProps, { setUser })(Product);
