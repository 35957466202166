import { apiEndPoints } from "../api";
import { handleResponse } from "../utils/misc";
import { apiService } from "./ApiService";

class AuthService {
  async login(userData) {
    try {
      const data = await apiService
        .unauthenticated()
        .post(apiEndPoints.auth.login, userData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
  async socialLogin(userData) {
    try {
      const data = await apiService
        .unauthenticated()
        .post(apiEndPoints.auth.socialLogin, userData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
  async guestLogin(guestData) {
    try {
      const data = await apiService
        .unauthenticated()
        .post(apiEndPoints.auth.guestLogin, guestData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  async SignUp(userData) {
    try {
      const data = await apiService
        .unauthenticated()
        .post(apiEndPoints.auth.signUp, userData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  logout() {
    window.localStorage.clear();
    window.location.href = "/login";
  }

  async forgetPassword(email) {
    try {
      const data = await apiService
        .unauthenticated()
        .post(apiEndPoints.auth.forgetPassword, email)
        .then(({ data }) => data);
        console.log(data);
      if (data.status) return handleResponse({ success: true, ...data });
      return handleResponse({ success: false, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  async verifyForgetPassword(email, otp) {
    try {
      const data = await apiService
        .unauthenticated()
        .post(apiEndPoints.auth.verifyForgetPassword, email)
        .then(({ data }) => data);
        console.log(data);
      if (data.status) return handleResponse({ success: true, ...data });
      return handleResponse({ success: false, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
  async resetForgetPassword(email) {
    try {
      const data = await apiService
        .unauthenticated()
        .post("resetForgetPassword", email)
        .then(({ data }) => data);
        console.log(data);
      if (data.status) return handleResponse({ success: true, ...data });
      return handleResponse({ success: false, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
}

export const authService = new AuthService();
