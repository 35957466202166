import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Dropdown,
  Pagination,
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { userService } from "../../../services/UserService";
import TextInput from "../../blocks/TextInput";
import OrderCard from "./OrderCard";
import PaginatedItems from "../../Pages/Search/PaginationBloack";
import { withRouter } from "react-router-dom";
import moment from "moment";

class Orders extends Component {
  state = {
    StatusCase: this.props.i18n.All,
    color: "",
    orders: [],
    fields: {
      to: "",
      from: "",
      key: "",
      status: "",
    },
    allStatus: [
      {
        className: "All",
        name: this.props.i18n.All,
        id: 0,
      },
      {
        className: "canceled",
        name: this.props.i18n.Canceled,
        id: -2,
      },
      {
        className: "arraived",
        name: this.props.i18n.Arraived,
        id: 4,
      },
      {
        className: "on_the_way",
        name: this.props.i18n.OnTheWay,
        id: 3,
      },
      {
        className: "accepted",
        name: this.props.i18n.Accepted,
        id: 2,
      },
      {
        className: "complete",
        name: this.props.i18n.Complete,
        id: 5,
      },
      {
        className: "pending",
        name: this.props.i18n.Pending,
        id: 1,
      },
    ],
    currentPage: this.props.match.params.page,
    loader: true,
    meta: {},
  };

  componentDidMount() {
    this.getUserOrders(1);
  }
  getUserOrders = async (page) => {
    const {
      fields: { from = "", to = "", key = "", status = "" },
      StatusCase,
    } = this.state;

    const { data, success, meta } = await userService.getUserOrders(
      page ? page : this.props.match.params.page,
      {
        from_date: from,
        to_date: to,
        search_key: key,
        status,
      }
    );
    if (!success) return;
    this.setState({
      orders: data,
      pageLoader: false,
      meta: meta,
      loader: false,
    });
  };
  render() {
    const {
      language,
      i18n: {
        Orders,
        OrdersTitle2,
        Returned,
        BuyDate,
        From,
        To,
        SortBy,
        search,
        Pending,
        Complete,
        Canceled,
        ordersEmpty,
        All,
        filterBy,
        Arraived,
        OnTheWay,
        Accepted,
      },
    } = this.props;
    const { StatusCase, orders, fields, meta, allStatus } = this.state;
    return (
      <Container>
        <div className={`orders ${language === "rtl" && "orders-arabic"}`}>
          <div className="account-page__title">
            <h3>{Orders}</h3>
            <h4>{OrdersTitle2}</h4>
          </div>

          <div className="filter-bar">
            <div className="search-section">
              <TextInput
                SearchImage
                placeholder={search}
                name="key"
                value={fields.key}
                onFieldChange={this.onFieldChange}
              />
            </div>

            <div className="date-section">
              <p className="buy-date">{BuyDate}</p>
              <InputGroup>
                <label
                  for="html"
                  className="input-group-text input-group-text1"
                >
                  {From}
                </label>
                <FormControl
                  id="html"
                  type="date"
                  name="from"
                  value={fields.from}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  className={`input1 ${fields.from ? "text-primary" : ""}`}
                  onChange={({ target: { name, value } }) =>
                    this.onFieldChange(name, value)
                  }
                  max={moment().endOf("day").toISOString().split("T")[0]}  
                />
              </InputGroup>
              {/* from date input end  */}

              {/* to date input start  */}
              <InputGroup>
                <label for="To" className="input-group-text input-group-text2 ">
                  {To}
                </label>
                <FormControl
                  id="To"
                  type="date"
                  name="to"
                  value={fields.to}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  className={`input2 ${fields.to ? "text-primary" : ""}`}
                  onChange={({ target: { name, value } }) =>
                    this.onFieldChange(name, value)
                  }
                  max={moment().endOf("day").toISOString().split("T")[0]}  
                  min={fields.from}

                />
              </InputGroup>
              {/* to date input start  */}
            </div>
            {/* filter by Date Section end */}

            {/* filter by status Section Start */}
            <div className="select-section">
              <p className="sort-by">{filterBy}</p>
              <Dropdown>
                {/* current filter status start  */}
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  <div className="status-case">
                    {" "}
                    <p
                      style={
                        StatusCase === OnTheWay
                          ? { color: "#000957" }
                          : StatusCase === Arraived
                          ? { color: "#B983FF" }
                          : StatusCase === Accepted
                          ? { color: "#116530" }
                          : StatusCase === Pending
                          ? { color: "#ff8000" }
                          : StatusCase === Returned
                          ? { color: "red" }
                          : StatusCase === Complete
                          ? { color: "#00FF66" }
                          : StatusCase === All
                          ? { color: "#752157" }
                          : StatusCase === Canceled
                          ? { color: "gray" }
                          : null
                      }
                      className="status-label"
                    >
                      {" "}
                      {StatusCase}{" "}
                    </p>
                    <i
                      style={
                        StatusCase === OnTheWay
                          ? { color: "#000957" }
                          : StatusCase === Arraived
                          ? { color: "#B983FF" }
                          : StatusCase === Accepted
                          ? { color: "#116530" }
                          : StatusCase === Pending
                          ? { color: "#ff8000" }
                          : StatusCase === Returned
                          ? { color: "red" }
                          : StatusCase === Complete
                          ? { color: "#00FF66" }
                          : StatusCase === Canceled
                          ? { color: "gray" }
                          : StatusCase === All
                          ? { color: "#752157" }
                          : null
                      }
                      className="fa fa-circle circle-icon"
                    ></i>
                    <i className="fa fa-chevron-down chevron-icon"></i>
                  </div>
                </Dropdown.Toggle>
                {/* current filter status end  */}
                {/* status list start  */}
                <Dropdown.Menu>
                  {allStatus.map(({ className, name, id }) => {
                    return (
                      <Dropdown.Item
                        className={className}
                        onClick={() =>
                          this.setState(
                            {
                              StatusCase: name,
                              loader: true,
                              fields: {
                                ...fields,
                                loader: true,
                                status: id,
                              },
                            },
                            () => this.getUserOrders(1)
                          )
                        }
                      >
                        {name} <i className="fa fa-circle circle-icon"></i>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
                {/* status list end  */}
              </Dropdown>
            </div>
            {/* filter by status Section end */}
          </div>
          {/* Filter-Bar End */}

          {/* Order cards start */}
          {this.state.loader ? (
            <>
              <Skeleton height={200} className="mt-3" />
              <Skeleton height={200} className="mt-3" />
              <Skeleton height={200} className="mt-3" />
              <Skeleton height={200} className="mt-3" />
              <Skeleton height={200} className="mt-3" />
            </>
          ) : orders.length > 0 ? (
            orders?.map((item, index) => {
              return <OrderCard key={index} data={item} />;
            })
          ) : (
            <Col md={12}>
              <div className="empty-products">
                <img src="/images/Artboard 6.svg" alt="k" />
                <p> {ordersEmpty} </p>
              </div>
            </Col>
          )}
          {/* Order cards End */}
        </div>

        {/** Pagination Start */}
        {this.state.loader ? null : meta.last_page > 1 ? (
          <Row>
            <Col md={12}>
              <div className="Pagination">
                <PaginatedItems
                  items={[...Array(meta?.total).keys()]}
                  itemsPerPage={meta?.per_page}
                  changePage={(page) => this.changePage(page)}
                />
              </div>
            </Col>
          </Row>
        ) : null}
        {/** Pagination End */}
      </Container>
    );
  }

  toTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  changePage(page) {
    this.toTop();
    this.props.history.push(`/orders/${page + 1}`);
    this.setState(
      {
        pageLoader: true,
      },
      () => {
        this.getUserOrders();
      }
    );
  }

  nextPage = (index) => {
    this.setState(
      {
        currentPage: index,
        loader: true,
      },
      () => this.getUserOrders(index)
    );
  };

  // on field change start
  onFieldChange = (name, value) => {
    const { fields } = this.state;
    this.setState(
      {
        fields: { ...fields, [name]: value },
      },
      () => {
        this.getUserOrders(this.state.currentPage);
      }
    );
  };

  // on field change end
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
});
export default connect(mapStateToProps, { setLanguage })(withRouter(Orders));
