import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { setLanguage } from "../../redux/actions-reducers/language";
import Skeleton from "react-loading-skeleton";
import { tabletScreen } from "../../utils/misc";
import { generalServices } from "../../services/GeneralService";
import Global from "./../../language/Global.json";
class PublicPagesData extends Component {
    state = {
        data: {},
    };
    componentDidMount() {
        this.getPublicPagesDataDetails();
    }

    componentDidUpdate(prevProps) {
        prevProps.location.pathname !== window.location.pathname && this.getPublicPagesDataDetails();
    }

    async getPublicPagesDataDetails() {
        const { success, data } = await generalServices.getPublicPagesDataDetails(this.props.match.params.machineName);
        if (!success) return;
        this.setState({
            data: data,
        });
    }

    render() {
        const { language } = this.props;
        const { data } = this.state;
        return (
            <Container>
                <div className={`Public-pages-data  ${language === "rtl" && "Public-pages-data-arabic"}`}>
                    {data?.length < 1 ? (
                        <Row>
                            <div className="title">
                                {" "}
                                <Skeleton height={tabletScreen ? 100 : 56}></Skeleton>
                            </div>
                            <Skeleton height={tabletScreen ? 100 : 112}></Skeleton>
                        </Row>
                    ) : (
                        <Row>
                            {/* Title start */}
                            <div className="title">
                                <h3>{data.name}</h3>
                            </div>
                            {/* Title end */}

                            {/* Text Start */}
                            <p dangerouslySetInnerHTML={{ __html: data.text }}></p>
                            {/* Text End */}
                        </Row>
                    )}
                </div>
            </Container>
        );
    }
}

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
});
export default connect(mapStateToProps, { setLanguage })(PublicPagesData);
