import React, { Component } from "react";
import {
  Col,
  Container,
  Row,
  Pagination,
  DropdownButton,
} from "react-bootstrap";
import Product from "../../blocks/Product";
import Filter from "../productsCategoryPage/Filter";
import HomeCarousel from "../home/HomeCarousel";
import ProductFullView from "../../blocks/ProductFullView";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import FilterModal from "./FilterModal";
import { tabletScreen } from "../../../utils/misc";
import { categoryService } from "../../../services/CategoryService";
import { generalServices } from "../../../services/GeneralService";
import { apiService } from "../../../services/ApiService";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Dropdown from "@restart/ui/esm/Dropdown";
import SubCategories from "./SubCategories";
import PaginatedItems from "../../Pages/Search/PaginationBloack";
import { homeService } from "../../../services/homeService";

class ProductsCategory extends Component {
  state = {
    products: [],
    products2: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    fullView: false,
    ShowFilter: false,
    sliders: [],
    adsData: {},
    adsLoader: true,
    ReviewData: [],
    filterModal: false,
    sideFilterShow: true,
    filterData: {},
    brands: [],
    price_from: "",
    price_to: "",
    rate: "",
    options_values: [],
    free_shipping: false,
    new_arrivals: false,
    sliderLoader: true,
    meta: {},
    currentPage: 1,
    dropdownOpen: false,
    SortByCase: this.props.i18n.Recommended,
    DisplayCase: 50,
    imageSwitch: 1,
    isFilterModalShow: false,
    lastFilteredData: {},
    resetFilter: false,
    display: "",
  };
  componentDidMount() {
    Promise.all([
      this.getSlider(),
      this.getSingleCategoryProducts(
        1,
        [],
        [],
        0,
        0,
        999999999,
        false,
        false,
        50,
        false
      ),
      this.getSingleCategoryInfoAndFilters(),
      this.getSingleAds(),
    ]);
  }

  getSingleCategoryInfoAndFilters = async () => {
    // console.log(this.props.match.params.machineName, "lll");
    const { data, success } =
      await categoryService.getSingleCategoryInfoAndFilters(
        this.props.match.params.machineName
      );
    // console.log("data", data);

    if (!success) return;
    // console.log(data, "data");
    this.setState({
      filterData: data,
     
    });
  };
  getSlider = async () => {
    const { data, success } = await generalServices.getSlider(
      this.props.match.params.machineName
    );
    if (!success) return;
    this.setState({
      sliders: data,
      sliderLoader: false,
    });
  };

  getSingleCategoryProducts = async (
    page,
    options_values,
    brands,
    rate,
    price_from,
    price_to,
    free_shipping,
    new_arrivals,
    DisplayCase,
    discount
  ) => {
    const { data, success, meta } =
      await categoryService.getSingleCategoryProducts({
        machine_name: this.props.match.params.machineName,
        page,
        options_values,
        brands,
        rate,
        price_from,
        price_to,
        free_shipping,
        new_arrivals,
        sort: this.state.sort,
        display: DisplayCase,
        has_discount: discount,
      });
    if (!success) return;
    this.setState({
      products: data,
      meta: meta,
      new_arrivals: false,
      discount: false,
    });
  };
  toTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  changePage(page) {
    // this.toTop();
    this.props.history.push(
      `/productsCategory/${this.props.match.params.machineName}/${page + 1}`
    );

    this.nextPage(
      page + 1,
      this.state.options_values,
      this.state.brands,
      this.state.rate,
      this.state.price_from,
      this.state.price_to,
      this.state.free_shipping,
      this.state.new_arrivals,
      this.state.DisplayCase
    );
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  render() {
    const {
      products,
      adsData,
      ShowFilter,
      sliders,
      filterData,
      adsLoader,
      meta,
      sliderLoader,
      SortByCase,
      DisplayCase,
      imageSwitch,
      resetFilter,
    } = this.state;
    const {
      language,
      i18n: { Categories, Items, Date, all, SortBy, Display },
    } = this.props;
    const closeModal = () => {
      this.setState({ isFilterModalShow: false });
    };

    return (
      <div
        className={`products-category  ${ShowFilter === true && "podMob"}  ${
          language === "rtl" && "products-category-arabic"
        }`}
      >
        {sliders.length > 0 && (
          <HomeCarousel sliders={sliders} sliderLoader={sliderLoader} />
        )}

        <Container>
          {/* Filter Modal veiw if  filterModal state equals true*/}

          {this.state.isFilterModalShow && (
            <FilterModal
              filterModal={true}
              closeModal={closeModal}
              getFilterData={this.getFilterData}
              data={filterData}
            />
          )}

          <Row className="Category-ViewOption">
            <Col md={3}>
              <div className="CategoryTitle">
                <h1 className="mb-2">{filterData?.categoryInfo?.name} </h1>
                <h6>
                  {meta?.total} {Items}
                </h6>
              </div>
            </Col>
            <div className="d-md-none">
              <SubCategories subCats={this.state.filterData.subCategories} />
            </div>
            <Col md={9}>
              <div
                className={`ProductView ${
                  language === "rtl" && "ProductView-arabic"
                } `}
              >
                <ul>
                  {/* sort select start  */}
                  <li>
                    <div className="sortBy-and-display-select">
                      <p>{SortBy}</p>
                      <DropdownButton
                        id="dropdown-basic-button"
                        title={SortByCase}
                      >
                        {filterData.sortTypes?.map(({ name, value }) => {
                          return (
                            <Dropdown.Item
                              onClick={() => {
                                this.setState(
                                  {
                                    SortByCase: name,
                                    sort: value,
                                    resetFilter: true,
                                  },
                                  () => {
                                    this.getSingleCategoryProducts(
                                      1,
                                      [],
                                      [],
                                      0,
                                      0,
                                      999999999,
                                      false,
                                      false,
                                      50,
                                      false
                                    );
                                  }
                                );
                              }}
                            >
                              {name}
                            </Dropdown.Item>
                          );
                        })}
                      </DropdownButton>
                    </div>
                  </li>
                  {/* sort select end  */}

                  {/* display select start  */}
                  <li>
                    {" "}
                    <div className="sortBy-and-display-select">
                      <p>{Display}</p>
                      <DropdownButton
                        id="dropdown-basic-button"
                        title={DisplayCase}
                        onChange={({ target: { value } }) => {}}
                      >
                        <Dropdown.Item
                          onClick={() => {
                            this.setState(
                              {
                                DisplayCase: 50,
                                resetFilter: true,
                              },
                              () =>
                                this.getSingleCategoryProducts(
                                  1,
                                  [],
                                  [],
                                  0,
                                  0,
                                  999999999,
                                  false,
                                  false,
                                  50,
                                  false
                                )
                            );
                          }}
                          value={50}
                        >
                          50
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            this.setState(
                              {
                                DisplayCase: 100,
                                resetFilter: true,
                              },
                              () =>
                                this.getSingleCategoryProducts(
                                  1,
                                  [],
                                  [],
                                  0,
                                  0,
                                  999999999,
                                  false,
                                  false,
                                  100,
                                  false
                                )
                            );
                          }}
                          value={100}
                        >
                          100
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            this.setState(
                              {
                                DisplayCase: 150,
                                resetFilter: true,
                              },
                              () =>
                                this.getSingleCategoryProducts(
                                  1,
                                  [],
                                  [],
                                  0,
                                  0,
                                  999999999,
                                  false,
                                  false,
                                  150,
                                  false
                                )
                            );
                          }}
                          value={150}
                        >
                          150
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </li>
                  {/* display select end  */}
                </ul>

                {/* product view icon (Grid , full) start  */}
                <ul className="grid-filter-full-ul">
                  {/** Grid View */}
                  <li
                    onClick={() =>
                      this.setState({
                        fullView: false,
                        imageSwitch: 1,
                      })
                    }
                    className={this.state.fullView ? "not-active" : "active"}
                  >
                    <a>
                      {/* <i className="fa fa-th"></i> */}
                      <img
                        src={`${
                          imageSwitch === 1
                            ? "/images/sq.svg"
                            : "/images/sqIn.svg"
                        } `}
                        alt=""
                      />
                    </a>
                  </li>
                  {/** Grid View End */}

                  {/** Full View */}
                  <li
                    onClick={() =>
                      this.setState({
                        fullView: true,
                        imageSwitch: 2,
                      })
                    }
                    className={this.state.fullView ? "active" : "not-active"}
                  >
                    <a>
                      {/* <i className="fa fa-align-justify"></i> */}

                      <img
                        src={`${
                          imageSwitch === 2
                            ? "/images/horC.svg"
                            : "/images/row.svg"
                        } `}
                        alt=""
                      />
                    </a>
                  </li>
                  {/** Full View */}

                  {/* Filter Modal (in mobile view)*/}
                  <li
                    onClick={() =>
                      this.setState({
                        isFilterModalShow: true,
                      })
                    }
                    className="filter-icon"
                  >
                    <a>
                      <img
                        src="/images/filtergray.svg"
                        width="25"
                        height="23"
                      />
                    </a>
                  </li>
                  {/* Filter Modal (in mobile view) end*/}
                </ul>
                {/* product view icon (Grid , full) start  */}
              </div>
            </Col>
          </Row>
          {/** Category Name And View Option End */}

          <Row>
            {/* {!tabletSceen && ( */}
            <Col md={3} xl={3} className="products-filter-component">
              {/* subcategory filter start  */}
              {this.state.filterData.subCategories?.length > 0 && (
                <SubCategories subCats={this.state.filterData.subCategories} />
              )}
              {/* subcategory filter end  */}

              {/* filters start  */}
              <Filter
                resetFilter={resetFilter}
                onResetFilter={(x) => {
                  this.setState(x, () =>
                    this.getSingleCategoryProducts(
                      1,
                      [],
                      [],
                      0,
                      0,
                      999999999,
                      false,
                      false,
                      150,
                      false
                    )
                  );
                }}
                data={filterData}
                getFilterData={this.getFilterData}
              />
              {/* filters end  */}

              {/* {this.state.sideFilterShow && } */}
            </Col>
            {/* )} */}

            <Col md={9} className="ProductCategoriesWrap">
              <Row>
                {/* category products (just a first 4 products  like a design)  start  */}
                {products
                  ?.filter((_, index) => index < 4)
                  ?.map((item) => (
                    <Col
                      lg={this.state.fullView ? 12 : 3}
                      md={this.state.fullView ? 12 : 6}
                      xl={this.state.fullView ? 12 : 3}
                      xs={this.state.fullView ? 12 : 6}
                    >
                      {this.state.fullView ? (
                        <ProductFullView
                          data={item}
                          reFetchData={() =>
                            this.getSingleCategoryProducts(
                              1,
                              [],
                              [],
                              0,
                              0,
                              999999999,
                              false,
                              false,
                              50,
                              false
                            )
                          }
                        />
                      ) : (
                        <Product
                          data={item}
                          reFetchData={() =>
                            this.getSingleCategoryProducts(
                              1,
                              [],
                              [],
                              0,
                              0,
                              999999999,
                              false,
                              false,
                              50,
                              false
                            )
                          }
                        />
                      )}
                    </Col>
                  ))}
                {/* category products end  */}

                {/* ads section start  */}
                {/* <AdsSection data={adsData} /> */}
                {adsLoader ? (
                  <Row className="mx-0 mb-3">
                    <Col className="p-0">
                      <Skeleton height={tabletScreen ? 110 : 200} />
                    </Col>
                  </Row>
                ) : (
                  <Row className="no-margin">
                    {adsData?.ad_image && (
                      <Col xs={12} className=" no-padd">
                        <div
                          onClick={() => {
                            if (adsData?.can_click) {
                              this.visitAdsLink(adsData?.link, adsData?.id);
                            }
                          }}
                        >
                          <img
                            className="products-category__ads"
                            src={apiService.imageLink + adsData?.ad_image}
                            alt="l"
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                )}
                {products
                  .filter((_, index) => index > 3)
                  .map((item) => (
                    <Col
                      lg={this.state.fullView ? 12 : 3}
                      md={this.state.fullView ? 12 : 6}
                      xl={this.state.fullView ? 12 : 3}
                      xs={this.state.fullView ? 12 : 6}
                    >
                      {this.state.fullView ? (
                        <ProductFullView
                          data={item}
                          reFetchData={() =>
                            this.getSingleCategoryProducts(
                              1,
                              [],
                              [],
                              0,
                              0,
                              999999999,
                              false,
                              false,
                              50,
                              false
                            )
                          }
                        />
                      ) : (
                        <Product
                          data={item}
                          reFetchData={() =>
                            this.getSingleCategoryProducts(
                              1,
                              [],
                              [],
                              0,
                              0,
                              999999999,
                              false,
                              false,
                              50,
                              false
                            )
                          }
                        />
                      )}
                    </Col>
                  ))}
                {/*category  products  end  */}
              </Row>

              {/** Pagination Start */}
              {meta?.last_page > 1 && (
                <Row>
                  <Col>
                    <div className="Pagination">
                      <PaginatedItems
                        items={[...Array(meta?.total).keys()]}
                        itemsPerPage={this.state.DisplayCase}
                        changePage={(page) => this.changePage(page)}
                        onClick={window.scroll({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        })}
                      />
                    </div>
                  </Col>
                </Row>
              )}
              {/** Pagination End */}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  getSingleAds = async () => {
    // categories_block_full_space
    const { data, success } = await generalServices.getSingleAds(
      "categories_block_full_space",
      this.props.match.params.machineName,
      this.props.country.id
    );
    if (!success) return;
    this.setState({
      adsData: data,
      adsLoader: false,
    });
  };

  nextPage = (
    _index,
    options_values,
    brands,
    rate,
    price_from,
    price_to,
    free_shipping,
    new_arrivals,
    DisplayCase,
    discount
  ) => {
    this.setState({
      currentPage: _index,
    });
    this.getSingleCategoryProducts(
      _index,
      options_values,
      brands,
      rate,
      price_from,
      price_to,
      free_shipping,
      new_arrivals,
      DisplayCase,
      discount
    );
  };
  // get Filter Data function start
  getFilterData = async (filterData) => {
    this.setState(
      {
        options_values: filterData.options_values,
        brands: filterData.brands,
        rate: filterData.rate,
        price_from: filterData.price_from,
        price_to: filterData.price_to,
        free_shipping: filterData.free_shipping,
        new_arrivals: filterData.new_arrivals,
        discount: filterData.discount,
      },
      () => {
        this.getSingleCategoryProducts(
          1,
          this.state.options_values,
          this.state.brands,
          this.state.rate,
          this.state.price_from,
          this.state.price_to,
          this.state.free_shipping,
          this.state.new_arrivals,
          this.state.DisplayCase,
          this.state.discount
        );
      }
    );
  };
  // get Filter Data function end

  visitAdsLink = async (url, id) => {
    const { data, success } = await homeService.visitAdsLink(id);
    if (!success) return;

    window.open(url, "target");
  };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
  country,
});

export default connect(mapStateToProps, { setLanguage })(ProductsCategory);
