import React, { Component } from "react";
import { Container, Row, Col, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { setUser } from "../../../redux/actions-reducers/user";
import { userService } from "../../../services/UserService";
import { displayAlert, getResponseErrors } from "../../../utils/misc";
import Label from "../../blocks/Label";
import SelectInput from "../../blocks/SelectInput";
import TextInput from "../../blocks/TextInput";
class Profile extends Component {
    state = {
        remindMe: false,
        userInfo: {},
        languages: [
            { label: "English", value: 1 },
            { label: "العربية", value: 2 },
        ],
        generalFields: {
            first_name: "",
            last_name: "",
            language_id: "",
            Phone: "",
            email: "",
        },
        securityFields: {
            old_password: "",
            password: "",
            password_confirmation: "",
        },
        errors: {
            name: "",
            language_id: "",
            old_password: "",
            password: "",
            password_confirmation: "",
        },
    };

    componentDidMount() {
        this.getUserInformation();
    }

    getUserInformation = async () => {
        const { data, success } = await userService.getUserInformation();
        if (!success) return;
        this.setState({
            userInfo: data,

            generalFields: {
                ...this.state.generalFields,
                first_name: data.name.split(" ").filter((item) => item !== "")[0] ?? "",
                last_name: data.name.split(" ").filter((item) => item !== "")[1] ?? "",
                email: data.email,
                language_id: this.state.languages.find(({ value }) => data.language_id === value),
                Phone: data.phone,
            },
            securityFields: {
                // password: data.email,
                // confirmPassword: data.email,
            },
        });
    };

    render() {
        const {
            language,
            i18n: { FirstName, LastName, oldPassword, Password, Email, ConfirmPassword, Save, Profile, ManageProfileDetails, GeneralInformation, Security, PreferredLanguage, Phone },
        } = this.props;
        const { languages, generalFields, securityFields, errors } = this.state;
        return (
            <Container>
                <div className={`profile  ${language === "rtl" && "profile-arabic"}`}>
                    {/* title start */}
                    <div className="account-page__title">
                        <h3>{Profile}</h3>
                        <h4>{ManageProfileDetails}</h4>
                    </div>
                    {/* title start */}

                    {/* general information Start section */}
                    <div className="general-info">
                        <h2 className="general-info__title">{GeneralInformation}</h2>
                        <Row>
                            {/* First Name input start  */}
                            <Col lg={6} md={6}>
                                <TextInput name="first_name" label={FirstName} placeholder={FirstName} value={generalFields.first_name} onFieldChange={this.onFieldChange} validate={errors.name} />
                            </Col>
                            {/* First Name input end  */}

                            {/* Last Name input start  */}
                            <Col lg={6} md={6}>
                                <TextInput name="last_name" label={LastName} placeholder={LastName} value={generalFields.last_name} onFieldChange={this.onFieldChange} />
                            </Col>
                            {/* Last Name input end  */}

                            {/* Email input start  */}
                            <Col lg={6} md={12}>
                                <TextInput name="email" label={Email} placeholder={Email} value={generalFields.email} onFieldChange={this.onFieldChange} validate={errors.email} />
                            </Col>
                            {/* Email input end  */}

                            {/* phone input start  */}
                            <Col lg={6} md={12}>
                                <div className={`text-input-block phone-input `}>
                                    <Label label={Phone} tooltip={""} isRequired={false} />

                                    <InputGroup>
                                        <input
                                            style={{ fontWeight: "600" }}
                                            type="number"
                                            placeholder={Phone}
                                            name="Phone"
                                            onInput={({ target }) => (target.value = Math.max(0, parseInt(target.value)).toString().slice(0, this.props.country.digits))}
                                            onChange={({ target: { value } }) =>
                                                this.setState({
                                                    generalFields: {
                                                        ...generalFields,
                                                        Phone: value,
                                                    },
                                                })
                                            }
                                            value={generalFields.Phone}
                                            validate={errors.Phone}
                                        />
                                    </InputGroup>
                                </div>
                            </Col>
                            {/* phone input end  */}

                            {/* Preferred Language start  */}
                            <Col lg={6} md={12}>
                                <SelectInput
                                    name="language_id"
                                    label={PreferredLanguage}
                                    placeholder={PreferredLanguage}
                                    value={generalFields.language_id}
                                    onFieldChange={this.onFieldChange}
                                    // isTextArea
                                    data={languages}
                                    validate={errors.language_id}
                                />
                            </Col>
                            {/* Preferred Language end  */}

                            {/* edit profile button start  */}
                            <div className="button-container">
                                <button className="submit-button2" onClick={this.submitGeneralInfo}>
                                    {Save}
                                </button>
                            </div>
                            {/* edit profile button end  */}
                        </Row>
                    </div>
                    {/* general information End section */}

                    {/* Security section Start (change password) */}
                    <div className="Security">
                        <h2 className="Security__title">{Security}</h2>
                        <Row>
                            {/* old password input start  */}
                            <Col lg={4} md={12}>
                                <TextInput type="password" name="old_password" label={oldPassword} placeholder={oldPassword} value={securityFields.old_password} onFieldChange={this.onSecurityFieldChange} validate={errors.old_password} withImg />
                            </Col>
                            {/* old password input end  */}

                            {/* new password input start  */}
                            <Col lg={4} md={6}>
                                <TextInput name="password" label={Password} placeholder={Password} value={securityFields.password} onFieldChange={this.onSecurityFieldChange} type="password" validate={errors.password} withImg />
                            </Col>
                            {/* new password input end  */}

                            {/* Confirm new password input start  */}
                            <Col lg={4} md={6}>
                                <TextInput name="password_confirmation" placeholder={ConfirmPassword} withImg label={ConfirmPassword} type="password" value={securityFields.password_confirmation} onFieldChange={this.onSecurityFieldChange} validate={errors.password_confirmation} />
                            </Col>
                            {/* Confirm new password input end  */}

                            {/* change password button start  */}
                            <div className="button-container">
                                <button onClick={this.submitSecurityInfo} className="submit-button2">
                                    {Save}
                                </button>
                            </div>
                            {/* change password button end  */}
                        </Row>
                    </div>
                    {/* Security section end (change password) */}
                </div>
            </Container>
        );
    }
    // onFieldChange function start
    onFieldChange = (name, value) => {
        const { generalFields } = this.state;
        this.setState({
            generalFields: { ...generalFields, [name]: value },
            errors: {
                first_name: "",
                last_name: "",
                language_id: "",
                Phone: "",
            },
        });
    };
    // onFieldChange function end

    // on Security Field Change function (password) start

    onSecurityFieldChange = (name, value) => {
        const { securityFields } = this.state;
        this.setState({
            securityFields: { ...securityFields, [name]: value },
            errors: {
                password: "",
                old_password: "",
                password_confirmation: "",
            },
        });
    };
    // on Security Field Change function (password) end

    // submit Genera lInfo function start
    submitGeneralInfo = async () => {
        const { generalFields } = this.state;
        const { success: successText, ProfileUpdatedSuccessfully } = this.props.i18n;
        const { success, message, errors } = await userService.updateGeneralInfo({
            email: generalFields.email,
            phone: generalFields.Phone,
            name: `${generalFields.first_name} ${generalFields.last_name}`,
            place_id: this.props.country.id,
            image: "",
        });

        if (!success) {
            if (errors) {
                const handelErrors = getResponseErrors(errors);
                this.setState({
                    errors: handelErrors,
                });
                return;
            } else {
                return displayAlert("Error", message, "error");
            }
        }
        // apiService.storeToken(data.access_token);
        displayAlert(successText, ProfileUpdatedSuccessfully, "success");
        this.props.setUser({
            name: generalFields.first_name + " " + generalFields.last_name,
        });
    };
    // submit General Info function end

    // submit Security Info function start
    submitSecurityInfo = async () => {
        const { securityFields } = this.state;
        const { success, message, errors } = await userService.updateSecurityInfo({
            old_password: securityFields.old_password,
            password: securityFields.password,
            password_confirmation: securityFields.password_confirmation,
        });
        if (!success) {
            if (errors) {
                const handelErrors = getResponseErrors(errors);
                this.setState({
                    errors: handelErrors,
                });
                return;
            } else {
                return displayAlert("Error", message, "error");
            }
        }
        displayAlert(this.props.i18n.Done, this.props.i18n.PasswordUpdatedSuccessfully, "success").then(() => {
            this.setState({
                securityFields: {
                    password: "",
                    old_password: "",
                    password_confirmation: "",
                },
            });
        });
    };
    // submit Security Info function end
}

const mapStateToProps = ({ language, currentUser, country }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
    country: country,
});
export default connect(mapStateToProps, { setLanguage, setUser })(Profile);
