import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { cartService } from "../../../services/CartService";
import Skeleton from "react-loading-skeleton";
import Product from "../../blocks/Product";
import { homeService } from "../../../services/homeService";
import PaginatedItems from "../../Pages/Search/PaginationBloack";

class CollectionProducts extends Component {
  state = {
    collectionProducts: [],
    loaderPage: true,
    meta: {},
  };

  componentDidMount() {
    Promise.all([this.getSingleCollectionProducts()]);
  }

  getSingleCollectionProducts = async () => {
    const { data, success, meta } =
      await homeService.getSingleCollectionProducts(
        this.props.match.params.machineName
      );

    if (!success) return;
    this.setState({
      collectionProducts: data,
      loaderPage: false,
      meta,
    });
  };

  render() {
    const { collectionProducts, loaderPage, meta } = this.state;
    const {
      reFetchData,
      i18n: { Items, favEmpty },
    } = this.props;

    return (
      <div className="Cart">
        {/** Title and items count  */}
        <div className="CartTitle">
          <Container>
            <Row>
              <Col>
                <h2>
                  {this.props.match.params.machineName}
                  <h4>
                    {collectionProducts.length} {Items}
                  </h4>
                </h2>
              </Col>
            </Row>
          </Container>
        </div>
        {/** Title and items count  End */}

        {/* collection products  Start */}
        <Container>
          <Row>
            {loaderPage ? (
              <>
                <Col md={3}>
                  <Skeleton height={200} className="mb-3" />
                </Col>
                <Col md={3}>
                  <Skeleton height={200} className="mb-3" />
                </Col>
                <Col md={3}>
                  <Skeleton height={200} className="mb-3" />
                </Col>
                <Col md={3}>
                  <Skeleton height={200} className="mb-3" />
                </Col>
                <Col md={3}>
                  <Skeleton height={200} className="mb-3" />
                </Col>
                <Col md={3}>
                  <Skeleton height={200} className="mb-3" />
                </Col>
                <Col md={3}>
                  <Skeleton height={200} className="mb-3" />
                </Col>
                <Col md={3}>
                  <Skeleton height={200} className="mb-3" />
                </Col>
              </>
            ) : collectionProducts.length === 0 ? (
              // if collection products empty
              <Col md={12}>
                <div className="empty-products">
                  <img src="/images/Artboard 8.svg" alt="k" />
                  <p> {favEmpty} </p>{" "}
                </div>
              </Col>
            ) : (
              collectionProducts.map((item, index) => {
                return (
                  <Col lg={3} md={4} xs={6}>
                    <Product
                      data={item}
                      reFetchData={() => this.getSingleCollectionProducts()}
                    />
                  </Col>
                );
              })
            )}
          </Row>
        </Container>
        {/* collection products  End */}

        {/** Pagination Start */}
        {meta?.last_page > 1 && (
          <Row>
            <Col>
              <div className="Pagination mb-0">
                <PaginatedItems
                  items={[...Array(meta?.total).keys()]}
                  itemsPerPage={this.state.DisplayCase}
                  changePage={(page) => this.changePage(page)}
                />
              </div>
            </Col>
          </Row>
        )}
        {/** Pagination End */}
      </div>
    );
  }
  toTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  changePage(page) {
    this.toTop();
    this.props.history.push(
      `/CollectionProducts/${this.props.match.params.machineName}/${page + 1}`
    );
    this.setState(
      {
        pageLoader: true,
      },
      () => {
        this.getSingleCollectionProducts();
      }
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
});

export default connect(mapStateToProps, { setLanguage })(CollectionProducts);
