import { apiService } from "./ApiService";
import { handleResponse } from "../utils/misc";
import { apiEndPoints } from "../api";
class GeneralServices {
  get guestId() {
    return localStorage.getItem("guestId");
  }

  async getAllCountries(level) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(`${apiEndPoints.general.getPlaces}?parent_id=${level}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  async getIconData() {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${apiEndPoints.general.getIconData}?guest_id=${
            apiService.accessToken === 0 ? this.guestId : 0
          }`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  async getCategories(
    parentId,
    isFeatured,
    topBar,
    showBrands,
    showSub,
    countryId
  ) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(
          `${apiEndPoints.general.getCategories}?parent_id=${parentId}&is_featured=${isFeatured}&in_top_bar=${topBar}&show_brands=${showBrands}&show_subcategories=${showSub}&place_id=${countryId}`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  async getSlider(catId) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(`${apiEndPoints.general.getSlider}?category_id=${catId}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  async getCities(countryId) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(`${apiEndPoints.general.getCities}?country_id=${countryId}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  async getAreas(cityId) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(`${apiEndPoints.general.getAreas}?city_id=${cityId}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  async getBrands(limit) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(`${apiEndPoints.general.getBrands}?with_limit=${limit}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  async getStores(limit) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(`${apiEndPoints.general.getStores}?with_limit=${limit}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  async getFavoritesIDs() {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${apiEndPoints.general.getFavoritesIDs}?guest_id=${
            apiService.accessToken === 0 ? this.guestId : 0
          }`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  async getComparesIDs() {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${apiEndPoints.general.getComparesIDs}?guest_id=${
            apiService.accessToken === 0 ? this.guestId : 0
          }`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  async getCartIDs() {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${apiEndPoints.general.getCartIDs}?guest_id=${
            apiService.accessToken === 0 ? this.guestId : 0
          }`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  async getHomeAds(countryId) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(`${apiEndPoints.general.getHomeAds}?place_id=${countryId}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  async getFooter() {
    try {
      const data = await apiService
        .unauthenticated()
        .get(apiEndPoints.general.getFooter)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  async getSocialMediaLinks() {
    try {
      const data = await apiService
        .unauthenticated()
        .get(apiEndPoints.general.getSocialMediaLinks)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  async getPublicPagesData() {
    try {
      const data = await apiService
        .unauthenticated()
        .get(apiEndPoints.general.getPublicPagesData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
  async getPublicPagesDataDetails(machine_name) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(
          `${apiEndPoints.general.getPublicPagesDataDetails}?machine_name=${machine_name}`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  async getPaymentMethodsByPlaceID(countryId) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(
          `${apiEndPoints.general.getPaymentMethodsByPlaceID}?place_id=${countryId}`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
  async getHelpCenter(countryId) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(`${apiEndPoints.general.getHelpCenter}?place_id=${countryId}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
  async getSingleAds(machine_name, category_id, countryId) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(
          `${apiEndPoints.general.getSingleAds}?machine_name=${machine_name}&category_id=${category_id}&place_id=${countryId}`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
  async search(key, catId, page) {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${
            apiEndPoints.general.search
          }?key=${key}&category_id=${catId}&page=${page}&guest_id=${
            apiService.accessToken === 0 ? this.guestId : 0
          }`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
}

export const generalServices = new GeneralServices();
