import React, { Component } from "react";
import {
  Container,
  DropdownButton,
  FormControl,
  InputGroup,
  Dropdown,
  Row,
  Col,
} from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../language/Global.json";
import { setLanguage } from "../../redux/actions-reducers/language";
import { setUser } from "../../redux/actions-reducers/user";
import { setCountry } from "../../redux/actions-reducers/country";
import { generalServices } from "../../services/GeneralService";
// import { apiService } from "../../services/ApiService";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import localStorage from "redux-persist/es/storage";
import { homeService } from "../../services/homeService";
import { tabletScreen } from "../../utils/misc";
import MenuCategory from "./Menu/index";

class Header extends Component {
  state = {
    showCategory: false,
    showMegaCategory: false,
    menuOpen: false,
    countries: [],
    categories: [],
    topCategory: [],
    searchCategory: this.props.i18n?.all,
    topBrand: [],
    TopBarCategories: [],
    key: "",
    currentLang: this.props.language.lang,
    ads: {},
    searchCategoryId: 0,
    categoryName: null,
  };
  onLanguageChange = async (name, langCode) => {
    this.setState(
      {
        currentLang: name,
      },
      () => {
        localStorage.setItem("langCode", langCode);
        this.props.setLanguage({ lang: name, langCode }).then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 300);
        });
      }
    );
  };

  componentDidMount() {
    Promise.all([
      this.getCountries(),
      this.getIconData(),
      this.getFavoritesIDs(),
      this.getComparesIDs(),
      this.getCartIDs(),
      this.getCategories(0, 0, 0, 1, 1),
      this.getCategories(0, 0, 1, 1, 1),
    ]);
  }

  getCountries = async () => {
    const { data, success } = await generalServices.getAllCountries(0);
    if (!success) return;
    this.setState({
      countries: data[0],
    });

    this.props.setCountry({
      id: data[0].id,
      name: data[0].name,
      language: data[0].languages,
      currency: data[0].currency,
      digits: data[0].digits,
      flag: data[0].flag,
      zipcode: data[0].zipcode,
    });
  };
  getCartIDs = async () => {
    const { data, success } = await generalServices.getCartIDs();
    if (!success) return;
    this.props.setUser({
      cartIds: data,
    });
  };
  getFavoritesIDs = async () => {
    const { data, success } = await generalServices.getFavoritesIDs();
    if (!success) return;

    this.props.setUser({
      favoritesId: data,
    });
  };
  getComparesIDs = async () => {
    const { data, success } = await generalServices.getComparesIDs();
    if (!success) return;
    this.props.setUser({
      comparesId: data,
    });
  };
  getIconData = async () => {
    const { data, success } = await generalServices.getIconData();
    if (!success) return;
    this.props.setUser({
      cart: data.cart_counter,
      fav: data.fav_counter,
      compare: data.compare_counter,
    });
  };

  getCategories = async (parentId, isFeatured, topBar, showBrands, showSub) => {
    const { data, success } = await generalServices.getCategories(
      parentId,
      isFeatured,
      topBar,
      showBrands,
      showSub,
      this.props.country.id
    );

    if (!success) return;
    let name = topBar === 0 ? "categories" : "TopBarCategories";
    this.setState({
      [name]: data,
      // topCategory: data[0].sub_category,
      // topBrand: data[0].brands,
    });
    // console.log("data data data data", data);
  };

  render() {
    const {
      language,
      currentUser,
      i18n: {
        DeliverTo,
        LoginNew,
        CreateAccountNew,
        Stores,
        hello,
        search,
        all,
        AllCategories,
        TodaysDeals,
        Brands,
        BrandStore,
        SignIn,
        TopCategory,
        TopBrands,
        Categories,
        Guest,
        TrackYourOrder,
        Profile,
        MyOrders,
        MyLocations,
        Logout,
        Jordan
      },
    } = this.props;

    const {
      showCategory,
      topBrand,
      currentLang,
      countries,
      topCategory,
      showMegaCategory,
      categories,
      searchCategory,
      TopBarCategories,
      ads,
    } = this.state;
    return (
      <div
        className={`header ${
          language.langCode === "rtl" && "header header-arabic"
        }`}
      >
        <div
          className="header__top"
          onMouseOver={() => {
            this.setState({
              showMegaCategory: false,
              showCategory: false,
            });
          }}
        >
          <Container fluid>
            <div className="header__top__content flex   ">
              <a href="/">
                <img src="/images/logo.svg" alt="logo" className="logo" />
              </a>

              <div
                className={`flex header__location-card  ${
                  language.langCode === "rtl" && "header__location-card-arabic"
                }`}
              >
                <img src="/images/locationMark.svg" />
                <div className="header__location-card__right-side">
                  <span>{DeliverTo} </span>
                  <p>{Jordan}</p>    
                </div>
              </div>

              {!tabletScreen && (
                <InputGroup
                  className={`header__search  ${
                    language.langCode === "rtl" && "header__search-arabic"
                  }`}
                >
                  <FormControl
                    placeholder={search}
                    aria-label="Username"
                    onChange={({ target: { value } }) => {
                      this.setState({
                        key: value,
                      });
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        if (this.state.key === "") {
                        } else {
                          this.Search(this.state.key);
                        }
                      }
                    }}
                    aria-describedby="basic-addon1"
                  />
                  <InputGroup.Text
                    id="basic-addon1"
                    onClick={() => {
                      if (this.state.key === "") {
                      } else {
                        this.Search(this.state.key);
                      }
                    }}
                  >
                    <img src="/images/searchIcon.svg" alt="search" />
                  </InputGroup.Text>
                </InputGroup>
              )}

              <DropdownButton
                className={`header__location-card header__language-dropDown flex  ${
                  language.langCode === "rtl" && "header__location-card-arabic"
                }`}
                variant="outline-secondary"
                title={
                  <div className="header__language flex">
                    <img src="/images/languageIcon.svg" alt="search" />
                    <span>{currentLang}</span>
                  </div>
                }
                id="input-group-dropdown-1"
              >
                {countries?.languages?.map(({ name, direction }) => {
                  return (
                    <Dropdown.Item
                      onClick={() => this.onLanguageChange(name, direction)}
                    >
                      {name}
                    </Dropdown.Item>
                  );
                })}
              </DropdownButton>

              {currentUser?.email ? ( // if user
                <DropdownButton
                  className={`header__location-card flex  ${
                    language.langCode === "rtl" &&
                    "header__location-card-arabic"
                  }`}
                  variant="outline-secondary"
                  title={
                    <div className="flex button-contents">
                      <img src="/images/userIcon.svg" alt="1" />
                      <div className="header__location-card__right-side">
                        {/* <span>{hello}</span> */}
                        <p>{currentUser?.name}</p>
                      </div>
                    </div>
                  }
                  id="input-group-dropdown-1"
                >
                  {/* Profile start  */}
                  <Dropdown.Item href="/profile">
                    <img src="/images/userDropdown.svg" alt="profile" />
                    {Profile}
                  </Dropdown.Item>
                  {/* Profile end  */}

                  {/* MyOrders start  */}
                  <Dropdown.Item href="/orders/1">
                    <img src="/images/boxDropdown.svg" alt="orders" />
                    {MyOrders}
                  </Dropdown.Item>
                  {/* MyOrders end  */}

                  {/* MyLocations start  */}
                  <Dropdown.Item href="/addresses">
                    <img src="/images/locationDropdown.svg" alt="addresses" />
                    {MyLocations}
                  </Dropdown.Item>
                  {/* MyLocations end  */}

                  {/* Track Your Order start  */}
                  <Dropdown.Item href="/trackYourOrder/1">
                    <img src="/images/truckDropdown.svg" alt="trackYourOrder" />
                    {TrackYourOrder}
                  </Dropdown.Item>
                  {/* Track Your Order end  */}

                  {/* Logout start  */}
                  <Dropdown.Item
                    href="/login"
                    onClick={() => {
                      localStorage.removeItem("safeerAccessToken");
                      localStorage.removeItem("persist:Safeer");
                    }}
                  >
                    <img src="/images/logoutDropdown.svg" alt="Logout" />
                    {Logout}
                  </Dropdown.Item>
                  {/* Logout end  */}
                </DropdownButton>
              ) : (
                <>
                  <div
                    className={`flex header__location-card  ${
                      language.langCode === "rtl" &&
                      "header__location-card-arabic"
                    }`}
                  >
                    <img src="/images/userIcon.svg" />
                    <div className="header__location-card__right-side">
                      {/* login and signup links start  */}
                      <Link to="/login">{LoginNew}</Link>/
                      <Link to="/signup">{CreateAccountNew}</Link>
                      {/* login and signup links start  */}
                      <DropdownButton
                        variant="outline-secondary"
                        title={
                          <>
                            <img src="/images/arrow-down.svg" alt="l" />
                          </>
                        }
                        id="input-group-dropdown-1"
                        className="header__userButton"
                      >
                        {/* SignIn start  */}
                        <Dropdown.Item href="/login">
                          <img src="/images/logoutDropdown.svg" alt="Logout" />
                          {SignIn}
                        </Dropdown.Item>
                        {/* SignIn end  */}

                        {/* gust Orders start  */}
                        <Dropdown.Item href="/orders/1">
                          <img src="/images/boxDropdown.svg" alt="orders" />
                          {MyOrders}
                        </Dropdown.Item>
                        {/* gust Orders end  */}
                      </DropdownButton>
                    </div>
                  </div>
                </>
              )}
              <div
                className={`header__count flex  ${
                  language.langCode === "rtl" && "header__count-arabic"
                }`}
              >
                <a href="/favourite">
                  <img src="/images/favicon.svg" alt="search" />
                  {currentUser?.fav > 0 && <span>{currentUser?.fav}</span>}
                </a>
                <a href="/cart">
                  <img src="/images/cartIcon.svg" alt="search" />
                  {currentUser?.cartIds?.length > 0 && (
                    <span>{currentUser?.cartIds?.length}</span>
                  )}
                </a>
              </div>
            </div>
          </Container>
        </div>

        <div className="header__bottom">
          <Container fluid>
            <ul
              className={`flex header__bottom__list  ${
                language.langCode === "rtl" && "header__bottom__list-arabic"
              }`}
            >
              <li
                className="header-links"
                onMouseOver={() => {
                  this.setState({
                    showCategory: true,
                    showMegaCategory: false,
                  });
                }}
              >
                <img src="/images/category.svg" alt="search" />

                <span>
                  {AllCategories}
                  <img src="/images/pat.svg" alt="search" />
                </span>
              </li>
              
              {/* <li
                className="header-links"
                onMouseOver={() => {
                  this.setState({
                    showMegaCategory: false,
                  });
                }}
              >
                <a href="/GeneralProduct/TodaysDeals" className="d-block">
                  <img
                    src="/images/hot-sale.svg"
                    alt="search"
                    className="m-2 mb-0 mt-0"
                  />
                  <span>{TodaysDeals}</span>
                </a>
              </li> */}

              <div className="overflow-auto mw-100">
                <div className="d-flex sub-list align-items-center">
                  {TopBarCategories.map((data_sub) => {
                    return (
                      <li
                        className="header-links"
                        key={data_sub.id}
                        onMouseOver={() => {
                          this.setState({
                            showCategory: false,
                            showMegaCategory: true,
                            topCategory: data_sub.subcategories,
                            topBrand: data_sub.brands,
                            ads: data_sub.ads,
                            categoryName: data_sub.machine_name,
                            data_sub: data_sub,
                          });
                        }}
                      >
                        <a
                          href={`/ProductsCategory/${data_sub.machine_name}/1`}
                          className="text-capitalize"
                        >
                          {data_sub.name}
                        </a>
                      </li>
                    );
                  })}
                  {/* {console.log(this.data_sub)} */}
                </div>
              </div>
            </ul>

            {tabletScreen && (
              <InputGroup
                className={`header__search mobile__search-header ${
                  language.langCode === "rtl" && "header__search-arabic"
                }`}
              >
                <FormControl
                  placeholder={search}
                  aria-label="Username"
                  onChange={({ target: { value } }) => {
                    this.setState({
                      key: value,
                    });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      if (this.state.key === "") {
                      } else {
                        this.Search(this.state.key);
                      }
                    }
                  }}
                  aria-describedby="basic-addon1"
                />
                <InputGroup.Text
                  id="basic-addon1"
                  onClick={() => {
                    if (this.state.key === "") {
                    } else {
                      this.Search(this.state.key);
                    }
                  }}
                >
                  <img src="/images/searchIcon.svg" alt="search" />
                </InputGroup.Text>
              </InputGroup>
            )}
          </Container>

          {showCategory && (
            <div
              className="mega-menu shadow"
              onMouseLeave={(name) => {
                this.setState({
                  showCategory: false,
                });
              }}
            >
              <ul
                className={`mega-menu__first-level  ${
                  language.langCode === "rtl" && "mega-menu__first-level-arabic"
                }`}
              >
                {categories.map(
                  ({ name, subcategories, id, machine_name, brands }) => {
                    return (
                      <li
                        key={id}
                        onMouseOver={() => {
                          this.setState({
                            topCategory: subcategories,
                            topBrand: brands,
                            categoryName: machine_name,
                          });
                        }}
                      >
                        <a href={"/ProductsCategory/" + machine_name + "/1"}>
                          <span className="text-capitalize">
                            {name}
                            {subcategories?.length > 0 && (
                              <img src="/images/rightPath.svg" alt="search" />
                            )}
                          </span>
                        </a>
                      </li>
                    );
                  }
                )}
              </ul>

              <div className="sub-menu">
                <div className="sub-menu__header">
                  <span>{TopCategory}</span>
                  <span>{TopBrands}</span>
                </div>

                <div className="sub-menu__lists">
                  <ul>
                    {topCategory.map(({ id, name, machine_name }) => {
                      return (
                        <li key={id}>
                          <a href={"/ProductsCategory/" + machine_name + "/1"}>
                            <span className="text-capitalize">{name}</span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>

                  <ul>
                    {topBrand.map(({ name, machine_name, id }, index) => {
                      return (
                        <li key={index}>
                          <a
                            href={`/Brand/${this.state.categoryName}/${machine_name}/${id}/1`}
                          >
                            <span className="text-capitalize">{name}</span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                  {/* sub menu Top Brans list end   */}
                </div>
              </div>
            </div>
          )}
          {/* mega menu all categories end  */}

          {/* mega menu single top categories start  */}

          {showMegaCategory && (
            <div
              className="mega-menu2 shadow"
              onMouseLeave={() => {
                this.setState({
                  showMegaCategory: false,
                });
              }}
            >
              <MenuCategory {...this.state.data_sub} />
            </div>
          )}
        </div>
      </div>
    );
  }

  // search function start
  Search = (key) => {
    const { searchCategoryId } = this.state;

    if (key.includes("%")) {
      window.location.href = `/Search/${key.replace(
        "%",
        "%25"
      )}/${searchCategoryId}/1`;
    } else {
      window.location.href = `/Search/${key}/${searchCategoryId}/1`;
    }
  };

  visitAdsLink = async (url, id) => {
    const { data, success } = await homeService.visitAdsLink(id);
    if (!success) return;
    window.open(url, "target");
  };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
  country,
});
export default connect(mapStateToProps, { setLanguage, setCountry, setUser })(
  withRouter(Header)
);

const d = {
  language: '{"lang":"English","isLoading":false,"langCode":"ltr"}',
  currentUser:
    '{"country_id":0,"phone":"","name":"","email":"","image":null,"id":1087,"cart":0,"fav":0,"favoritesId":[],"comparesId":[],"compare":0,"cartIds":[],"device_id":457558,"ip_address":"82.212.109.88","last_login":null,"status":null,"currency":null}',
  country:
    '{"id":1,"language":[{"id":1,"name":"English","code":"en","direction":"ltr"},{"id":2,"name":"عربي","code":"ar","direction":"rtl"}],"name":"Jordan","currency":"JOD","digits":9,"flag":"jor.png","zipcode":"00962"}',
  _persist: '{"version":-1,"rehydrated":true}',
};
