import React, { useState } from "react";

const AccordionLocation = ({ changeAccordion, selectedLocation, data: { id, nickname, address, latitude, longitude, phone, is_default } }) => {
    const [Check, setCheck] = useState(false);
    return (
        <li key={id}>
            <div className="Locations">
                <div className="LocationInfo">
                    {/* location icon start  */}
                    <div className="LocationIcon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="31.336" height="38.284" viewBox="0 0 31.336 38.284">
                            <g id="Bold-Outline" transform="translate(-2.5 -0.5)">
                                <g id="location" transform="translate(2.5 0.5)">
                                    <path
                                        id="shape"
                                        d="M20.267,38.03a3.3,3.3,0,0,1-4.2,0C7.08,30.613,2.5,23.341,2.5,16.168a15.668,15.668,0,1,1,31.336,0C33.836,23.341,29.256,30.613,20.267,38.03Zm10.27-21.862a12.369,12.369,0,0,0-24.739,0c0,6.011,4.066,12.466,12.369,19.318C26.471,28.634,30.537,22.179,30.537,16.168ZM18.168,21.116a6.6,6.6,0,1,1,6.6-6.6A6.6,6.6,0,0,1,18.168,21.116Zm0-3.3a3.3,3.3,0,1,0-3.3-3.3A3.3,3.3,0,0,0,18.168,17.817Z"
                                        transform="translate(-2.5 -0.5)"
                                        fill={selectedLocation.id == id ? "#752157" : "#cecece"}
                                    />
                                </g>
                            </g>
                        </svg>
                    </div>
                    {/* location icon end  */}

                    {/* location details  start  */}
                    <div>
                        {/* location nick name start  */}
                        <h1 style={{ color: selectedLocation.id == id ? "#752157" : "#cecece" }}>{nickname}</h1>
                        {/* location nick name end  */}

                        {/* location address start  */}
                        <p>{address}</p>
                        {/* location address end  */}

                        {/* location phone start  */}
                        <div className="LocationUserEmailPhone">
                            <h5>
                                <svg xmlns="http://www.w3.org/2000/svg" width="11.973" height="16.462" viewBox="0 0 11.973 16.462">
                                    <g id="Bold-Outline" transform="translate(0 0)">
                                        <g id="phone">
                                            <path
                                                id="shape"
                                                d="M14.476,11.476V3.993a1.5,1.5,0,0,0-1.5-1.5H6.993a1.5,1.5,0,0,0-1.5,1.5v7.483Zm0,1.5H5.5v1.5a1.5,1.5,0,0,0,1.5,1.5h5.986a1.5,1.5,0,0,0,1.5-1.5ZM6.993,1h5.986a2.993,2.993,0,0,1,2.993,2.993V14.469a2.993,2.993,0,0,1-2.993,2.993H6.993A2.993,2.993,0,0,1,4,14.469V3.993A2.993,2.993,0,0,1,6.993,1ZM9.986,15.592a1.122,1.122,0,1,1,1.122-1.122A1.122,1.122,0,0,1,9.986,15.592Z"
                                                transform="translate(-4 -1)"
                                                fill={selectedLocation.id == id ? "#752157" : "#cecece"}
                                            />
                                        </g>
                                    </g>
                                </svg>{" "}
                                &nbsp; {phone}
                            </h5>
                        </div>
                        {/* location phone end  */}
                    </div>
                    {/* location details  end  */}
                </div>

                {/** <!--Location On Off  switch --> */}
                <div className="LocationOnOff">
                    <div className="SwitchButt">
                        <label className="switch">
                            <input checked={selectedLocation.id == id ? true : false} type="checkbox" onClick={() => changeAccordion(id)} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                {/** <!--Location On Off  end --> */}
            </div>
        </li>
    );
};

export default AccordionLocation;
