import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import QRCode from "qrcode.react";
import Global from "../../language/Global.json";
import { setLanguage } from "../../redux/actions-reducers/language";
import { generalServices } from "../../services/GeneralService";
import { apiService } from "../../services/ApiService";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
class Footer extends Component {
  state = {
    payment_methods: [],
    help_section: [],
    settings: [],
    social_media: [],
    PublicPagesData: [],
    footerData: {},
    loader: true,
  };
  async componentDidMount() {
    await Promise.all([
      this.getFooter(),
      this.getPublicPagesData(),
      this.getSocialMediaLinks(),
      this.getPaymentMethodsByPlaceID(),
      this.getHelpCenter(),
      this.getPaymentMethodsByPlaceID(),
    ]);

    this.setState({ loader: false });
  }

  async getFooter() {
    const { success, data } = await generalServices.getFooter();
    if (!success) return;
    // const { payment_methods, help_section, settings, social_media } = data
    this.setState({
      footerData: data,
    });
  }

  async getSocialMediaLinks() {
    const { success, data } = await generalServices.getSocialMediaLinks();
    if (!success) return;
    this.setState({
      social_media: data,
    });
  }

  async getPaymentMethodsByPlaceID() {
    const { success, data } = await generalServices.getPaymentMethodsByPlaceID(
      this.props.country.id
    );
    if (!success) return;
    this.setState({
      payment_methods: data,
    });
  }

  async getHelpCenter() {
    const { success, data } = await generalServices.getHelpCenter(
      this.props.country.id
    );
    if (!success) return;
    this.setState({
      help_section: data,
    });
  }

  async getPublicPagesData() {
    const { success, data } = await generalServices.getPublicPagesData();
    if (!success) return;
    this.setState({
      PublicPagesData: data,
    });
  }

  toTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  render() {
    const {
      language,
      i18n: {
        FollowUs,
        DownloadsOurApp,
        Help,
        PaymentMethod,
        AvailableOn,
        CoppyRights,
      },
    } = this.props;
    const {
      payment_methods,
      help_section,
      social_media,
      PublicPagesData,
      footerData,
      loader,
    } = this.state;
    return (
      <>
        {loader ? (
          <Skeleton height={400} />
        ) : (
          <div className={`footer  ${language === "rtl" && "footer-arabic"}`}>
            {/* Footer upper side Start */}

            <div className="footer__upper-side">
              <Container>
                <Row>
                  {/* logo and about safeer text  start */}
                  <Col md={4} sm={12}>
                    <div className="about">
                      <a href="/">
                        <img
                          src={apiService.imageLink + footerData.footer_logo}
                          alt="1"
                        />
                      </a>
                      <p>{footerData.footer_text}</p>
                    </div>
                  </Col>
                  {/* logo and about safeer text  end */}

                  {/* helps list start */}
                  <Col md={4} sm={6}>
                    <div className="help">
                      <h3>{Help}</h3>
                      <div className="help__methods">
                        {help_section.map(({ name, id, value, icon }) => (
                          <div key={id} className="method">
                            <img src={apiService.imageLink + icon} alt="1" />
                            <div className="info">
                              {" "}
                              <h3>{name}</h3>
                              <h4
                                onClick={() => {
                                  var reg = new RegExp("^[0-9]*$");
                                  
                                  if (value.includes("@")) {
                                    window.location.href = `mailto:${value}`;
                                  }
                                  if (reg.test(value)) {
                                    window.location.href = `tel:${value}`;
                                  }
                                }}
                              >
                                {value}
                              </h4>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Col>
                  {/* helps list end */}

                  {/* download applications start */}
                  <Col md={4} sm={6}>
                    <div className="application">
                      <h3>{DownloadsOurApp}</h3>
                      <div className="application__content">
                        {/* qr code start  */}
                        <div className="qr-code-and-download-section">
                          <div className="qr-code">
                            {/* <img src="/images/qr.svg" /> */}
                            <QRCode
                              value={`${footerData?.download_app_qr_link}`}
                            />
                          </div>
                        </div>
                        {/* qr code end  */}

                        <div className="download">
                          <a
                            target="_blank"
                            className="store"
                            rel="noreferrer"
                            href={footerData.download_ios}
                          >
                            <img src="/images/applelogo.svg" alt="1" />
                            <div className="store-info">
                              <h3>{AvailableOn}</h3>
                              <h4>App Store</h4>
                            </div>
                          </a>
                          <a
                            target="_blank"
                            className="store"
                            rel="noreferrer"
                            href={footerData.download_android}
                          >
                            <img src="/images/android.svg" alt="1" />
                            <div className="store-info">
                              <h3>{AvailableOn}</h3>
                              <h4>Google Play</h4>
                            </div>
                          </a>
                          <a
                            target="_blank"
                            className="store"
                            rel="noreferrer"
                            href={footerData.download_huawei}
                          >
                            <img src="/images/appgallery.svg" alt="1" />
                            <div className="store-info">
                              <h3>{AvailableOn}</h3>
                              <h4>AppGallery</h4>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* download applications end */}
                </Row>
                <Row className="mt-4">
                  {/* social media list start  */}
                  <Col md={8}>
                    <div className="footer__social-media">
                      <h3>{FollowUs}</h3>
                      <div className="social-media__icons">
                        {social_media.map(({ id, icon, link }) => (
                          <a key={id} href={link} target="_blank">
                            <img src={apiService.imageLink + icon} alt="1" />
                          </a>
                        ))}
                      </div>
                    </div>
                  </Col>
                  {/* social media list end  */}

                  {/* payment methods start  */}
                  <Col md={4}>
                    <div className="footer__payment">
                      <h3>{PaymentMethod} </h3>
                      <div className="images">
                        {payment_methods?.map(({ image }, index) => (
                          <img
                            key={index}
                            src={apiService.imageLink + image}
                            alt="1"
                          />
                        ))}
                      </div>
                    </div>
                  </Col>
                  {/* payment methods end  */}
                </Row>
              </Container>
            </div>
            {/* Footer upper side End */}

            {/* Footer lower (CopyWrite) side Start */}
            <div className="footer__lower-side">
              <Container>
                <div className="coppyrights">
                  <p className="">{CoppyRights}</p>
                  <ul>
                    {PublicPagesData.map(({ name, id, machine_name }) => (
                      <li key={id}>
                        <Link
                          to={`/PublicPagesData/${machine_name}`}
                          onClick={() => {
                            this.getPublicPagesData();
                            this.toTop();
                          }}
                        >
                          {name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Container>
            </div>
            {/* Footer lower (CopyWrite) side End */}

            {/** Messenger Chat */}
            <a
              className="Whatsaapicon"
              href="https://api.whatsapp.com/send?phone=962791333622"
              target="_blank"
            >
              <img alt="whatsapp" src="/images/whatsapp.png" width={"70px"} />
            </a>
            {/** Messenger Chat End */}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
  country,
});
export default connect(mapStateToProps, { setLanguage })(Footer);
