import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { apiService } from "../../../services/ApiService";
import { tabletScreen } from "../../../utils/misc";

class GirlsCategories extends Component {
  render() {
    const {
      language,
      data,
      // i18n: { },
    } = this.props;

    return (
      <Container>
        <div
          className={`girls-categories  ${
            language === "rtl" && "girls-categories-arabic"
          }`}
        >
          {data?.length < 1 ? (
            <Row>
              <Col>
                <Skeleton height={tabletScreen ? 120 : 250} />
              </Col>
              <Col>
                <Skeleton height={tabletScreen ? 120 : 250} />
              </Col>
            </Row>
          ) : (
            <Row className="m-0">
              {data?.map(
                ({ id, image, machine_name, name, description }, index) => {
                  return (
                    // card style 1
                    <>
                      <Col key={index} className=" col" xs={6}  md={3} xl={2}>
                        <a
                          href={`/productsCategory/${machine_name}/1`}
                          className="girls-categories__vertical-card"
                        >
                          <img src={apiService.imageLink + image} alt="/" />
                          <h3>{name}</h3>
                        </a>
                      </Col>

                    </>
                  );
                }
              )}
            </Row>
          )}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
});
export default connect(mapStateToProps, { setLanguage })(GirlsCategories);
