import React, { Component } from "react";
import { Col, Container } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { apiService } from "../../../services/ApiService";
import { homeService } from "../../../services/homeService";

class BigAds extends Component {
    render() {
        const { data, loader } = this.props;
        return (
            <div
                // href={data?.link}
                onClick={() => {
                    if (data?.can_click) {
                        this.visitAdsLink(data?.link, data?.id);
                    }
                }}
                className="home__big-sale"
            >
                {/* img background start  */}
                {loader ? (
                    <Col sm={12}>
                        <Skeleton height={400} className="w-100" />
                    </Col>
                ) : (
                    <img src={data?.ad_image ? apiService.imageLink + data?.ad_image : "/images/placeholder.png"} alt="ll" />
                )}
                {/* img background end  */}
            </div>
        );
    }

    visitAdsLink = async (url, id) => {
        const { data, success } = await homeService.visitAdsLink(id);
        if (!success) return;

        window.open(url, "target");
    };
}

export default BigAds;
