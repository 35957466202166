import React, { Component } from "react";
import { Container, Row, Col, Modal, Spinner } from "react-bootstrap";
import TextInput from "../../blocks/TextInput";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { setUser } from "../../../redux/actions-reducers/user";
import { authService } from "../../../services/AuthService";
import { displayAlert, getResponseErrors, toTop } from "../../../utils/misc";
import { apiService } from "../../../services/ApiService";
import { Link } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";



class Login extends Component {
  state = {
    remindMe: false,
    errors: { email: "", password: "", password_confirmation: "" },
    fields: { email: "", password: "", password_confirmation: "" },
    forgetPasswordModal: false,
    showVerificationCode: false,
    verificationCode: "",
    userVerificationCode: null,
    forgetStep: 1,
    socialLogin: {
      email: "",
      social_provider: "",
      social_provider_id: "",
      phone: "",
      name: "",
      place_id: "",
      social_image: "",
    },
    forgetPasswordLoader: false
  };

  closeModal = () => {
    this.setState({
      forgetPasswordModal: false,
    });
  };

  componentDidMount() {
    toTop();
  }
  render() {
    const {
      language,
      i18n: {
        LoginToYourAccount,
        Email,
        Password,
        CreateBtn,
        LoginByFacebookOrGoogle,
        DontHaveAcount,
        LoginBtn,
        LittleNoteUnderEmail,
        RememberMe,
        ResetYourPassword,
        ForgetPassword,
        ResetPassword,
        Or,
        successResetPassword,
        successText
      },
    } = this.props;
    const { errors, fields, forgetPasswordModal } = this.state;
    return (
      <Container>
        <div
          className={`signup-login   ${language === "rtl" && "signup-login-arabic "
            }`}
        >
          {/* create new account title */}
          <h3>{LoginToYourAccount}</h3>
          <span>
            {DontHaveAcount} &nbsp;
            <Link to="/signup" className="signup-login__link">
              {CreateBtn}
            </Link>
          </span>

          {/* Login with facebook or google section start */}
          <h4>{LoginByFacebookOrGoogle}</h4>

          <Container className="signup-login__by-fb-google mb-4">
            <Row className="no-wrap">
              {/* Login with facebook  start */}

              <Col md={6} sm={12} className="text-center mt-2">
                <FacebookLogin
                  appId="393196132597853"
                  autoLoad={false}
                  fields="id,name,email,picture"
                  callback={this.responseFacebook}
                  cssclassName=""
                  isMobile={false}
                  icon="fa fa-facebook-f fa-2x"
                  textButton=""
                  className="socialBtn fb-btn"
                />
              </Col>
              {/* Login with facebook  end */}

              {/* Login with  google  start */}
              <Col md={6} sm={12} className="text-center mt-2 googleImg">
                <GoogleLogin
                  clientId="741158028277-aa83fs9vvqlovsu8f5ab4nggrfhc353b.apps.googleusercontent.com"
                  buttonText={``}
                  onSuccess={this.responseGoogle}
                  onFailure={(error) => {
                    console.log(error);
                  }}
                  cookiePolicy={"single_host_origin"}
                  className="socialBtn gl-btn"
                />
              </Col>
              {/* Login with  google  end */}
            </Row>
            <Row>
              <Col xs={12}>
                <h4 className="text-center">{Or}</h4>
              </Col>
            </Row>
          </Container>
          {/* Login with facebook or google section End */}

          {/* login form start */}
          <div className="signup-login__form passwordInput">
            <Row>
              {/* email inputs start  */}
              <Col sm={12}>
                <TextInput
                  placeholder={Email}
                  name="email"
                  value={fields.email}
                  label={Email}
                  onFieldChange={this.onFieldChange}
                  validate={errors.email}
                />
                {!errors.email && (
                  <span className="text-note mt-1">{LittleNoteUnderEmail}</span>
                )}
              </Col>
              {/* email inputs end  */}

              {/* password inputs start  */}
              <Col className="mt-px" sm={12}>
                <TextInput
                  placeholder={Password}
                  withImg
                  name="password"
                  value={fields.password}
                  label={Password}
                  type="password"
                  onFieldChange={this.onFieldChange}
                />
              </Col>
              {/* password inputs end  */}

              <div className="checkbox-ForgetPassword">
                {/* remember me check box start  */}
                <div className="checkbox-container">
                  <p
                    className="checkbox"
                    onClick={() =>
                      this.setState({
                        remindMe: !this.state.remindMe,
                      })
                    }
                  >
                    {this.state.remindMe ? (
                      <i className="fa fa-check"></i>
                    ) : null}
                  </p>
                  <p>{RememberMe}</p>
                </div>
                {/* remember me check box end  */}

                {/* foeget passward  start  */}
                <p
                  onClick={() => this.setState({ forgetPasswordModal: true })}
                  className="ForgetPassword"
                >
                  {ForgetPassword}
                </p>
                {/* foeget passward  end  */}
              </div>
            </Row>
          </div>
          {/* login form End */}

          {/* Create account button */}
          <button className="submit-button" onClick={this.submit}>
            {LoginBtn}
          </button>
          {/* Create account button end */}

          {/* I don't Have An Account section start */}

          {/* <h5 className="h5-login">{DontHaveAcount}</h5>
                    <Link to="/signup" className="submit-button login-button">
                        {CreateBtn}
                    </Link> */}

          {/* I don't Have An Account section end */}
        </div>

        {/* forget Password modal */}
        <Modal
          show={forgetPasswordModal}
          width="600"
          effect="fadeInUp"
          className={`location-modal ${language === "rtl" && "location-modal-arabic"
            }`}
        >
          {/* modal title start  */}
          <div className="modal-title flex">
            <h5>
              {this.state.showVerificationCode
                ? "Enter verification code"
                : ResetYourPassword}
            </h5>
            <i onClick={this.closeModal} className="fa fa-times"></i>
          </div>
          {/* modal title end  */}

          <div className="address-inputs">
            <Row>
              {/* verification code inputs end  */}
              <Col lg={12} md={12} sm={12} style={{ marginBottom: "1rem" }}>
                {
                  // forgetStep: 1,
                  // forgetPasswordError: "",
                }
                {this.state.forgetStep === 1 && (
                  <div>
                    <TextInput
                      name="email"
                      label={Email}
                      placeholder={Email}
                      value={fields.email}
                      onFieldChange={this.onFieldChange}
                      validate={errors.email}
                    />
                    <p className="text-danger">
                      {this.state.forgetPasswordError}
                    </p>
                  </div>
                )}
                {this.state.forgetStep === 2 && (
                  <>
                    <h6>Please enter the otp:</h6>
                    <ReactCodeInput
                      fields={4}
                      // onComplete={this.checkVerificationCode}
                      onChange={(value) => {
                        if (value) {
                          this.setState({
                            userVerificationCode: `${value}`,
                          });
                        } else {
                          this.setState({
                            userVerificationCode: null
                          });
                        }

                      }}
                    />
                    <p className="text-danger">
                      {this.state.forgetPasswordError}
                    </p>
                  </>
                )}

                {this.state.forgetStep === 3 && (
                  <div>
                    <Col className="mt-px" sm={12}>
                      <TextInput
                        placeholder={Password}
                        withImg
                        name="password"
                        value={fields.password}
                        label={Password}
                        type="password"
                        onFieldChange={this.onFieldChange}
                      />

                    </Col>
                    <Col className="mt-px" sm={12}>
                      <TextInput
                        placeholder="password confirmation"
                        withImg
                        name="password_confirmation"
                        value={fields.password_confirmation}
                        label="password confirmation"
                        type="password"
                        onFieldChange={this.onFieldChange}
                      />
                    </Col>
                    {
                      this.state.passwordError &&
                      <p className="text-danger">
                        {this.state.passwordError}
                      </p>
                    }

                  </div>
                )}
              </Col>
              {/* verification code inputs end  */}

              {/* rest password and submit code button start  */}
              <Col lg={12} md={12} sm={12} className="locationButton">
                <button
                  className={this.state.forgetPasswordLoader ? "submit-button2 margin-top-0 preventBtnHover" : "submit-button2 margin-top-0"}

                  disabled={this.state.forgetPasswordLoader ? true : false}
                  onClick={() =>
                    this.state.forgetStep === 1
                      ? this.checkEmail()
                      : this.state.forgetStep === 2
                        ? (this.state.userVerificationCode ? this.checkVerificationCode() : null)
                        : this.resetForgetPassword()
                  }
                >
                  {
                    this.state.forgetPasswordLoader ?
                      <Spinner animation="grow" variant="light" size="sm" />
                      :
                      (
                        this.state.showVerificationCode
                          ? "Submit code"
                          : ResetPassword
                      )
                  }


                </button>
              </Col>
              {/* rest password and submit code button end  */}
            </Row>
          </div>
        </Modal>
        {/* forget Password modal */}
      </Container>
    );
  }
  // on Field Change start
  onFieldChange = (name, value) => {
    const { fields } = this.state;
    this.setState({
      fields: { ...fields, [name]: value },
      errors: {
        email: "",
        password: "",
      },
    });
  };
  // on Field Change end

  // login submit start
  submit = async () => {
    const { fields } = this.state;
    const { errors: errorTag } = this.props.i18n;
    const { success, data, message, errors } = await authService.login({
      email: fields.email,
      password: fields.password,
    });

    if (!success) {
      if (errors) {
        const handelErrors = getResponseErrors(errors);
        this.setState({
          errors: handelErrors,
        });
        return;
      } else {
        return displayAlert(errorTag, message, "error");
      }
    }

    apiService.storeToken(data.access_token);

    this.props.setUser({ ...data.user });

    window.location.href = "/";
  };
  // login submit end

  // Handle facebook Login START..
  responseFacebook = async (response) => {
    if(!response.id){
      return false;
    }
    const { error: errorTag } = this.props.i18n;
    const { success, data, message, errors } = await authService.socialLogin({
      phone: "",
      place_id: this.props.country.id, // What
      email: response.email ? response.email : "",
      name: response.name ? response.name : "",
      social_image: response.image ? response.image : "",
      social_provider_id: response.id ? response.id : "",
      social_provider: "facebook",
    });

    if (!success) {

      if (errors) {
        this.props.setUser({
          socialMedia: {
            email: response?.email ?? "",
            name: response?.name ?? "",
            phone: response?.phone ?? "",
            password_confirmation: "",
            password: "",
            terms_conditions: false,
            social_image: response?.image ?? "",
            social_provider_id: response?.id ?? "",
            social_provider: "facebook",
          },
        });
        setTimeout(() => {
          window.location.href = "/SocialLogin";
        }, 0);
      } else {
        return displayAlert(errorTag, message, "error");
      }

    }


    apiService.storeToken(data.access_token);
    this.props.setUser({ ...data.user });
    window.location.href = "/";
  };

  responseGoogle = async (response) => {
    console.error(response);
    const { error: errorTag } = this.props.i18n;
    const { success, data, message, errors } = await authService.socialLogin({
      phone: "",
      place_id: this.props.country.id,
      email: response.profileObj.email ? response.profileObj.email : "",
      name: response.profileObj.givenName
        ? response.profileObj.givenName + " " + response.profileObj.familyName
        : "",
      social_image: response.profileObj.image ? response.profileObj.image : "",
      social_provider_id: response.profileObj.googleId
        ? response.profileObj.googleId
        : "",
      social_provider: "google",
    });
    if (!success) {
      if (errors) {
        this.props.setUser({
          socialMedia: {
            email: response.profileObj.email ? response.profileObj.email : "",
            name: response.profileObj.givenName
              ? response.profileObj.givenName + " " + response.profileObj.familyName
              : "",
            phone: response?.profileObj?.phone ?? "",
            social_image: response.profileObj.image ?? "",
            social_provider_id: response.profileObj.googleId ?? "",
            social_provider: "google",
          },
        });
        setTimeout(() => {
          window.location.href = "/SocialLogin";
        }, 0);
      } else {
        return displayAlert(errorTag, message, "error");
      }
    }
    apiService.storeToken(data.access_token);
    this.props.setUser({ ...data.user });
    window.location.href = "/";
  };
  // Handle google Login START..

  // Check Reset password email
  checkEmail = async () => {
    this.setState({
      forgetPasswordLoader: true,
    });
    const { fields } = this.state;
    const { success, data, message, errors } = await authService.forgetPassword(
      {
        email: fields.email,
      }
    );
    // forgetStep: 1,
    // forgetPasswordError: "",
    if (!success) {
      if (errors) {
        const handelErrors = getResponseErrors(errors);
        this.setState({
          forgetPasswordError: handelErrors,
        });
      } else {
        this.setState({
          forgetPasswordError: message,
        });
      }
      return;
    }
    this.setState({ forgetStep: 2 });
    this.setState({
      forgetPasswordLoader: false,
    });
  };
  // Check Reset password email end

  // check Verification Code start
  checkVerificationCode = async (inputCode) => {
    this.setState({
      forgetPasswordLoader: true,
    });
    const { fields, userVerificationCode } = this.state;
    const { error: errorTag } = this.props.i18n;
    const { success, data, message, errors } =
      await authService.verifyForgetPassword({
        email: fields.email,
        otp: userVerificationCode,
      });

    this.setState({
      forgetPasswordLoader: false,
    });

    if (!success) {
      if (errors) {
        const handelErrors = getResponseErrors(errors);

        this.setState({
          forgetPasswordError: handelErrors,
        });
      } else {
        this.setState({
          forgetPasswordError: message,
        });
      }
      return;
    }
    this.setState({ forgetStep: 3 });


  };

  resetForgetPassword = async (inputCode) => {
    this.setState({
      forgetPasswordLoader: true,
    });
    const { fields, userVerificationCode } = this.state;
    const { error: errorTag } = this.props.i18n;
    const { success, data, message, errors } =
      await authService.resetForgetPassword({
        email: fields.email,
        otp: userVerificationCode,
        password: fields.password,
        password_confirmation: fields.password_confirmation,
      });

    this.setState({
      forgetPasswordLoader: false,
    });

    if (!success) {
      if (errors) {
        const { password } = getResponseErrors(errors);
        console.log(password);
        this.setState({
          passwordError: password,
        });
      } else {
        this.setState({
          passwordError: message,
        });
      }
      return;
    }

    this.setState({
      forgetPasswordModal: false,
    });

    const {
      i18n: {
        successResetPassword,
        success:successText
      },
    } = this.props;

    return displayAlert(
      successText,
      successResetPassword,
      "success",
      "noButtons",
      2000
    ).then(() => (window.location.reload()));
  };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
  country,
});
export default connect(mapStateToProps, { setLanguage, setUser })(Login);
