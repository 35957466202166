import React, { Component } from "react";
import { Container, Row, Col, InputGroup } from "react-bootstrap";
import TextInput from "../../blocks/TextInput";

import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { setUser } from "../../../redux/actions-reducers/user";
import { authService } from "../../../services/AuthService";
import { apiService } from "../../../services/ApiService";
import { displayAlert, getResponseErrors, toTop } from "../../../utils/misc";
import { Link } from "react-router-dom";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
import Label from "../../blocks/Label";

class SocialLogin extends Component {
    state = {
        fields: {
            name: this.props.currentUser?.socialMedia?.name ?? "",
            phone: this.props.currentUser?.socialMedia?.phone ?? "",
            email: this.props.currentUser?.socialMedia?.email ?? "",
            social_provider: this.props.currentUser?.socialMedia?.social_provider ?? "",
            social_provider_id: this.props.currentUser?.socialMedia?.social_provider_id ?? "",
            social_image: this.props.currentUser?.socialMedia?.social_image ?? "",
        },
        errors: {
            firstName: "",
            lastName: "",
            phone: "",
            password_confirmation: "",
            password: "",
            email: "",
        },
    };
    componentDidMount() {
        toTop();
        localStorage.removeItem("safeerAccessToken");
    }
    responseFacebook = async (response) => {

        const { error: errorTag } = this.props.i18n;
        const { success, data, message, errors } = await authService.socialLogin({
            phone: "",
            place_id: this.props.country.id, // What
            email: response.email ? response.email : "",
            name: response.name ? response.name : "",
            social_image: response.image ? response.image : "",
            social_provider_id: response.id ? response.id : "",
            social_provider: "facebook",
        });

        if (!success) {

            if (errors) {
                this.props.setUser({
                    socialMedia: {
                        email: response?.email ?? "",
                        name: response?.name ?? "",
                        phone: response?.phone ?? "",
                        password_confirmation: "",
                        password: "",
                        terms_conditions: false,
                        social_image: response?.image ?? "",
                        social_provider_id: response?.id ?? "",
                        social_provider: "facebook",
                    },
                });
                setTimeout(() => {
                    window.location.href = "/SocialLogin";
                }, 0);
            } else {
                return displayAlert(errorTag, message, "error");
            }

        }


        apiService.storeToken(data.access_token);
        this.props.setUser({ ...data.user });
        window.location.href = "/";
    };
    responseGoogle = async (response) => {
        console.error(response);
        const { error: errorTag } = this.props.i18n;
        const { success, data, message, errors } = await authService.socialLogin({
            phone: "",
            place_id: this.props.country.id,
            email: response.profileObj.email ? response.profileObj.email : "",
            name: response.profileObj.givenName
                ? response.profileObj.givenName + " " + response.profileObj.familyName
                : "",
            social_image: response.profileObj.image ? response.profileObj.image : "",
            social_provider_id: response.profileObj.googleId
                ? response.profileObj.googleId
                : "",
            social_provider: "google",
        });
        if (!success) {
            if (errors) {
                const handelErrors = getResponseErrors(errors);
                this.setState({
                    errors: handelErrors,
                });
                return;
            } else {
                return displayAlert(errorTag, message, "error");
            }
        }
        apiService.storeToken(data.access_token);
        this.props.setUser({ ...data.user });
        window.location.href = "/";
    };
    render() {
        const {
            language,
            i18n: {
                CreateNewAccount,
                FirstName,
                LastName,
                Email,
                Mobile,
                Password,
                ConfirmPassword,
                CreateBtn,
                LoginByFacebookOrGoogle,
                HaveAcount,
                LoginBtn,
                Or,
                fillSocialMediaInputs,
                submit,
                FullName
            },
        } = this.props;

        const { errors, fields } = this.state;
        return (
            <Container>
                <div
                    className={`SocialLogin-login ${language === "rtl" && "SocialLogin-login-arabic"
                        }`}
                >
                    {/* create new account title */}
                    <h3>{fillSocialMediaInputs}</h3>
                    {/* create new account end */}

                    <div className="SocialLogin-login__form">
                        <Row>
                            {/* FullName input start  */}
                            <Col sm={6}>
                                <TextInput
                                    placeholder={FullName}
                                    label={FullName}
                                    name="name"
                                    value={fields.name}
                                    onFieldChange={this.onFieldChange}
                                    validate={errors.name}
                                />
                            </Col>
                            {/* FullName input end  */}

                            {/* Email input start  */}
                            <Col sm={6}>
                                <TextInput
                                    placeholder={Email}
                                    name="email"
                                    value={fields.email}
                                    label={Email}
                                    onFieldChange={this.onFieldChange}
                                    validate={errors.email}
                                />
                            </Col>
                            {/* Email input end  */}
                            {/* Mobile input start  */}
                            <Col sm={6}>
                                <div
                                    className={`text-input-block phone-input ${language === "rtl" && "phone-input-arabic"
                                        }`}
                                >
                                    <Label label={Mobile} tooltip={""} isRequired={false} />
                                    <div className="phone-input__content">
                                        <div className="phone-input__content__country--code">
                                            {this.props.country.zipcode ?? "+962"}
                                        </div>
                                        <InputGroup className="phone-input__content__container">
                                            <input
                                                className="phone-input__content__container__field"
                                                type="number"
                                                placeholder={Mobile}
                                                name="phone"
                                                onInput={({ target }) =>
                                                (target.value = Math.max(0, parseInt(target.value))
                                                    .toString()
                                                    .slice(0, this.props.country.digits))
                                                }
                                                onChange={({ target: { value } }) =>
                                                    this.setState({
                                                        fields: {
                                                            ...fields,
                                                            phone: value,
                                                        },
                                                    })
                                                }
                                                value={fields.phone}
                                                validate={errors.phone}
                                            />
                                            
                                        </InputGroup>
                                       
                                    </div>
                                    {
                                        errors.phone && 
<div class="validation-message--underline"><small class="font-weight-bold text-capitalize ls-1">{errors.phone}</small></div>
                                    }
                                    
                                </div>
                            </Col>
                            {/* Mobile input end  */}

                        </Row>
                    </div>
                    {/* SocialLogin form End */}

                    {/* Create account button */}
                    <br />
                    <button className="submit-button" onClick={this.submit}>
                        {submit}
                    </button>
                    {/* Create account button end*/}

                    {/* SocialLogin with facebook or google section End */}
                    {/* Already have account section start */}
                    {/* <h5>{HaveAcount}</h5>
                    <Link to="/login" className="submit-button login-button">
                        {LoginBtn}
                    </Link> */}
                    {/* Already have account section end */}
                </div>
            </Container>
        );
    }

    onFieldChange = (name, value) => {
        const { fields } = this.state;
        this.setState({
            fields: { ...fields, [name]: value },
            errors: {
                name: "",
                mobile: "",
                email: "",
                phone:""
            },
        });
    };

    // sign up function start
    submit = async () => {
        const { fields } = this.state;
        const { country } = this.props;
     
        const { success, data, message, errors } = await authService.socialLogin({
            place_id: country.id,
            password: fields.password,
            email: fields.email,
            phone: fields.phone,
            name: fields.name,
            social_provider: fields.social_provider,
            social_provider_id: fields.social_provider_id,
        });
        const { error: errorTag } = this.props.i18n;

        if (!success) {
            if (errors) {
                const handelErrors = getResponseErrors(errors);
                this.setState({
                    errors: handelErrors,
                });
                return;
            } else {
                return displayAlert(errorTag, message, "error");
            }
        }

        apiService.storeToken(data.access_token);

        this.props.setUser({ ...data.user });

        window.location.href = "/";
    };
    // sign up function end
}

const mapStateToProps = ({ language, currentUser, country }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
    country,
});
export default connect(mapStateToProps, { setLanguage, setUser })(SocialLogin);
