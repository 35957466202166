import React, { Component } from "react";
import { Container, Row, Col, InputGroup } from "react-bootstrap";
import TextInput from "../../blocks/TextInput";

import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { setUser } from "../../../redux/actions-reducers/user";
import { authService } from "../../../services/AuthService";
import { apiService } from "../../../services/ApiService";
import { displayAlert, getResponseErrors, toTop } from "../../../utils/misc";
import { Link } from "react-router-dom";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
import Label from "../../blocks/Label";

class SignUp extends Component {
  state = {
    fields: {
      firstName: "",
      lastName: "",
      phone: "",
      password_confirmation: "",
      password: "",
      email: "",
    },
    errors: {
      firstName: "",
      lastName: "",
      phone: "",
      password_confirmation: "",
      password: "",
      email: "",
    },
  };
  componentDidMount() {
    toTop();
  }
  responseFacebook = async (response) => {
    if (!response.id) {
      return false;
    }
    const { error: errorTag } = this.props.i18n;
    const { success, data, message, errors } = await authService.socialLogin({
      phone: "",
      place_id: this.props.country.id, // What
      email: response.email ? response.email : "",
      name: response.name ? response.name : "",
      social_image: response.image ? response.image : "",
      social_provider_id: response.id ? response.id : "",
      social_provider: "facebook",
    });

    if (!success) {
      if (errors) {
        this.props.setUser({
          socialMedia: {
            email: response?.email ?? "",
            name: response?.name ?? "",
            phone: response?.phone ?? "",
            password_confirmation: "",
            password: "",
            terms_conditions: false,
            social_image: response?.image ?? "",
            social_provider_id: response?.id ?? "",
            social_provider: "facebook",
          },
        });
        setTimeout(() => {
          window.location.href = "/SocialLogin";
        }, 0);
      } else {
        return displayAlert(errorTag, message, "error");
      }
    }

    apiService.storeToken(data.access_token);
    this.props.setUser({ ...data.user });
    window.location.href = "/";
  };
  responseGoogle = async (response) => {
    console.error(response);
    const { error: errorTag } = this.props.i18n;
    const { success, data, message, errors } = await authService.socialLogin({
      phone: "",
      place_id: this.props.country.id,
      email: response.profileObj.email ? response.profileObj.email : "",
      name: response.profileObj.givenName
        ? response.profileObj.givenName + " " + response.profileObj.familyName
        : "",
      social_image: response.profileObj.image ? response.profileObj.image : "",
      social_provider_id: response.profileObj.googleId
        ? response.profileObj.googleId
        : "",
      social_provider: "google",
    });
    if (!success) {
      if (errors) {
        this.props.setUser({
          socialMedia: {
            email: response.profileObj.email ? response.profileObj.email : "",
            name: response.profileObj.givenName
              ? response.profileObj.givenName +
                " " +
                response.profileObj.familyName
              : "",
            phone: response?.profileObj?.phone ?? "",
            social_image: response.profileObj.image ?? "",
            social_provider_id: response.profileObj.googleId ?? "",
            social_provider: "google",
          },
        });
        setTimeout(() => {
          window.location.href = "/SocialLogin";
        }, 0);
      } else {
        return displayAlert(errorTag, message, "error");
      }
    }
    apiService.storeToken(data.access_token);
    this.props.setUser({ ...data.user });
    window.location.href = "/";
  };
  render() {
    const {
      language,
      i18n: {
        CreateNewAccount,
        FirstName,
        LastName,
        Email,
        Mobile,
        Password,
        ConfirmPassword,
        CreateBtn,
        LoginByFacebookOrGoogle,
        HaveAcount,
        LoginBtn,
        Or,
      },
    } = this.props;

    const { errors, fields } = this.state;
    return (
      <Container>
        <div
          className={`signup-login ${
            language === "rtl" && "signup-login-arabic"
          }`}
        >
          {/* create new account title */}
          <h3>{CreateNewAccount}</h3>
          {/* create new account end */}

          <span>
            {HaveAcount} &nbsp;
            {/* login button start  */}
            <Link to="/login" className="signup-login__link">
              {LoginBtn}
            </Link>
            {/* login button end  */}
          </span>
          {/* Signup form start */}
          {/* SignUp with facebook or google section start */}
          <h4>{LoginByFacebookOrGoogle}</h4>
          <Container className="signup-login__by-fb-google">
            <Row className="no-wrap">
              {/* SignUp with facebook  start */}
              <Col md={6} sm={12} className="text-center mt-2">
                <FacebookLogin
                  appId="393196132597853"
                  autoLoad={false}
                  fields="id,name,email,picture"
                  callback={this.responseFacebook}
                  cssclassName=""
                  isMobile={false}
                  icon="fa fa-facebook-f fa-2x"
                  textButton=""
                  className="socialBtn fb-btn h-100"
                />
              </Col>
              {/* SignUp with facebook  end */}

              {/* SignUp with  google  start */}
              <Col md={6} sm={12} className="text-center mt-2 googleImg">
                <GoogleLogin
                  clientId="741158028277-aa83fs9vvqlovsu8f5ab4nggrfhc353b.apps.googleusercontent.com"
                  buttonText={``}
                  onSuccess={this.responseGoogle}
                  onFailure={(error) => {
                    console.log(error);
                  }}
                  cookiePolicy={"single_host_origin"}
                  className="socialBtn gl-btn"
                />
              </Col>
              {/* SignUp with  google  end */}
            </Row>
          </Container>

          <Container>
            <Row>
              <Col xs={12}>
                <h4 className="text-center m-0 mt-4">{Or}</h4>
              </Col>
            </Row>
          </Container>
          <div className="signup-login__form">
            <Row>
              {/* FirstName input start  */}
              <Col sm={6}>
                <TextInput
                  placeholder={FirstName}
                  label={FirstName}
                  name="firstName"
                  value={fields.firstName}
                  onFieldChange={this.onFieldChange}
                  validate={errors.firstName}
                />
              </Col>
              {/* FirstName input end  */}

              {/* LastName input start  */}
              <Col sm={6}>
                <TextInput
                  placeholder={LastName}
                  label={LastName}
                  name="lastName"
                  value={fields.lastName}
                  onFieldChange={this.onFieldChange}
                  validate={errors.lastName}
                />
              </Col>
              {/* LastName input end  */}

              {/* Email input start  */}
              <Col sm={6}>
                <TextInput
                  placeholder={Email}
                  name="email"
                  value={fields.email}
                  label={Email}
                  onFieldChange={this.onFieldChange}
                  validate={errors.email}
                />
              </Col>
              {/* Email input end  */}
              {/* Mobile input start  */}
              <Col sm={6}>
                <div
                  className={`text-input-block phone-input ${
                    language === "rtl" && "phone-input-arabic"
                  }`}
                >
                  <Label label={Mobile} tooltip={""} isRequired={false} />
                  <div className="phone-input__content">
                    <div className="phone-input__content__country--code">
                      {this.props.country.zipcode ?? "+962"}
                    </div>
                    <InputGroup className="phone-input__content__container">
                      <input
                        className="phone-input__content__container__field"
                        type="number"
                        placeholder={Mobile}
                        name="phone"
                        onInput={({ target }) =>
                          (target.value = Math.max(0, parseInt(target.value))
                            .toString()
                            .slice(0, this.props.country.digits))
                        }
                        onChange={({ target: { value } }) =>
                          this.setState({
                            fields: {
                              ...fields,
                              phone: value,
                            },
                          })
                        }
                        value={fields.phone}
                        validate={errors.phone}
                      />
                    </InputGroup>
                  </div>
                </div>
              </Col>
              {/* Mobile input end  */}

              {/* Password input start  */}
              <Col sm={6}>
                {" "}
                <TextInput
                  placeholder={Password}
                  withImg
                  name="password"
                  value={fields.password}
                  label={Password}
                  type="password"
                  onFieldChange={this.onFieldChange}
                  validate={errors.password}
                />
              </Col>
              {/* Password input end  */}
              {/* ConfirmPassword input start  */}
              <Col sm={6}>
                <TextInput
                  placeholder={ConfirmPassword}
                  withImg
                  name="password_confirmation"
                  value={fields.password_confirmation}
                  label={ConfirmPassword}
                  type="password"
                  onFieldChange={this.onFieldChange}
                  validate={errors.password_confirmation}
                />
              </Col>
              {/* ConfirmPassword input end  */}
            </Row>
          </div>
          {/* Signup form End */}

          {/* Create account button */}
          <button className="submit-button" onClick={this.submit}>
            {CreateBtn}
          </button>
          {/* Create account button end*/}

          {/* SignUp with facebook or google section End */}
          {/* Already have account section start */}
          {/* <h5>{HaveAcount}</h5>
                    <Link to="/login" className="submit-button login-button">
                        {LoginBtn}
                    </Link> */}
          {/* Already have account section end */}
        </div>
      </Container>
    );
  }

  onFieldChange = (name, value) => {
    const { fields } = this.state;
    this.setState({
      fields: { ...fields, [name]: value },
      errors: {
        firstName: "",
        lastName: "",
        mobile: "",
        password_confirmation: "",
        password: "",
        email: "",
      },
    });
  };

  // sign up function start
  submit = async () => {
    const { fields } = this.state;
    const { country } = this.props;
    const { success, data, message, errors } = await authService.SignUp({
      password_confirmation: fields.password_confirmation,
      place_id: country?.language[0]?.id,
      password: fields.password,
      email: fields.email,
      phone: fields.phone,
      // country_id: country?.id,
      name: `${fields.firstName}  ${fields.lastName}`,
      // first_name: fields.firstName,
      // last_name: fields.lastName,
    });
    const { error: errorTag } = this.props.i18n;

    if (!success) {
      if (errors) {
        const handelErrors = getResponseErrors(errors);
        this.setState({
          errors: handelErrors,
        });
        return;
      } else {
        return displayAlert(errorTag, message, "error");
      }
    }

    apiService.storeToken(data.access_token);

    this.props.setUser({ ...data.user });

    window.location.href = "/";
  };
  // sign up function end
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
  country,
});
export default connect(mapStateToProps, { setLanguage, setUser })(SignUp);
