import axios from "axios";

class ApiService {
  baseURL = "https://api.safeercart.com/api/";
  token = "f95aedfed793a46023f9baa7d769c4c01be459cf";
  imageLink = "https://api.safeercart.com/public/storage/";

  get accessToken() {
    return window.localStorage.getItem("safeerAccessToken") ?? 0;
  }

  get language() {
    return window.localStorage.getItem("langCode") === "rtl" ? "ar" : "en";
  }
  authenticated() {
    return axios.create({
      baseURL: this.baseURL,
      headers: {
        Token: this.token,
        Authorization: `Bearer ${this.accessToken}`,
        Language: this.language,
      },
    });
  }

  unauthenticated() {
    return axios.create({
      baseURL: this.baseURL,
      headers: { Token: this.token, Language: this.language },
    });
  }

  storeToken(token) {
    window.localStorage.setItem("safeerAccessToken", token);
  }
}

export const apiService = new ApiService();
