import React, { Component } from "react";
import { Col, InputGroup, Modal, Row } from "react-bootstrap";
import TextInput from "../../blocks/TextInput";
import SelectInput from "../../blocks/SelectInput";
import LocationPicker from "react-location-picker";
import { connect } from "react-redux";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { locationService } from "../../../services/LocationService";
import {
  displayAlert,
  getResponseErrors,
  mapSelectData,
} from "../../../utils/misc";
import Global from "../../../language/Global.json";
import { generalServices } from "../../../services/GeneralService";
import Label from "../../blocks/Label";

class LocationModal extends Component {
  state = {
    addAddress: this.props.show,
    /* Default position */
    defaultPosition: {
      lat: 31.963158,
      lng: 35.930359,
    },
    position: {
      lat: 31.963158,
      lng: 35.930359,
    },
    fields: {
      nickname: "",
      address: "Al-Urdon St. 227, Amman, Jordan",
      building: "",
      phone: this.props.currentUser.phone,
      floor: "",
      appartment: "",
    },
    errors: {
      nickname: "",
      address: "",
      building: "",
      phone: "",
      floor: "",
      appartment: "",
    },

    userAddressesData: [],
    places: [],
    loader: true,
    areas_list: [],
    placesArray: [],
    showSelect: true,
    placesIds: [],
  };
  componentDidMount() {
    this.getPlacesFunction(this.props.countryId, this.state.placesArray);
  }
  render() {
    const {
      language,

      i18n: {
        PlacesText,
        Name,
        BuildingNumber,
        FloorNumber,
        ApartmentNumber,
        DetailedDescription,
        City,
        AddYourAddress,
        phone,
        AddLocationButt,
      },
    } = this.props;
    const {
      fields,
      defaultPosition,
      errors,
      placesArray,
      showSelect,
      placesIds,
    } = this.state;
    return (
      <Modal
        show={this.props.show}
        size="lg"
        effect="fadeInUp"
        onHide={this.props.closeModal}
        className={`location-modal ${
          language === "rtl" && "location-modal-arabic"
        }`}
      >
        <div className="modal-title flex">
          {/* modal title start  */}
          <h5> {AddYourAddress}</h5>
          {/* modal title end  */}

          {/* close modal icon start  */}
          <i
            onClick={() => {
              this.setState(
                {
                  placesArray: [placesArray[0]],

                  fields: {
                    nickname: "",
                    address: "Al-Urdon St. 227, Amman, Jordan",
                    building: "",
                    phone: this.props.currentUser.phone,
                    floor: "",
                    appartment: "",
                  },
                },
                () => {
                  this.props.closeModal();
                }
              );
            }}
            className="fa fa-times"
          ></i>
          {/* close modal icon end  */}
        </div>

        <div className="address-inputs">
          <Row>
            {/* nickname input start  */}
            <Col lg={6} md={6} sm={6} className="mb-0">
              <TextInput
                name="nickname"
                maxLength={12}
                label={Name}
                placeholder={Name}
                value={fields.nickname}
                onFieldChange={this.onFieldChange}
                validate={errors.nickname}
              />
            </Col>
            
            <Col lg={6} md={6} sm={6} className="mb-0">
              <TextInput
                name="phone"
                type="tel"
                maxLength={12}
                label={phone}
                placeholder={phone}
                value={fields.phone}
                onFieldChange={(name, value) => {
                  if (value.length <= 9) this.onPhoneFieldChange(value);
                }}
                validate={errors.phone}
                code={this.props.country.zipcode ?? "+962"}
              />
            </Col>

            {
              /* 
              <Col lg={4} md={4} sm={4} className="mb-0">
              <TextInput
                name="building"
                type="number"
                label={BuildingNumber}
                placeholder={BuildingNumber}
                value={fields.building}
                onFieldChange={this.onFieldChange}
                validate={errors.building}
              />
            </Col>
              */
            }
            {/* Building Number input end  */}

            {/* FloorNumber input end  */}
            {
              /*
              <Col lg={4} md={4} sm={4} className="mb-0">
              <TextInput
                name="floor"
                type="number"
                label={FloorNumber}
                placeholder={FloorNumber}
                value={fields.floor}
                onFieldChange={this.onFieldChange}
                validate={errors.floor}
              />
            </Col>
              */
            }
            {/* FloorNumber input end  */}

            {/* ApartmentNumber input end  */}
            {
              /* 
              <Col lg={4} md={4} sm={4} className="mb-0">
              <TextInput
                name="appartment"
                type="number"
                label={ApartmentNumber}
                placeholder={ApartmentNumber}
                value={fields.appartment}
                onFieldChange={this.onFieldChange}
                validate={errors.appartment}
              />
            </Col>
              */
            }
            {/* ApartmentNumber input end  */}

            {/* address input start  */}
            {
              /* 
              <Col lg={12} md={12} sm={12} className="mb-0">
              <TextInput
                name="address"
                label={DetailedDescription}
                placeholder={DetailedDescription}
                value={fields.address}
                onFieldChange={this.onFieldChange}
                validate={errors.address}
              />
            </Col>
              */
            }
            {/* address input end  */}

            {/* dynamic place select  start  */}
            {placesArray > 0 ||
              (showSelect &&
                placesArray.map((item, index) => {
                  return (
                    <Col sm={12} className="mb-4">
                      <SelectInput
                        name={
                          item.filter(({ value }) => value == placesIds[index])
                            .label
                        }
                        label={index === 0 ? PlacesText : " "}
                        placeholder={City}
                        value={
                          item.filter(({ value }) => value == placesIds[index])
                            .value
                        }
                        onFieldChange={(name, value) =>
                          this.selectChange(value, index)
                        }
                        data={item}
                        validate={
                          index === placesArray.length - 1 && errors.place_id
                        }
                        // isTextArea
                      />
                    </Col>
                  );
                }))}
            {/* dynamic place select  end  */}

            {/* location map piker start  */}
            {/* <LocationPicker
              containerElement={<div style={{ height: "100%" }} />}
              mapElement={<div style={{ height: "400px" }} />}
              onChange={this.handleLocationChange}
              defaultPosition={defaultPosition}
              circleOptions={{ visible: false }}
            /> */}
            {/* location map piker end  */}

            {/* AddLocation Button start  */}
            <Col lg={12} md={12} sm={12} className="locationButton">
              <button
                className="submit-button2"
                onClick={() => this.addUserLocation()}
              >
                {AddLocationButt}
              </button>
            </Col>
            {/* AddLocation Button end  */}
          </Row>
        </div>
      </Modal>
    );
  }

  // on select Change function start
  selectChange = (value, index) => {
    const { fields, placesArray, placesIds } = this.state;

    if (placesArray[index]) {
      let arr = placesArray.slice(0, index + 1);
      this.setState({
        placesArray: arr,
      });
      this.getPlacesFunction(value.value, arr);
    } else {
      this.getPlacesFunction(value.value, placesArray);
    }
    // setValue(fieldData.fieldName, target.value);

    if (placesIds[index]) {
      let arr = placesIds;
      arr[index] = value.value;
      this.setState({
        placesIds: arr,
      });
    } else {
      this.setState({
        placesIds: [...placesIds, value.value],
      });
    }

    this.setState({
      fields: { ...fields, place_id: value },
      errors: {
        place_id: "",
      },
    });
  };

  // on field Change function start
  onFieldChange = (name, value) => {
    const { fields } = this.state;
    this.setState({
      fields: { ...fields, [name]: value },
      errors: {
        nickname: "",
        address: "",
        building: "",
        phone: "",
        floor: "",
        appartment: "",
      },
    });
  };
  onPhoneFieldChange = (value) => {
    if (typeof value !== "undefined" && isNaN(Number(value))) return;
    this.setState({
      fields: { ...this.state.fields, phone: value },
    });
  };

  addUserLocation = async () => {
    const { placesArray } = this.state;
    const { fields, position } = this.state;
    const {
      success: successText,
      LocationAdded,
      error: errorTag,
      FillAllFields,
    } = this.props.i18n;
    const { success, message, errors } = await locationService.addUserLocation({
      nickname: fields.nickname,
      address: fields.address,
      latitude: position.lat,
      longitude: position.lng,
      building: fields.building,
      phone: fields.phone,
      floor: fields.floor,
      appartment: fields.appartment,
      place_id: fields.place_id?.value,
    });
    if (!success) {
      if (errors) {
        const handelErrors = getResponseErrors(errors);
        this.setState({
          errors: handelErrors,
        });
      } else {
        this.props.closeModal()
        return displayAlert("Error", message, "error");
      }
    } else {
      displayAlert(successText, LocationAdded, "success");
      this.props.reFetchData();
      this.props.closeModal();
      this.setState({
        placesArray: [placesArray[0]],
        fields: {
          nickname: "",
          address: "Al-Urdon St. 227, Amman, Jordan",
          building: "",
          phone: "",
          floor: "",
          appartment: "",
        },
      });
    }
  };
  handleLocationChange = ({ position, address }) => {
    // Set new location
    const { fields } = this.state;
    this.setState({ position, fields: { ...fields, address } });
  };
  getPlacesFunction = async (id, placesArrayParameter) => {
    const { data, success } = await generalServices.getAllCountries(id);
    if (!success) return;
    if (data?.length > 0 && !this.state.showSelect) {
      this.setState({
        placesArray: [
          mapSelectData(data)?.map((item, index) => ({
            value: item.id,
            label: item.name,
          })),
        ],
      });
    }
    if (data?.length > 0 && this.state.showSelect) {
      this.setState({
        placesArray: [
          ...placesArrayParameter,
          data?.map((item, index) => ({ value: item.id, label: item.name })),
        ],
      });
    }
  };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
  countryId: country.id,
  country,
});

export default connect(mapStateToProps, { setLanguage })(LocationModal);
