import { apiEndPoints } from "../api";
import { handleResponse } from "../utils/misc";
import { apiService } from "./ApiService";

class CategoryService {
  get guestId() {
    return localStorage.getItem("guestId");
  }
  async getSingleCategoryProducts(categoryData, page) {
    try {
      const data = await apiService
        .authenticated()
        .post(apiEndPoints.category.getSingleCategoryProducts, {
          guest_id: apiService.accessToken === 0 ? this.guestId : 0,
          ...categoryData,
        })
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  async getSingleCategoryInfoAndFilters(machine_name) {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${
            apiEndPoints.category.getSingleCategoryInfoAndFilters
          }?machine_name=${machine_name}&?guest_id=${
            apiService.accessToken === 0 ? this.guestId : 0
          }`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
}

export const categoryService = new CategoryService();
