import React, { Component } from "react";
import {
  Col,
  Container,
  Row,
  InputGroup,
  Button,
  FormControl,
  Accordion,
} from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import TextInput from "../../blocks/TextInput";
import { GoogleApiWrapper } from "google-maps-react";
import { cartService } from "../../../services/CartService";
import { userService } from "../../../services/UserService";
import AccordionLocation from "./AccordionLocation";
import { apiService } from "../../../services/ApiService";
import { generalServices } from "../../../services/GeneralService";
import SelectInput from "../../blocks/SelectInput";
import { displayAlert, mapSelectData } from "../../../utils/misc";
import { locationService } from "../../../services/LocationService";
import { ErrorMessage } from "../../blocks/ErrorMessage";
import LocationModal from "../addressesPage/LocationModal";
import Skeleton from "react-loading-skeleton";
import GuestAddLocation from "./GuestAddLocation";
import moment from "moment";
class Checkout extends Component {
  state = {
    products: [1, 2, 3, 4],
    products2: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    fullView: false,
    eventKey: 0,
    Check: false,
    CreatAccount: false,
    cart: [],
    itemsCount: [],
    payment_methods: [],
    AddLocation: false,
    adsData: [
      {
        image: "/images/Group55.png",
        link: "/",
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
    ],
    myLocations: [],
    fields: {},
    errors: {},

    areas: [],
    cities: [],
    addAddress: false,

    defaultPosition: {
      lat: 31.963158,
      lng: 35.930359,
    },
    position: {
      lat: 31.963158,
      lng: 35.930359,
    },
    Coupon: [],
    CouponFields: {},
    cartAmount: {},
    couponError: false,
    locationLoader: true,
    couponErrorText: "",
    couponInputIsEmpty: true,
    eventKeyPayment: 1,
    eventKeyDate: 1,
    timeSlots: [
      { label: "9-12", value: "9-12" },
      { label: "12-3", value: "12-3" },
      { label: "3-7", value: "3-7" },
    ],
    selectedLocation: {},
    guestLocation: {},
  };
  get guestId() {
    return localStorage.getItem("guestId");
  }

  handleLocationChange = ({ position, address, places }) => {
    // Set new location
    this.setState({ position, address });
  };

  componentDidMount() {
    Promise.all([
      this.getUserCart(),
      this.getUserLocation(),
      this.getCities(),
      this.getPaymentMethodsByPlaceID(),
      this.getCheckoutAmounts(),
    ]);
    this.setState({
      fields: {
        ...this.state.fields,
        time: this.state.timeSlots[0],
        date: moment(moment().add(2, "days")._d).format("YYYY-MM-DD"),
      },
    });
  }
  // get Checkout Amounts function start
  getCheckoutAmounts = async () => {
    const { data, success } = await cartService.getCheckoutAmounts({
      payment_id: this.state.selectedPayment ?? "",
      delivery_company_id: 0,
      // place_id: this.props.country.id,
      place_id:
        apiService.accessToken === 0
          ? this.state.guestLocation?.place_id
          : this.state.selectedLocation?.place?.id,
      coupons: [],
    });

    if (!success) return;
    this.setState({
      cartAmount: data,
    });
  };
  // get Checkout Amounts function end

  // get Payment Methods By Place ID start
  async getPaymentMethodsByPlaceID() {
    const { success, data } = await generalServices.getPaymentMethodsByPlaceID(
      this.props.country.id
    );
    if (!success) return;
    this.setState(
      {
        payment_methods: data,
        selectedPayment: data[0]?.id,
      },
      () => {
        this.getCheckoutAmounts();
      }
    );
  }

  getUserCart = async () => {
    const { data, success } = await cartService.getUserCart();
    const itemsCount = data
      ?.map(({ qty }) => qty)
      .reduce((acc, currentValue) => {
        return currentValue + acc;
      }, 0);

    if (!success) return;
    this.setState({
      cart: data,
      itemsCount,
    });
  };
  // get User Cart end

  // get User Location start
  getUserLocation = async () => {
    if (apiService.accessToken === 0)
      return this.setState({
        locationLoader: false,
      });
    const { data, success } = await locationService.getUserLocation();

    if (!success) return;
    this.setState({
      myLocations: data,
      locationLoader: false,
      selectedLocation: data.length === 1 ? data[0] : {},
    });
  };
  // get User Location end

  // get Cities start
  getCities = async () => {
    const { country } = this.props;
    const { success, data } = await generalServices.getCities(country?.id);
    if (!success) return;
    this.setState({
      cities: mapSelectData(data),
    });
  };
  // get Cities end

  // get Areas start
  getAreas = async (id) => {
    const { success, data } = await generalServices.getAreas(id);
    if (!success) return;
    this.setState({
      areas: mapSelectData(data),
    });
  };
  // get Areas end

  // handle location change ////
  handleLocationChange = ({ position }) => {
    // Set new location
    this.setState({ position });
  };

  render() {
    const {
      addAddress,
      guestLocation,
      Check,
      timeSlots,
      eventKey,
      eventKeyPayment,
      eventKeyDate,
      couponInputIsEmpty,
      cartAmount,
      couponErrorText,
      couponError,
      myLocations,
      cart,
      selectedPayment,
      itemsCount,
      payment_methods,
      fields,
      errors,
      Coupon,
      CouponFields,
      locationLoader,
      selectedLocation,
    } = this.state;
    const {
      language,
      i18n: {
        deliveryAmount,
        DeliveryLocation,
        PaymentMethod,
        CashUponDelivery,
        ChoosePaymentMethod,
        DeliveryDate,
        WhenDoYouWantToReceive,
        AppropriateTime,
        TaxesAndFees,
        Cart,
        Items,
        OrderSummary,
        Delete,
        CouponText,
        Apply,
        CouponCode,
        SubTotal,
        Discount,
        feesAmount,
        taxesAmount,
        Total,
        ProcerdtoCheckout,
        Righttimefordelivery,
        BasicInformation,
        AddLocationButt,
        Date,
        Time,
      },
    } = this.props;

    return (
      <div className="Cart">
        {/** Title page and items count start */}
        <div className="CartTitle">
          <Container>
            <Row>
              <Col>
                <h2>
                  {Cart}
                  {/* items count start  */}
                  <h4>
                    {cart.length}

                    {Items}
                  </h4>
                  {/* items count end  */}
                </h2>
              </Col>
            </Row>
          </Container>
        </div>
        {/** Title page and items count end */}

        {/** delivery location, payment methods and date Accordions */}
        <Container>
          <Row>
            <Col xl={9} lg={9} md={8} sm={12} xs={12}>
              <div
                className={`AccordionFilters AccordionFiltersCheckout ${language === "rtl" && "Cart-Arabic"
                  }`}
              >
                <Accordion
                  accordion={true}
                  defaultActiveKey={0}
                  activeKey={eventKey}
                  expandMode={"multiple"}
                >
                  {/* user locations and guest location form start  */}
                  {apiService.accessToken !== 0 ? (
                    //  if is user
                    <Accordion.Item eventKey={0}>
                      {/* accordion header start  */}
                      <Accordion.Header
                        onClick={() => {
                          this.setState({
                            eventKey: eventKey !== 0 ? 0 : false,
                          });
                        }}
                      >
                        {DeliveryLocation}
                      </Accordion.Header>
                      {/* accordion header end  */}

                      <Accordion.Body>
                        {/* user locations list start  */}
                        <ul>
                          {/** Location Start */}
                          {locationLoader ? (
                            <>
                              <Col lg={12} md={12}>
                                <Skeleton height={120} />
                              </Col>
                              <Col lg={12} md={12}>
                                <Skeleton height={120} />
                              </Col>
                            </>
                          ) : (
                            myLocations?.map((item) => {
                              return (
                                <AccordionLocation
                                  data={item}
                                  selectedLocation={selectedLocation}
                                  changeAccordion={(id) =>
                                    this.setState(
                                      {
                                        Check: !Check,
                                        // eventKey: 1,
                                        selectedLocation:
                                          selectedLocation == item ? {} : item,
                                        Coupon: [],
                                      },
                                      () => this.getCheckoutAmounts()
                                    )
                                  }
                                />
                              );
                            })
                          )}
                          {/** Location Start */}
                        </ul>
                        {/* user locations list end  */}

                        {/** open modal Add Location start*/}
                        <Container>
                          <div className="Locations LocationsAdd">
                            <div className="LocationInfo">
                              <div className="LocationIcon">
                                <img src="/images/Bold-Outline.svg" alt="" />
                              </div>
                              <div className="AddToCartSpan">
                                <span
                                  onClick={() =>
                                    this.setState({
                                      addAddress: true,
                                    })
                                  }
                                >
                                  {AddLocationButt}
                                </span>
                              </div>
                            </div>
                            {/* plus icon to add location start  */}
                            <img
                              src={"/images/pluse.png"}
                              className="addLocation-icon"
                              alt="1"
                              onClick={() =>
                                this.setState({
                                  addAddress: true,
                                })
                              }
                            />
                            {/* plus icon to add location end  */}
                          </div>
                          {/** open modal Add Location end*/}
                        </Container>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : (
                    <Accordion.Item eventKey={0}>
                      {/* accordion title start  */}
                      <Accordion.Header
                        onClick={() => {
                          this.setState({
                            eventKey: eventKey !== 0 ? 0 : false,
                          });
                        }}
                      >
                        {BasicInformation}
                      </Accordion.Header>
                      {/* accordion title end  */}

                      {/* guest add location form start  */}
                      <GuestAddLocation
                        reFetchData={() => this.getUserLocation()}
                        changeAccordion={(guestLocation) =>
                          this.setState(
                            {
                              Check: !Check,
                              eventKey: 1,
                              guestLocation,
                            },
                            () => this.getCheckoutAmounts()
                          )
                        }
                      />
                      {/* guest add location form end  */}
                    </Accordion.Item>
                  )}
                  {/* user locations and guest location form start  */}
                </Accordion>

                {/** Payment Method Accordion start */}
                <Row className="mb-5">
                  <Col className="mb-5" sm={6}>
                    <Accordion
                      accordion={true}
                      defaultActiveKey={0}
                      activeKey={eventKeyPayment}
                      expandMode={"multiple"}
                    >
                      <Accordion.Item eventKey={1}>
                        {/** title  Accordion */}
                        <Accordion.Header
                          onClick={() => {
                            this.setState({
                              eventKeyPayment:
                                eventKeyPayment !== 1 ? 1 : false,
                            });
                          }}
                        >
                          {ChoosePaymentMethod}
                        </Accordion.Header>
                        {/** title  Accordion end */}

                        <Accordion.Body>
                          {/* payment methods list start  */}
                          <ul className="PaymentMethodAcc">
                            {payment_methods.map(
                              ({ name, image, id }, index) => {
                                return (
                                  <>
                                    <li key={index}>
                                      <label className="CehckBoxContainer_">
                                        <img
                                          src={apiService.imageLink + image}
                                          alt="l"
                                        />
                                        &nbsp;
                                        <span className="CehckBoxContainer_span">
                                          {name}
                                        </span>
                                        <input
                                          type="radio"
                                          name="cash"
                                          onClick={() =>
                                            this.setState(
                                              {
                                                selectedPayment: id,
                                              },
                                              () => this.getCheckoutAmounts()
                                            )
                                          }
                                          checked={
                                            +this.state.selectedPayment === +id
                                          }
                                        />
                                        <span className="mark">
                                          {+this.state.selectedPayment ===
                                            +id && (
                                              <img
                                                src="/images/check.svg"
                                                alt="k"
                                              />
                                            )}
                                        </span>
                                      </label>
                                    </li>
                                  </>
                                );
                              }
                            )}
                          </ul>
                          {/* payment methods list end  */}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    {/** Payment Method Accordion start */}
                  </Col>

                  {
                    /* 
                    <Col className="mb-5" sm={6}>
                   

<Accordion
                      accordion={true}
                      defaultActiveKey={1}
                      activeKey={eventKeyDate}
                      expandMode={"multiple"}
                    >
                      <Accordion.Item eventKey={1}>
                    
                        <Accordion.Header
                          onClick={() => {
                            this.setState({
                              eventKeyDate: eventKeyDate !== 1 ? 1 : false,
                            });
                          }}
                        >
                          {WhenDoYouWantToReceive}
                        </Accordion.Header>
               

                        <Accordion.Body>
                          <ul>
                     
                            <li>
                              <TextInput
                                min={moment(moment().add(2, "days")._d).format(
                                  "YYYY-MM-DD"
                                )}
                                // max={moment(moment().add(30, "days")._d).format("YYYY-MM-DD")}
                                placeholder={DeliveryDate}
                                label={DeliveryDate}
                                type={"date"}
                                name="date"
                                value={fields.date}
                                validate={errors.date}
                                onFieldChange={this.onFieldChange}
                              />
                            </li>

                            <li>
                              <SelectInput
                                name="time"
                                placeholder={AppropriateTime}
                                label={AppropriateTime}
                                value={fields.time}
                                onFieldChange={this.onFieldChange}
                                data={timeSlots}
                                validate={errors.time}
                              // isTextArea
                              />
                            </li>
                    
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                    */
                  }
                </Row>
              </div>
            </Col>
            {/** delivery location, payment methods and date Accordions End */}

            {/* order summary start  */}
            <Col xl={3} lg={3} md={4} sm={12} xs={12}>
              <div className="TotalCart">
                {/* title start  */}
                <h3>{OrderSummary}</h3>
                {/* title end  */}

                {/** items title Start  */}
                <div>
                  <div className="TotalsCart">
                    <span className="SubTotalSpan">{Items}</span>
                  </div>
                </div>
                {/** items title End  */}

                {/** Items name and quantity start */}
                <ol>
                  {cart?.map(({ product, qty }, index) => {
                    return (
                      <li>
                        <p>
                          {/* <strong> 0{index + 1}</strong> */}
                          {product?.title}
                        </p>
                        <span> X {qty}</span>
                      </li>
                    );
                  })}
                </ol>
                {/** Items name and quantity end */}

                {/** coupon section  start */}
                {(apiService.accessToken === 0
                  ? guestLocation?.place_id
                  : selectedLocation?.id) &&
                  selectedPayment && (
                    <div className="mb-3">
                      {/** coupon input  start */}

                      <InputGroup>
                        <FormControl
                          name={`couponCode-${Coupon.length}`}
                          value={
                            CouponFields[`couponCode-${Coupon.length - 1}`]
                          }
                          placeholder={CouponCode}
                          aria-label={CouponCode}
                          aria-describedby="basic-addon2"
                          onChange={({ target: { name, value } }) =>
                            this.onCouponFieldChange(name, value)
                          }
                        />
                        <Button
                          disabled={couponInputIsEmpty}
                          variant="outline-secondary"
                          id="button-addon2"
                          className={` ${language === "rtl" && "buttAr"}`}
                          onClick={this.checkCouponIfValid}
                        >
                          {Apply}
                        </Button>
                      </InputGroup>
                      {/** coupon input  end */}

                      {/** coupon input error  start */}
                      {couponError && (
                        <ErrorMessage message={couponErrorText} isSelect />
                      )}
                      {/** coupon input error  end */}

                      {/** valid coupons list  start */}
                      <ul className="valid-coupon-list mt-4">
                        {Coupon.map((item, index) => {
                          return (
                            <li data-index={index} key={index} className="mb-1">
                              <span>
                                {CouponText} : {item}
                              </span>
                              {/* delete valid coupon start */}
                              <span
                                onClick={() => this.deleteValidCoupon(index)}
                              >
                                {Delete}
                              </span>
                              {/* delete valid coupon end */}
                            </li>
                          );
                        })}
                      </ul>
                      {/** valid coupons list  end */}
                    </div>
                  )}
                {/** coupon section  end */}

                {/** Sub Total Start  */}
                <div>
                  <div className="TotalsCart">
                    <span className="SubTotalSpan">
                      {" "}
                      {SubTotal}{" "}
                      <span className="SubTotalSpanCount">
                        {itemsCount} {Items}{" "}
                      </span>
                    </span>

                    <span className="PriceSpan">
                      <sup>{this.props.country.currency}</sup>
                      {cartAmount?.items_amount}
                    </span>
                  </div>
                </div>
                {/** Sub Total End  */}

                {/** Discount Start  */}
                {cartAmount?.discount_amount > 0 && (
                  <div>
                    <div className="TotalsCart">
                      <span className="SubTotalSpan"> {Discount}</span>

                      <span className="PriceSpan">
                        <span>{cartAmount?.discount_amount}</span>
                      </span>
                    </div>
                  </div>
                )}
                {/** Discount End  */}

                {/** taxes Amount Start  */}
                {cartAmount?.taxes_amount > 0 && (
                  <div>
                    <div className="TotalsCart">
                      <span className="SubTotalSpan"> {TaxesAndFees}</span>

                      <span className="PriceSpan">
                        <sup>{this.props.country.currency}</sup>{" "}
                        {cartAmount?.taxes_amount}
                      </span>
                    </div>
                  </div>
                )}
                {/** taxes Amount End  */}

                {/** feesAmount Start  */}
                {cartAmount?.fees_amount > 0 && (
                  <div>
                    <div className="TotalsCart">
                      <span className="SubTotalSpan"> {feesAmount}</span>

                      <span className="PriceSpan">
                        <sup>{this.props.country.currency}</sup>{" "}
                        {cartAmount?.fees_amount}
                      </span>
                    </div>
                  </div>
                )}
                {/** feesAmount End  */}

                {/** delivery amount Start  */}
                {cartAmount?.delivery_amount > 0 && (
                  <div>
                    <div className="TotalsCart">
                      <span className="SubTotalSpan">{deliveryAmount}</span>

                      <span className="PriceSpan">
                        <sup>{this.props.country.currency}</sup>
                        {cartAmount?.delivery_amount}
                      </span>
                    </div>
                  </div>
                )}
                {/** delivery amount End  */}

                <hr></hr>

                {/** Total Start  */}
                <div>
                  <div className="TotalsCart">
                    <span className="SubTotalSpan"> {Total}</span> <br />
                    <br />
                    <span className="PriceSpan">
                      <sup>{this.props.country.currency}</sup>
                      {cartAmount?.total_amount}
                    </span>
                  </div>
                </div>
                {/** Total End  */}

                {/** Checkout Button */}
                <div className="CheckoutButt">
                  <a onClick={() => this.checkoutSubmit()}>
                    {ProcerdtoCheckout}
                  </a>
                </div>
                {/** Checkout end */}
              </div>
            </Col>
            {/* order summary end  */}
          </Row>
        </Container>

        {/* add/edit address modal */}
        <LocationModal
          show={addAddress}
          closeModal={this.closeModal}
          reFetchData={() => this.getUserLocation()}
        />
        {/* end add/edit address modal */}
      </div>
    );
  }

  checkCouponIfValid = async () => {
    const {
      selectedPayment,
      Coupon,
      CouponFields,
      selectedLocation,
      guestLocation,
    } = this.state;
    let couponReadyToSend = [];
    for (const item in CouponFields) {
      couponReadyToSend.push(CouponFields[item]);
    }

    const { data } = await cartService.checkCouponIfValid({
      guest_id: apiService.accessToken === 0 ? this.guestId : 0,
      payment_id: selectedPayment ?? "",
      delivery_company_id: 0,
      // place_id: this.props.country.id,
      place_id:
        apiService.accessToken === 0
          ? guestLocation.place_id
          : selectedLocation?.place?.id,
      coupon: CouponFields[`couponCode-${Coupon.length}`],
    });

    if (!data)
      return this.setState({
        couponError: true,
        couponErrorText: "Invalid Coupon Code",
      });

    if (Coupon.includes(CouponFields[`couponCode-${Coupon.length}`]))
      return this.setState({
        couponError: true,
        couponErrorText: "Coupon Used",
        CouponFields: { [`couponCode-${Coupon.length}`]: "" },
      });

    this.setState(
      {
        CouponFields: { [`couponCode-${Coupon.length}`]: "" },
        couponInputIsEmpty: true,
        Coupon: [...Coupon, ...couponReadyToSend],
      },
      () => {
        this.checkOutAmount();
      }
    );
  };

  deleteValidCoupon = (couponIndex) => {
    let newCoupon = this.state.Coupon.filter(
      (item, index) => index !== couponIndex
    );
    this.setState(
      {
        Coupon: newCoupon,
      },
      () => this.getCheckoutAmounts()
    );
  };

  checkOutAmount = async () => {
    const { selectedPayment, CouponFields } = this.state;
    let couponReadyToSend = [];
    for (const item in CouponFields) {
      couponReadyToSend.push(CouponFields[item]);
    }

    const { data, success } = await cartService.getCheckoutAmounts({
      payment_id: selectedPayment,
      delivery_company_id: 0,
      // place_id: this.props.country.id,
      place_id:
        apiService.accessToken === 0
          ? this.state.guestLocation?.place_id
          : this.state.selectedLocation?.place?.id,

      coupons: this.state.Coupon,
    });
    if (!success) this.setState({ couponError: true });
    this.setState({
      cartAmount: data,
      Coupon: data.coupons,
    });
  };

  onCouponFieldChange = (name, value) => {
    if (value === "") return this.setState({ couponInputIsEmpty: true });
    this.setState({
      couponError: false,
      couponInputIsEmpty: false,
      CouponFields: {
        [name]: value,
      },
    });
  };
  onCityChange = (name, value) => {
    const { fields } = this.state;
    this.setState({
      fields: { ...fields, [name]: value },
    });
    this.getAreas(value.value);
  };
  onFieldChange = (name, value) => {
    const { fields } = this.state;
    this.setState({
      fields: { ...fields, [name]: value },
      errors: {
        email: "",
        password: "",
      },
    });
  };
  addLocationSubmit = async (id, qty, reFetchData) => {
    const { fields, position } = this.state;
    const { success: successText, LocationAdded } = this.props.i18n;
    const { success } = await locationService.addLocationSubmit({
      nickname: fields.name,
      address: fields.address,
      latitude: position.lat,
      longitude: position.lng,
      phone: fields.phone,
      area_id: fields.selectedArea.value,
    });
    if (!success) return;
    displayAlert(successText, LocationAdded, "success").then(() =>
      this.setState({ addAddress: false })
    );
    this.getUserLocation();
  };

  checkoutSubmit = async () => {
    const { fields, selectedLocation, selectedPayment, Coupon, guestLocation } =
      this.state;
    const {
      success: successText,
      OrderCheckoutSuccessfully,
      error: errorTag,
      Oops,
      PleaseFillAllTheRequiredFields,
      Attention,
    } = this.props.i18n;
    let dataSendToApi = {};

    if (apiService.accessToken === 0) {
      dataSendToApi = {
        preferred_delivery_date: fields.date?.replaceAll("/", "-"),
        payment_method_id: selectedPayment,
        preferred_time_slot: fields.time?.value,
        coupons: Coupon,
        notes: "",
        user_location_id: 0,
        delivery_comapny_id: "",

        guest_details: {
          place_id: guestLocation?.place_id,
          person_name: guestLocation?.name,
          latitude: guestLocation?.latitude,
          longitude: guestLocation?.longitude,
          building: guestLocation?.building,
          floor: guestLocation?.floor,
          appartment: guestLocation?.apartment,
          address: guestLocation?.address,
          phone: guestLocation?.phone,
          email: "",
        },
      };
    } else {
      dataSendToApi = {
        preferred_delivery_date: fields.date?.replaceAll("/", "-"),
        payment_method_id: selectedPayment,
        preferred_time_slot: fields.time?.value,
        // ?.replaceAll("/", "-"), // "preferred_time_slot":"10:00 - 12:00",
        coupons: Coupon,
        notes: "",
        user_location_id: selectedLocation?.id,
        delivery_comapny_id: "",
        guest_details: {},
      };
    }

    if (
      (selectedLocation?.id || guestLocation.place_id) &&
      selectedPayment &&
      fields.time?.value &&
      fields.date
    ) {
      const { success, message, data } = await userService.checkoutSubmit(
        dataSendToApi
      );

      if (!success) {
        return displayAlert(Attention, message, "warning").then((ok) => {
          if (ok) {
            window.location.href = "/cart";
          }
        });
      }

      if (data.has_payment_link) {
        window.location.href = apiService.baseURL + data.payment_link;
        return false;
      }

      displayAlert(
        successText,
        OrderCheckoutSuccessfully,
        "success",
        "noButtons",
        2000
      ).then(() => (window.location.href = "/orders/1"));
    } else {
      displayAlert(Oops, PleaseFillAllTheRequiredFields, "warning");
    }
  };

  closeModal = () => {
    this.setState({ addAddress: false });
  };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
  country,
});

export default connect(
  mapStateToProps,
  setLanguage
)(
  GoogleApiWrapper({
    apiKey: "AIzaSyBeEQoJ2XPNATY5YWXatV45hD6DyGt7Y0s",
  })(Checkout)
);
