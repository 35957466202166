import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CartProductBlock from "../Cart/CartProductBlock";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setUser } from "../../../redux/actions-reducers/user";
import { cartService } from "../../../services/CartService";
import FavouriteProductBlock from "./../Favourite/FavoriteProductBlock";
import Skeleton from "react-loading-skeleton";
import { displayAlert, tabletScreen } from "../../../utils/misc";
import { generalServices } from "../../../services/GeneralService";
import { apiService } from "../../../services/ApiService";
import { useHistory } from "react-router-dom";

class CartClass extends Component {
  state = {
    fullView: false,
    cart: [],
    favorites: [],
    payment_methods: [],
    itemsPriceBeforeDiscount: 0,
    itemsPriceAfterDiscount: 0,
    itemsCount: [],
    customerData: [],
    loaderPage: true,
    cartAmount: {},
    adsData: {},
    adsLoader: true,
  };

  componentDidMount() {
    Promise.all([
      this.getUserCart(),
      this.getCheckoutAmounts(),
      this.getUserFavorites(),
      this.getSingleAds(),
    ]);
    // console.log(this.props);
  }

  getSingleAds = async () => {
    const { data, success } = await generalServices.getSingleAds(
      "home_under_categories_block_left_space",
      0,
      this.props.country.id
    );
    if (!success) return;
    this.setState({
      adsData: data,
      adsLoader: false,
    });
  };

  getUserFavorites = async () => {
    const { data, success } = await cartService.getUserFavorites();
    if (!success) return;
    this.setState({
      favorites: data,
    });
  };

  getUserFavorites = async () => {
    const { data, success } = await cartService.getUserFavorites();
    if (!success) return;
    this.setState({
      favorites: data,
    });
  };

  getUserCart = async () => {
    const { data, success } = await cartService.getUserCart();

    if (!success) return;

    const itemsCount = data?.map(({ qty }) => qty);
    this.setState({
      cart: data,
      itemsCount,
      payment_methods: data.payment_methods,
      loaderPage: false,
    });
  };


  getCheckoutAmounts = async () => {
    const { data, success } = await cartService.getCheckoutAmounts({
      payment_id: 0,
      delivery_company_id: 0,
      // place_id: this.props.country.id,
      place_id: "",
      coupons: [],
    });
    if (!success) return;
    this.setState({
      cartAmount: data,
    });
  };
  getProductsCustomersViewed = async () => {
    // const { data, success } = await productService.getProductsCustomersViewed(0);
    // if (!success) return;
    // this.setState({
    //     customerData: data,
    // });
  };

  render() {
    const { adsData, favorites, cart, cartAmount, adsLoader, loaderPage } =
      this.state;
    const {
      language,
      currentUser,
      i18n: {
        Wishlist,
        cartSummary,
        Cart,
        Items,
        SubTotal,
        Discount,
        Total,
        taxesAmount,
        DoYouWantToContinueAsGuest,
        ContinueAsGuest,
        LoginBtn,
        Attention,
        ProcerdtoCheckout,
        ContinueSopping,
        feesAmount,
        deliveryAmount,
        VIEWALL,
        cartEmpty,
      },
    } = this.props;

    return (
      <div className={`Cart ${language === "rtl" && "Cart-Arabic"}`}>
        {/** Title */}
        <div className="CartTitle">
          <Container>
            <Row>
              <Col>
                <h2>
                  {Cart}
                  <h4>
                    {cart.length}
                    {Items}
                  </h4>
                </h2>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <Row>
            <Col xl={cart.length > 0 ? 9 : 12} lg={9} md={12} sm={12} xs={12}>
              <div className="CartAds">
                {adsLoader ? (
                  <Row>
                    <Col>
                      <Skeleton height={tabletScreen ? 110 : 200} />
                    </Col>
                  </Row>
                ) : (
                  <Row className="no-margin"></Row>
                )}
              </div>
              {loaderPage ? (
                <Row>
                  <Col>
                    <Skeleton height={tabletScreen ? 100 : 170} />
                  </Col>
                </Row>
              ) : (
                <>
                  {cart.length < 1 && (
                    <div className="empty-products">
                      <img src="/images/Artboard 7.svg" alt="k" />
                      <p> {cartEmpty} </p>
                    </div>
                  )}
                  {cart?.map((item, index) => {
                    return (
                      <CartProductBlock
                        data={item}
                        key={index}
                        reFetchData={() =>
                          Promise.all([
                            this.getUserCart(),
                            this.getCheckoutAmounts(),
                            this.getUserFavorites(),
                          ])
                        }
                      />
                    );
                  })}
                </>
              )}
            </Col>
            {cart.length > 0 && (
              <Col xl={3} lg={3} md={12} sm={12} xs={12}>
                <div className="TotalCart">
                  <h3>{cartSummary}</h3>
                  <div>
                    <div className="TotalsCart">
                      <span className="SubTotalSpan">
                        {SubTotal}
                        <span className="SubTotalSpanCount">
                          {this.totalItemsCount()}
                          {Items}
                        </span>
                      </span>

                      <span className="PriceSpan">
                        <sup>{this.props.country.currency}</sup>
                        {cartAmount?.items_amount}
                      </span>
                    </div>
                  </div>

                  <ol>
                    {cart?.map(({ product, qty }, index) => {
                      return (
                        <li>
                          <p>{product?.title}</p>
                          <span> X {qty}</span>
                        </li>
                      );
                    })}
                  </ol>

                  {cartAmount?.discount_amount != 0 && (
                    <div>
                      <div className="TotalsCart">
                        <span className="SubTotalSpan"> {Discount}</span>

                        <span className="PriceSpan">
                          <sup>{this.props.country.currency}</sup>{" "}
                          {cartAmount?.discount_amount}
                        </span>
                      </div>
                    </div>
                  )}

                  {+cartAmount?.taxes_amount != 0 && (
                    <div>
                      <div className="TotalsCart">
                        <span className="SubTotalSpan"> {taxesAmount}</span>

                        <span className="PriceSpan">
                          <sup>{this.props.country.currency}</sup>{" "}
                          {cartAmount?.taxes_amount}
                        </span>
                      </div>
                    </div>
                  )}

                  {cartAmount?.fees_amount != 0 && (
                    <div>
                      <div className="TotalsCart">
                        <span className="SubTotalSpan"> {feesAmount}</span>

                        <span className="PriceSpan">
                          <sup>{this.props.country.currency}</sup>{" "}
                          {cartAmount?.fees_amount}
                        </span>
                      </div>
                    </div>
                  )}

                  {+cartAmount?.delivery_amount != 0 && (
                    <div>
                      <div className="TotalsCart">
                        <span className="SubTotalSpan">{deliveryAmount}</span>

                        <span className="PriceSpan">
                          <sup>{this.props.country.currency}</sup>
                          {cartAmount?.delivery_amount}
                        </span>
                      </div>
                    </div>
                  )}
                  <hr></hr>
                  <div>
                    <div className="TotalsCart">
                      <span className="SubTotalSpan"> {Total}</span> <br />
                      <br />
                      <span className="PriceSpan">
                        <sup>{this.props.country.currency}</sup>
                        {cartAmount?.total_amount}
                      </span>
                    </div>
                  </div>

                  <div className="CheckoutButt">
                    <a
                      onClick={() => {
                        if (cart.length > 0) {
                          if (currentUser?.email) {
                            this.props.goTo("/Checkout");
                          } else {
                            displayAlert(
                              Attention,
                              DoYouWantToContinueAsGuest,
                              "warning",
                              "",
                              "",
                              [LoginBtn, ContinueAsGuest]
                            ).then((ok) => {
                              if (ok) {
                                this.props.goTo("/Checkout");
                              } else {
                                this.props.goTo("/login");
                              }
                            });
                          }
                        }
                      }}
                    >
                      {ProcerdtoCheckout}
                    </a>
                    {/** Checkoout end */}

                    <a href="/">{ContinueSopping}</a>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Container>

        {/** Wishlist */}
        {favorites?.length > 0 && (
          <div className="CartFav">
            <Container>
              <Row>
                <Col>
                  <div className="categories-block__header">
                    <h3>{Wishlist}</h3>

                    <div className="hr" />
                    <a href="/Favourite" className="view-all">
                      <span>{VIEWALL}</span>
                      <img src="/images/arrow-small-right.svg" alt="l" />
                    </a>
                  </div>
                </Col>
              </Row>

              <Row>
                {favorites.map((item, index) => {
                  return (
                    <Col md={6}>
                      <FavouriteProductBlock
                        data={item}
                        key={index}
                        wishList
                        reFetchData={() =>
                          Promise.all([
                            this.getUserCart(),
                            this.getCheckoutAmounts(),
                            this.getUserFavorites(),
                          ])
                        }
                      />
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </div>
        )}
      </div>
    );
  }

  newPrice = (price, discount_percentage, fixed_amount) => {
    let discountAmount = (price * discount_percentage) / 100;
    let priceAfterDiscount = 0;
    if (discountAmount > fixed_amount) {
      priceAfterDiscount = price - fixed_amount;
      return priceAfterDiscount;
    } else {
      priceAfterDiscount = price - discountAmount;
      return priceAfterDiscount;
    }
  };

  totalItemsCount = () =>
    this.state.itemsCount.reduce((acc, currentValue) => {
      return currentValue + acc;
    }, 0);

  itemsPriceBeforeDiscount = () => {};

  itemsPriceAfterDiscount = () => {};

  discountAmount = () => {};
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
  country,
});

const ConnectedCart = connect(mapStateToProps, { setUser })(CartClass);

export default function HeaderMobile() {
  const goTo = (path) => {
    return (window.location.href = path);
  };
  return <ConnectedCart goTo={goTo} />;
}
