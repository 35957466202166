import React, { useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import Global from "../../../language/Global.json";
import { apiService } from "../../../services/ApiService";
import { statusTypesColor } from "../../../utils/misc";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import PrintInvoice from "./PrintInvoice";
import { generalServices } from "../../../services/GeneralService";

const Invoices = ({ data }) => {
  const language = useSelector(({ language }) => language);
  const currentUser = useSelector(({ currentUser }) => currentUser);
  const country = useSelector(({ country }) => country);
  const i18n = language.langCode === "ltr" ? Global.en : Global.ar;
  const [settings, setSettings] = React.useState({});

  const getFooter = async () => {
    const { success, data } = await generalServices.getFooter();
    if (!success) return;
    setSettings(data);
  };

  React.useEffect(() => {
    getFooter();
  }, []);

  const {
    Orders,
    OrdersTitle2,
    DeliverTo,
    OrderRef,
    Name,
    SafeerCo,
    DownloadInvoice,
    Date,
    Email,
    Phone,
    DeliveredOn,
    Username,
    days,
    BillTo,
    BillFrom,
    Company,
    Status,
    Pic,
    feesAmount,
    ItemName,
    Quntity,
    UnitPrice,
    Total,
    Note,
    taxesAmount,
    Discount,
    ItemsAmount,
    PaymentMethod,
    statementNumber,
    Time
  } = i18n;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [responsiveTable, setResponsiveTable] = useState(false);

  window.addEventListener("resize", () => {
    if (window.innerWidth === 786) {
      setResponsiveTable(true);
    }
  });
  return (
    <div
      className={`invoice ${language.langCode === "rtl" && "invoice-arabic"}`}
    >
      {/**************** header start ************/}
      <div className="px-3">
        <h2 className="invoice__title">{Orders}</h2>
        <p className="invoice__subtitle mb-4">{OrdersTitle2}</p>
      </div>
      {/**************** header end************/}

      {/**************** order number section start ************/}
      <div className="invoice__sec1 mb-4 p-3">
        <div className="flex gap-2">
          <div>
            <p className="mb-0 invoice__sec1__orderNum">
              {OrderRef} &nbsp; : &nbsp;{" "}
              {data?.order_information?.order_reference}
            </p>
          </div>
          {/* <div>{data?.order_information?.order_reference}</div> */}
          <div>
            <div>
              {/* <img src="/images/surface1.svg" alt="download-icon" className="mx-3" /> */}
              <button onClick={handlePrint} className="invoice__sec1__download">
                {DownloadInvoice}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="d-none">
        <PrintInvoice ref={componentRef} data={data} />
      </div>
      {/********* order number section end ******/}

      <div className="printable-section-container">
        {/* logo start  */}
        <div className="invoice-header">
          <img
            src="/images/Group 1131.svg"
            alt="logo"
            className="logo mb-4 pt-4"
          />
        </div>
        {/* logo start  */}

        {/* ## we create table to make the print output in a good style ## */}
        {/* web invoice table start */}
        <Table responsive="sm" className={`printable-section-container__table`}>
          {/* company and user details start  */}
          <thead className="printable-section-container__table__header">
            <tr>
              <th colSpan={2}>{BillTo}</th>
              <th>{BillFrom}</th>
            </tr>
          </thead>
          <tbody className="printable-section-container__table__body">
            <tr>
              <td colSpan={2}>
                <table>
                  <tr>
                    <td className="table-field">{Name} : </td>
                    <td className="ms-3">{currentUser.name}</td>
                  </tr>
                </table>
              </td>
              <td>
                <table>
                  <tr>
                    <td className="table-field">{Company} : </td>
                    <td className="ms-3">{settings.billing_company_name}</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <table>
                  <tr>
                    <td className="table-field">{DeliverTo} : </td>
                    <td className="ms-3">
                      {data?.order_information?.receiver_details?.address}
                    </td>
                  </tr>
                </table>
              </td>
              {/*  */}
              <td>
                <table>
                  <tr>
                    <td className="table-field">{Phone} :</td>
                    <td className="ms-3 direction-class">
                      {settings.billing_company_phone}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <table>
                  <tr>
                    <td className="table-field">{Phone} :</td>
                    <td className="ms-3">
                      {data?.order_information.receiver_details?.phone}
                    </td>
                  </tr>
                </table>
              </td>
              <td>
                <table>
                  <tr>
                    <td className="table-field">{Email} :</td>
                    <td className="ms-3">{settings.billing_company_email}</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <table>
                  <tr>
                    <td className="table-field">{Email} :</td>
                    <td className="ms-3">
                      {data?.order_information.receiver_details?.email}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <table>
                  <tr>
                    <td className="table-field">{PaymentMethod} :</td>
                    <td className="ms-3">
                      {data?.order_information.payment_method?.name}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            {data?.order_information.statement_number > 0 ? (
              <tr>
                <td colSpan={2}>
                  <table>
                    <tr>
                      <td className="table-field">{statementNumber} :</td>
                      <td className="ms-3">
                        {data?.order_information.statement_number}
                        {console.log(data.order_information.statement_number)}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            ) : null}
            {/* <tr>
              <td colSpan={2}>
                <table>
                  <tr>
                    <td className="table-field">{Discount} :</td>
                    <td className="ms-3">
                      {data?.order_information.discount_amount}{" "}
                      {country.currency}
                    </td>
                  </tr>
                </table>
              </td>
            </tr> */}
          </tbody>
          {/* company and user details start  */}
        </Table>
        {/* web invoice table end */}

        {/* phone invoice table start */}
        <div
          className={`printable-section-container__table__responsive__container d-none`}
        >
          <Table className="printable-section-container__table__responsive">
            <thead className="printable-section-container__table__header">
              <tr>
                <th colSpan={2}>{BillTo}</th>
              </tr>
            </thead>
            <tbody className="printable-section-container__table__body">
              <tr>
                <td colSpan={2}>
                  <table>
                    <tr>
                      <td className="table-field">{Name} :</td>
                      <td className="ms-3">{currentUser.name}</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <table>
                    <tr>
                      <td className="table-field">{DeliverTo} :</td>
                      <td className="ms-3">
                        {data?.order_information?.receiver_details?.address}
                        {console.log(data?.order_information.receiver_details)}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <table>
                    <tr>
                      <td className="table-field">{Phone} :</td>
                      <td className="ms-3">
                        {data?.order_information.receiver_details?.phone}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <table>
                    <tr>
                      <td className="table-field">{Email} :</td>
                      <td className="ms-3">
                        {data?.order_information.receiver_details?.email}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <table>
                    <tr>
                      <td className="table-field">{PaymentMethod} :</td>
                      <td className="ms-3">
                        {data?.order_information.payment_method?.name}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              {data?.order_information.statement_number > 0 ? (
              <tr>
                <td colSpan={2}>
                  <table>
                    <tr>
                      <td className="table-field">{statementNumber} :</td>
                      <td className="ms-3">
                        {data?.order_information.statement_number}
                        {console.log(data.order_information.statement_number)}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            ) : null}
              {/* <tr>
                <td colSpan={2}>
                  <table>
                    <tr>
                      <td className="table-field">{Discount} :</td>
                      <td className="ms-3">
                        {data?.order_information.discount_amount}{" "}
                        {country.currency}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr> */}
            </tbody>
          </Table>

          <Table className="printable-section-container__table__responsive">
            <thead className="printable-section-container__table__header">
              <tr>
                <th colSpan={2}>{BillFrom}</th>
              </tr>
            </thead>
            <tbody className="printable-section-container__table__body">
              <tr>
                <td>
                  <table>
                    <tr>
                      <td className="table-field">{Company} :</td>
                      <td className="ms-3">{settings.billing_company_name}</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tr>
                      <td className="table-field">{Phone} :</td>
                      <td className="ms-3">{settings.billing_company_phone}</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tr>
                      <td className="table-field">{Email} :</td>
                      <td className="ms-3">{settings.billing_company_email}</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        {/* phone invoice table start */}

        {/****** date and status section start *****/}
        <div className="invoice__sec2 mb-4 ps-2">
          <div className="gap-4">
            <div>
              {/* order create date start  */}
              <p className="mb-0 invoice__sec2__date">
                <span className="invoice__span1"><strong>{Date}</strong></span>
                :&nbsp;&nbsp;&nbsp;&nbsp;
                <span>
                  {moment(data?.order_information?.created_at).format(
                    "yy-MM-DD"
                  )}
                </span>
              </p>
              {/* order create date end  */}
              <p className="mb-0 mt-2 invoice__sec2__date">
                <span className="invoice__span1"><strong>{Time}</strong></span>
                :&nbsp;&nbsp;&nbsp;&nbsp;
                <span>
                  {moment(data?.order_information?.created_at).utcOffset(0).format(
                    "hh:mm:ss a"
                  )}
                </span>
              </p>
              {/* <p className="mb-0 invoice__sec2__date">
                                <span className="invoice__span1">{DeliveredOn}</span>
                                :&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>{moment(data?.order_information?.complete_date_time).format("yy-MM-DD")}</span>
                            </p> */}

              {/* {data?.order_information.complete_date_time && ( 
                            <p className="mb-0 invoice__sec2__deliverdOn">
                                <span className="invoice__span1">{DeliveredOn}</span>
                                :&nbsp;&nbsp;&nbsp;&nbsp;
                                {data?.order_information.complete_date_time}
                                 <span>+/- 2 {days}</span> 
                            </p> 
                           )} */}
            </div>
            {/* order status  start  */}

            <div className="align-self-start mt-2">
              <p className="mb-0 invoice__sec2__status">
                <span><strong>{Status} :</strong></span>
                <span
                  style={{
                    color: data.order_information?.statusColor,
                  }}
                  // className="d-flex align-items-center"
                  // style={{columnGap: "10px"}}
                >
                  <span className="m-0 p-0" style={{ display: "inline-block" }}>
                    {language.langCode === "ltr"
                      ? data.order_information?.statusText
                      : data.order_information?.statusText == "Pending"
                      ? "قيد الانتظار"
                      : data.order_information?.statusText == "Cancelled"
                      ? "ملغي"
                      : data.order_information?.statusText == "Accepted"
                      ? "تم القبول"
                      : data.order_information?.statusText == "On the Way"
                      ? "في الطريق"
                      : data.order_information?.statusText == "Arrived"
                      ? "تم التوصيل"
                      : data.order_information?.statusText == "Completed"
                      ? "مكتمل"
                      : null}
                  </span>
                  <i className=" fa fa-circle ps-3"></i>
                </span>
              </p>
            </div>
            {/* order status  end  */}
          </div>
        </div>
        {/********* date and status section end ******/}

        {/*********** Product Table Start  *********************/}
        <div className="invoice-table-parent">
          <Table bordered className="invoice-table">
            {/* header table start  */}
            <thead className="invoice-header">
              <tr>
                <th>{Pic}</th>
                <th>{ItemName}</th>
                <th>{Quntity}</th>
                <th>{UnitPrice}</th>
                <th>{Total}</th>
              </tr>
            </thead>
            {/* header table end  */}

            {/* Table body start  */}
            <tbody>
              {/* products list row start  */}
              {data?.items?.map(
                ({ product, qty, unit_price, total_price, option_values }) => {
                  return (
                    <tr>
                      <td>
                        <img
                          alt=""
                          src={
                            product.main_image
                              ? apiService.imageLink + product.main_image
                              : "/images/placeholder.png"
                          }
                        />
                      </td>
                      <td className="ItemNameTable">
                        {product.title}
                        <br />
                        <span className="invoice-option">
                          {option_values.join(",")}
                        </span>
                      </td>
                      <td>X {qty}</td>
                      <td>
                        <strong>
                          {unit_price} {country.currency}
                        </strong>
                      </td>
                      <td>
                        <strong>
                          {total_price} {country.currency}
                        </strong>
                      </td>
                    </tr>
                  );
                }
              )}
              {/* products list row end  */}

              <tr>
                <td colSpan="2" className="NoLeftBorder">
                  {" "}
                </td>
                <td colSpan="2" className="NoLeftBorder">
                  <div>
                    {/* items_amount start  */}
                    {data?.order_information.items_amount > 0 && (
                      <h4>{ItemsAmount}</h4>
                    )}
                    {/* items_amount end  */}

                    {/* discount_amount start  */}
                    {data?.order_information.discount_amount > 0 && (
                      <h4>{Discount} </h4>
                    )}
                    {/* <h4>{Discount} "Code :A25sal"</h4> */}
                    {/* discount_amount end  */}

                    {/* taxes_amount start  */}
                    {data?.order_information.taxes_amount > 0 && (
                      <h4>{taxesAmount}</h4>
                    )}
                    {/* taxes_amount end  */}

                    {/* feesAmount start  */}
                    {data?.order_information.fees_amount > 0 && (
                      <h4>{feesAmount}</h4>
                    )}
                    {/* feesAmount start  */}
                  </div>
                </td>
                <td colSpan="2">
                  {/* total items_amount start  */}
                  {data?.order_information.items_amount > 0 && (
                    <h2>
                      {data?.order_information.items_amount} {country.currency}
                    </h2>
                  )}
                  {/* total items_amount end  */}

                  {/* discount_amount start  */}
                  {data?.order_information.discount_amount > 0 && (
                    <h3>
                      {data?.order_information.discount_amount}{" "}
                      {country.currency}
                    </h3>
                  )}
                  {/* discount_amount end  */}

                  {/* taxes_amount start  */}
                  {data?.order_information.taxes_amount > 0 && (
                    <h2>
                      {data?.order_information.taxes_amount} {country.currency}
                    </h2>
                  )}
                  {/* taxes_amount end  */}

                  {/* fees_amount start  */}
                  {data?.order_information.fees_amount > 0 && (
                    <h2>
                      {data?.order_information.fees_amount} {country.currency}
                    </h2>
                  )}
                  {/* fees_amount end  */}
                </td>
              </tr>
              {/* total_amount start  */}
              <tr>
                <td className="NoBorder" colSpan="2"></td>
                <td colSpan="1" className="ColoredTD">
                  {" "}
                  {Total}{" "}
                </td>
                <td className="ColoredTD" colSpan="3">
                  <h3>
                    {data?.order_information.total_amount} {country.currency}
                  </h3>
                </td>
              </tr>
              {/* total_amount end  */}
            </tbody>
            {/* Table body end  */}
          </Table>
        </div>
      </div>
      {/*********** Product Table end  *********************/}
    </div>
  );
};
export default Invoices;
