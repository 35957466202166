import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../language/Global.json";
import { setLanguage } from "../../redux/actions-reducers/language";
import { setUser } from "../../redux/actions-reducers/user";
import { apiService } from "../../services/ApiService";
import { generalServices } from "../../services/GeneralService";
import { productService } from "../../services/ProductService";
import { displayAlert } from "../../utils/misc";

class ProductFullView extends Component {
    state = {
        isFav: this.props.data?.in_favorite,
        compare: this.props.data?.compare,
    };

    render() {
        const {
            data,
            reFetchData,
            currentUser,
            language,
            i18n: { outOfStuck, SoldOut },
        } = this.props;
        return (
            <div className={`product FullViewCard ${language === "rtl" && "product-arabic"}`}>
                {/* sold out label start  */}
                {data.option.qty == 0 && <span className={`${"product-label product-label-soldOut"}`}>{SoldOut}</span>}
                {/* sold out label end  */}

                {/* product label start  */}
                {data.label && data.option.qty > 0 && (
                    <span
                        className={`${"product-label product-fullVeiw-label"}`}
                        style={{
                            backgroundColor: data.label.background_color,
                            color: data.label.text_color,
                        }}
                    >
                        {data.label.label}
                    </span>
                )}
                {/* product label end  */}

                {/* fav icon start  */}
                <div className="product-fav" onClick={() => this.addRemoveFavorites(data?.id, reFetchData)}>
                    {currentUser.favoritesId.includes(data?.id) ? <img src="/images/heartColor.svg" alt="heart" /> : <img src="/images/heart.svg" alt="heart" />}
                </div>
                {/* fav icon end  */}

                <div className="media FullViewCard">
                    <div style={{ width: "100%" }}>
                        <Row className=" m-0">
                            <Col md={4} className="m-auto p-0">
                                {/* product img start  */}
                                <img onClick={() => (window.location.href = "/productDetails/" + data?.machine_name)} src={data?.main_image ? apiService.imageLink + data?.main_image : "/images/placeholder.png"} alt="k" className="product__img clickable" />
                                {/* product img end  */}
                            </Col>
                            <Col md={8} className=" p-0">
                                <div className="media-body">
                                    <a href={"/productDetails/" + data?.machine_name}>
                                        {/* product title start  */}
                                        <h5 className="mt-0">{data?.title}</h5>
                                        {/* product title end  */}

                                        {/* product description start  */}
                                        <p
                                            className="media-text"
                                            dangerouslySetInnerHTML={{
                                                __html: data?.subtitle,
                                            }}
                                        />
                                        {/* product description end  */}

                                        {/* product body start  */}
                                        <a className="product__body">
                                            <div className="product__price">
                                                {/* product price after discount start  */}
                                                <div className="product__price__new-price">
                                                    <span className="m-0">{data?.option.price_after_discount}</span>
                                                    <span>{this.props.country.currency}</span>
                                                </div>
                                                {/* product price after discount end  */}

                                                {/* product old price and discount percentage start  */}
                                                {data?.option.discount_percentage > 0 ? (
                                                    <div className="product__price__old-price">
                                                        <span>{(data?.option.price).toFixed(2)}</span>

                                                        <span>{data?.option.discount_percentage}% off</span>
                                                    </div>
                                                ) : (
                                                    <div className="product__price__old-price">
                                                        <div className="empty"></div>
                                                    </div>
                                                )}
                                                {/* product old price and discount percentage end  */}
                                            </div>
                                        </a>
                                        {/* product body end  */}
                                    </a>
                                    {/* product footer start  */}

                                    <div className="product__footer">
                                        <div>
                                            {/* add/remove from cart start  */}
                                            <div
                                                onClick={() => {
                                                    if (data?.option?.qty > 0) {
                                                        this.addToCart(data?.option.id, this.props.currentUser.cartIds?.includes(data?.option?.id) ? 0 : 1, reFetchData);
                                                    }
                                                }}
                                            >
                                                {data?.option?.qty > 0 ? (
                                                    <img src={`/images/${currentUser.cartIds?.includes(data?.option?.id) ? "bagC" : "bag"}.svg`} alt="ss" />
                                                ) : (
                                                    <img
                                                        src={`/images/qqqq.svg`}
                                                        alt="ss"
                                                        style={{
                                                            cursor: "no-drop",
                                                        }}
                                                        title={outOfStuck}
                                                    />
                                                )}
                                            </div>
                                            {/* add/remove from cart end  */}

                                            {/* add/remove compare start  */}
                                            {/* <div onClick={() => this.addRemoveCompares(data?.id, reFetchData)}>
                                                <img src={`/images/${this.props.currentUser.comparesId?.includes(data?.id) ? "newCompC.svg" : "newComp.svg"}`} alt="ss" />
                                            </div> */}
                                            {/* add/remove compare end  */}
                                        </div>
                                        {/* product rate start  */}
                                        <div>
                                            <i className="fa fa-star"></i>
                                            <span>{data?.rate}</span>
                                        </div>
                                        {/* product rate end  */}
                                    </div>
                                    {/* product footer end  */}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
    newPrice = () => {
        const { data } = this.props;

        let discountAmount = (data?.price * data?.campaign.discount_percentage) / 100;

        let priceAfterDiscount = 0;

        if (discountAmount > data?.campaign.fixed_amount) {
            priceAfterDiscount = data?.price - data?.campaign.fixed_amount;

            return priceAfterDiscount;
        } else {
            priceAfterDiscount = data?.price - discountAmount;
            return priceAfterDiscount;
        }
    };
    getIconData = async () => {
        const { data, success } = await generalServices.getIconData();
        if (!success) return;

        this.props.setUser({
            cart: data.cart_counter,
            fav: data.fav_counter,
            compare: data.compare_counter,
        });
    };
    getFavoritesIDs = async () => {
        const { data, success } = await generalServices.getFavoritesIDs();
        if (!success) return;

        this.props.setUser({
            favoritesId: data,
        });
    };
    getComparesIDs = async () => {
        const { data, success } = await generalServices.getComparesIDs();
        if (!success) return;
        this.props.setUser({
            comparesId: data,
        });
    };
    getCartIDs = async () => {
        const { data, success } = await generalServices.getCartIDs();
        if (!success) return;
        this.props.setUser({
            cartIds: data,
        });
    };
    addRemoveFavorites = async (id, reFetchData) => {
        // const { isFav } = this.state;
        const { success } = await productService.addOrRemoveFavorite(id);
        if (!success) return;
        // this.setState({ isFav: !this.state.isFav });
        this.getFavoritesIDs();
        reFetchData();
        this.getIconData();
    };
    addRemoveCompares = async (id, reFetchData) => {
        const { compare } = this.state;
        const { success } = await productService.addRemoveCompares(id);
        const { success: successText, CompareProductDeleted, CompareProductAdded } = this.props.i18n;
        if (!success) return;
        this.setState({ compare: !this.state.compare });
        displayAlert(successText, compare ? CompareProductDeleted : CompareProductAdded, "success", "noButtons", 2000);
        this.getComparesIDs();

        reFetchData();
        this.getIconData();
    };
    addToCart = async (id, qty, reFetchData) => {
        const { success } = await productService.addToCart(id, qty);
        const { success: successText, DeletedFromCart, AddedToCart } = this.props.i18n;
        if (!success) return;
        displayAlert(successText, qty === 0 ? DeletedFromCart : AddedToCart, "success", "noButtons", 2000);
        this.getCartIDs();

        reFetchData();
        this.getIconData();
    };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    country,
    currentUser,
});
export default connect(mapStateToProps, { setLanguage, setUser })(ProductFullView);
