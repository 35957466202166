import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-elastic-carousel";
import Product from "./Product";

import { connect } from "react-redux";
import Global from "../../language/Global.json";
import { setLanguage } from "../../redux/actions-reducers/language";
import Skeleton from "react-loading-skeleton";
import { tabletScreen } from "../../utils/misc";
class CategoriesBlock extends Component {
  state = {
    breakPoints: [
      // { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
      // { width: 992, itemsToShow: 5 },
      { width: 1, itemsToShow: 2, showArrows: false, pagination: true },
      { width: 425, itemsToShow: 2, showArrows: false, pagination: true },
      { width: 576, itemsToShow: 3 },
      { width: 768, itemsToShow: 4 },
      { width: 992, itemsToShow: 5 },
      { width: 1200, itemsToShow: 6 },
    ],
  };
  render() {
    const {
      language,
      data,
      reFetchData,
      title,
      isRelated,
      machine_name,
      type,
      category,
      i18n: { VIEWALL },
    } = this.props;
    return (
      <Container>
        <div
          className={`categories-block  ${
            language === "rtl" && "categories-block-arabic"
          }`}
        >
          <div className="categories-block__header">
            {/* category title start  */}
            <h3>{title}</h3>
            {/* category title end  */}

            <div className="hr" />
            {/* view all button start  */}
            {!isRelated && (
              <a
                href={
                  machine_name
                    ? `/${
                        category ? "ProductsCategory" : "GeneralProduct"
                      }/${machine_name}/1`
                    : `/GeneralProduct/${
                        type === 1
                          ? "TrendingProducts"
                          : type === 2
                          ? "RecommendedProducts"
                          : "DailyDeals"
                      }`
                }
                className="view-all"
              >
                <span>{VIEWALL}</span>
                <img src="/images/arrow-small-right.svg" alt="l" />
              </a>
            )}
            {/* view all button end  */}
          </div>

          {/* category products carousel start  */}
          {data?.length < 1 ? (
            <Row className="no-margin p-1">
              <Col>
                <Skeleton height={tabletScreen ? 100 : 200} />
              </Col>
              <Col>
                <Skeleton height={tabletScreen ? 100 : 200} />
              </Col>
              <Col>
                <Skeleton height={tabletScreen ? 100 : 200} />
              </Col>
              <Col>
                <Skeleton height={tabletScreen ? 100 : 200} />
              </Col>
            </Row>
          ) : data?.length < 4 ? (
            <Row className="no-margin p-1">
              {data?.map((item, index) => {
                return (
                  <Col key={index} lg={3} md={4} sm={6} xs={12}>
                    <Product data={item} reFetchData={() => reFetchData()} />{" "}
                  </Col>
                );
              })}
            </Row>
          ) : (
            <Carousel
              itemsToShow={6}
              pagination={false}
              breakPoints={this.state.breakPoints}
              isRTL={language === "rtl" && true}
              itemPadding={[0, 5]}
              className="my-slider"
            >
              {data?.map((item, index) => {
                return (
                  <div key={item.id}>
                    <Product
                      key={item.id}
                      data={item}
                      reFetchData={() => reFetchData()}
                    />
                  </div>
                );
              })}
            </Carousel>
          )}
          {/* category products carousel end  */}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
});
export default connect(mapStateToProps, { setLanguage })(CategoriesBlock);
