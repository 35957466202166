import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { cartService } from "../../../services/CartService";
import Skeleton from "react-loading-skeleton";
import Product from "../../blocks/Product";
import { homeService } from "../../../services/homeService";
// import GeneralProductBlock from "./GeneralProductBlock";

class GeneralProduct extends Component {
    state = {
        fullView: false,
        cart: [],
        favorites: [],
        payment_methods: [],
        itemsPriceBeforeDiscount: 0,
        itemsPriceAfterDiscount: 0,
        adsData: [
            {
                image: "/images/Group55.png",
                link: "/",
                lg: 12,
                md: 12,
                sm: 12,
                xs: 12,
            },
        ],
        loaderPage: true,
    };

    componentDidMount() {
        Promise.all([this.getHomeTDRDeals(this.props.match.params.machineName === "RecommendedForYou" ? 1 : this.props.match.params.machineName == "TrendingProducts" ? 2 : 3)]);
    }

    getHomeTDRDeals = async (type) => {
        const { data, success } = await homeService.getHomeTDRDeals(type);

        if (!success) return;
        this.setState({
            favorites: data,
            loaderPage: false,
        });
    };

    render() {
        const { favorites, loaderPage } = this.state;
        const {
            reFetchData,
            i18n: { Items, favEmpty },
        } = this.props;

        return (
            <div className="Cart">
                {/** Title and items count start */}
                <div className="CartTitle">
                    <Container>
                        <Row>
                            <Col>
                                <h2>
                                    {this.props.match.params.machineName}
                                    <h4>
                                        {favorites.length} {Items}
                                    </h4>
                                </h2>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/** Title and items count end */}

                {/*  products  Start */}
                <Container>
                    <Row>
                        {loaderPage ? (
                            <>
                                <Col md={3}>
                                    <Skeleton height={200} className="mb-3" />
                                </Col>
                                <Col md={3}>
                                    <Skeleton height={200} className="mb-3" />
                                </Col>
                                <Col md={3}>
                                    <Skeleton height={200} className="mb-3" />
                                </Col>
                                <Col md={3}>
                                    <Skeleton height={200} className="mb-3" />
                                </Col>
                                <Col md={3}>
                                    <Skeleton height={200} className="mb-3" />
                                </Col>
                                <Col md={3}>
                                    <Skeleton height={200} className="mb-3" />
                                </Col>
                                <Col md={3}>
                                    <Skeleton height={200} className="mb-3" />
                                </Col>
                                <Col md={3}>
                                    <Skeleton height={200} className="mb-3" />
                                </Col>
                            </>
                        ) : favorites.length === 0 ? (
                            <Col md={12}>
                                <div className="empty-products">
                                    <img src="/images/Artboard 8.svg" alt="k" />
                                    <p> {favEmpty} </p>{" "}
                                </div>
                            </Col>
                        ) : (
                            favorites.map((item, index) => {
                                return (
                                    <Col lg={3} md={4} xs={6}>
                                        <Product data={item} reFetchData={() => this.getHomeTDRDeals(this.props.match.params.machineName === "RecommendedProducts" ? 1 : this.props.match.params.machineName == "TrendingProducts" ? 2 : 3)} />
                                    </Col>
                                );
                            })
                        )}
                    </Row>
                </Container>
                {/*  products  end */}
            </div>
        );
    }
}

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
});

export default connect(mapStateToProps, { setLanguage })(GeneralProduct);
