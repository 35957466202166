import React, { Component } from "react";
import { Container, Row, Col, Pagination } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { userService } from "../../../services/UserService";
import { withRouter } from "react-router-dom";
import PaginatedItems from "../../Pages/Search/PaginationBloack";
import moment from "moment";

class TrackYourOrder extends Component {
  state = {
    step: 1,
    qty: 1,
    selectedLocation: {},
    selectedPayment: -1,
    books: [],
    locations: [],
    AddAddress: false,
    pageLoader: true,
    countries: [],
    fields: {
      address: "",
      name: "",
      phone: "",
      country: "",
    },
    orders: [],
    currentPage: 1,
    meta: {},
  };

  async componentDidMount() {
    await this.getUserOrders();
  }
  nextPage = (index) => {
    this.setState(
      {
        currentPage: index,
        pageLoader: true,
      },
      () => this.getUserOrders(index)
    );
  };
  getUserOrders = async (page) => {
    const {
      fields: { from = "", to = "", key = "", status = "" },
    } = this.state;

    const { data, success, meta } = await userService.getUserOrders(
      this.props.match.params.page,
      {
        from,
        to,
        key,
        status,
      }
    );
    if (!success) return;
    this.setState({
      orders: data,
      pageLoader: false,
      meta: meta,
    });
  };
  render() {
    const {
      language,
      i18n: {
        OrderNo,
        YourOrder,
        OrderConfirmed,
        OrderPreparing,
        YouGetOrder,
        OnTheWay,
        Items,
        Total,
        EstimatedDeliveryDate,
        TrackOrder,
        trackerEmpty,
        Canceled,
        Arraived,
        Accepted,
        Complete,
        Pending,
      },
    } = this.props;
    const { step, pageLoader, meta, orders } = this.state;
    // console.log(meta, "Orders");
    return (
      <Container>
        <div
          className={`track-order  ${
            language === "rtl" && " track-order-arabic"
          }`}
        >
          {/* Title Start */}
          <div className="account-page__title">
            <h3>{TrackOrder}</h3>
            <h4 hidden>a</h4>
          </div>
          {/* Title End */}

          {/* Track block Start */}
          {pageLoader ? (
            <>
              <Skeleton height={200} className="mt-3" />
              <Skeleton height={200} className="mt-3" />
              <Skeleton height={200} className="mt-3" />
              <Skeleton height={200} className="mt-3" />
              <Skeleton height={200} className="mt-3" />
            </>
          ) : this.state.orders.length > 0 ? (
            this.state.orders.map((order, index) => {
              return (
                <div className={`track mt-3 `} key={index}>
                  {/* track header contain order number and date Start */}
                  <div className="header">
                    <a
                      href={`/invoice/${order?.order_reference}`}
                      className="number"
                    >
                      {OrderNo} #{order.id}
                    </a>
                    <h6 className="date">
                      {moment(order.created_at).format("yy-MM-DD")}
                    </h6>
                  </div>
                  {/* track header contain order number and date End */}

                  {/*Track  Steps Start */}
                  <div className="steps">
                    <ul>
                      {/* step 1 label  start  */}
                      <li>
                        <div
                          className={
                            order.status >= 1 ? "step stepActive" : "step"
                          }
                        >
                          <span
                            className={
                              order.status >= 1 ? " bg-stepActive" : ""
                            }
                          >
                            {order.status > 1 ? (
                              <i className="fa fa-check"></i>
                            ) : (
                              1
                            )}
                          </span>
                          <p>{Pending}</p>
                        </div>
                      </li>
                      {/* step 1 label  end  */}

                      <li
                        className={
                          order.status >= 2
                            ? "step-hr bg-stepActive none"
                            : "step-hr none"
                        }
                      ></li>

                      {/* step 2 label  start  */}
                      <li>
                        <div
                          className={
                            order.status >= 2 ? "step stepActive" : "step"
                          }
                        >
                          <span
                            className={
                              order.status >= 2 ? " bg-stepActive" : ""
                            }
                          >
                            {order.status > 2 ? <i className="fa fa-check"></i> : 2}
                          </span>
                          <p>{Accepted}</p>
                        </div>
                      </li>
                      {/* step 2 label  end  */}

                      <li
                        className={
                          order.status >= 2
                            ? "step-hr bg-stepActive none"
                            : "step-hr none"
                        }
                      ></li>

                      {/* step 3 label  start  */}
                      <li>
                        <div
                          className={
                            order.status >= 3 ? "step stepActive" : "step"
                          }
                        >
                          <span
                            className={
                              order.status >= 3 ? " bg-stepActive" : ""
                            }
                          >
                            {order.status > 3 ? (
                              <i className="fa fa-check"></i>
                            ) : (
                              3
                            )}
                          </span>
                          <p>{OnTheWay}</p>
                        </div>
                      </li>
                      {/* step 3 label  end  */}

                      <li
                        className={
                          order.status >= 3
                            ? "step-hr bg-stepActive none"
                            : "step-hr none"
                        }
                      ></li>

                      {/* step 4 label  start  */}
                      <li>
                        <div
                          className={
                            order.status >= 4 ? "step stepActive" : "step"
                          }
                        >
                          <span
                            className={
                              order.status >= 4 ? " bg-stepActive" : ""
                            }
                          >
                            {order.status > 4 ? (
                              <i className="fa fa-check"></i>
                            ) : (
                              4
                            )}
                          </span>
                          <p>{Arraived}</p>
                        </div>
                      </li>
                      <li
                        className={
                          order.status >= 4
                            ? "step-hr bg-stepActive none"
                            : "step-hr none"
                        }
                      ></li>
                      {/* step 4 label  end  */}

                      {/* step 5 label  start  */}
                      <li>
                        <div
                          className={
                            order.status >= 5 ? "step stepActive" : "step"
                          }
                        >
                          <span
                            className={
                              order.status >= 5 ? " bg-stepActive" : ""
                            }
                          >
                            {order.status >= 5 ? (
                              <i className="fa fa-check"></i>
                            ) : (
                              5
                            )}
                          </span>
                          <p>{Complete}</p>
                        </div>
                      </li>
                      {/* step 5 label  end  */}
                    </ul>
                  </div>
                  {/* Track Steps End */}

                  {/* Track info Start */}
                  <div
                    className="info"
                    style={order.status == -2 ? { opacity: "0.5" } : {}}
                  >
                    {/* order items count start  */}
                    <div className="items">
                      <span>{order.items_count}</span>
                      <span>{Items}</span>
                    </div>
                    {/* order items count end  */}

                    {/* total price start  */}
                    <div className="total">
                      <span>{Total} :</span>
                      <span>
                        {order.total_amount} {this.props.country.currency}
                      </span>
                    </div>
                    {/* total price end  */}

                    {/* Canceled label start  */}
                    {order.status == -2 && (
                      <div className="date">
                        <span>{Canceled}</span>
                        {/* <span>{order.complete_date_time}</span> */}
                      </div>
                    )}
                    {/* Canceled label start  */}
                  </div>
                  {/* Track info End */}
                </div>
              );
            })
          ) : (
            // if no order to track
            <Col md={12}>
              <div className="empty-products">
                <img src="/images/Artboard 6.svg" alt="k" />
                <p>{trackerEmpty}</p>
              </div>
            </Col>
          )}
        </div>

        {/** Pagination Start */}
        {meta.last_page > 1 ? (
          <Row>
            <Col md={12}>
              <div className="Pagination">
                <PaginatedItems
                  items={[...Array(meta?.total).keys()]}
                  itemsPerPage={meta?.per_page}
                  changePage={(page) => this.changePage(page)}
                />
              </div>
            </Col>
          </Row>
        ) : null}
        {/** Pagination End */}
      </Container>
    );
  }

  toTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  changePage(page) {
    this.toTop();
    this.props.history.push(`/trackYourOrder/${page + 1}`);
    this.setState(
      {
        pageLoader: true,
      },
      () => {
        this.getUserOrders();
      }
    );
  }
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
  country,
});
export default connect(mapStateToProps, { setLanguage })(
  withRouter(TrackYourOrder)
);
