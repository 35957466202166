import moment from "moment";
import React, { useState } from "react";
import { FormControl, InputGroup, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { apiService } from "../../services/ApiService";
import { productService } from "../../services/ProductService";
import Global from "../../language/Global.json";
import "moment/min/locales";
import { displayAlert } from "../../utils/misc";

// The Style is in ProductDetails.scss

const Reviews = (props) => {
    const [selectedRate, SetSelectedRate] = useState(5);
    const { meta, currentPage } = props;
    // const [ProductReviewData, setProductReviewData] = useState({});
    const [comment, setComment] = useState("");
    const [SpinnerShow, setSpinnerShow] = useState(false);
    const { ordersCount, id } = props;
    const language = useSelector(({ language }) => language);
    const i18n = language.langCode === "ltr" ? Global.en : Global.ar;
    const { successOrders, TotalRate, leaveComment, Loading, LoadMoreComments, error, PleaseEnterRate } = i18n;

    const addProductReview = async () => {
        const { success } = await productService.addProductReview({
            product_id: id,
            rate: selectedRate,
            comment,
        });
        if (!success) return;
        setComment("");
        SetSelectedRate(1);
        props.refetchData();
    };

    return (
        <div className="reviews-section">
            <div className="head mb-3">
                {/* Star Rate Table Start */}
                <div className="rate-table">
                    {props.reviewRates
                        .slice()
                        ?.reverse()
                        .map(({ rate, rate_count }) => {
                            return props.rateBar(rate, rate_count);
                        })}
                </div>
                {/* Star Rate Table End */}
                {/* Total Rate Start */}
                <div className="rate-total">
                    <span className="total-rate-title">{TotalRate}</span>
                    <div className="star-average-people">
                        <i className="fa fa-star fa-lg"></i>

                        <div>{calculation(props) ? calculation(props).toFixed(1) : "0"}</div>
                    </div>
                    <span className="order-case">
                        {ordersCount} {successOrders}
                    </span>
                </div>
                {/* Total Rate End */}
            </div>
            {/*Customers Comments Section Start */}
            {props.data?.length > 0 && (
                <div className="reviews-section__comment-container">
                    {props.data?.map(({ user, comment, rate, created_at }) => {
                        return (
                            <div className="comment-content">
                                <div className="comment-head">
                                    <div className="user">
                                        <img className="user-image" src={`${user?.image ? apiService.imageLink + user?.image : "/images/Group 1496.png"}`} alt="profile" />
                                        <div className="user-name-rate">
                                            {/* user name start  */}
                                            <span className="user-name">{user?.name}</span>
                                            {/* user name end  */}

                                            {/* user rated  start  */}
                                            <span className="user-rate">{props.rateBar(rate)}</span>
                                            {/* user rated  start  */}
                                        </div>
                                    </div>
                                    {/* Comment Date Start */}
                                    <div className="date">
                                        {/* {language.langCode == "rtl"
                                        ? moment(
                                              created_at ?? new Date(),
                                              "YYYYMMDD"
                                          )
                                              .locale("ar")
                                              .startOf("min")
                                              .fromNow()
                                        : moment(
                                              created_at ?? new Date(),
                                              "YYYYMMDD"
                                          )
                                              .locale("us")
                                              .startOf("min")
                                              .fromNow()} */}
                                        {moment(created_at).format("l")}
                                    </div>
                                    {/* Comment Date End */}
                                </div>

                                {/* user comment  start  */}
                                <p className="comment-text">{comment}</p>
                                {/* user comment  end  */}
                            </div>
                        );
                    })}

                    {/* load more comment loader start  */}
                    {meta.total !== currentPage + 1 ? (
                        <div className="reviews-section__comment-container__more-comment-loader">
                            {props.isLoading ? (
                                <div>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">{Loading}...</span>
                                    </Spinner>
                                </div>
                            ) : (
                                <button
                                    className="p-3"
                                    onClick={() => {
                                        props.loadMoreComments();
                                    }}
                                    style={meta.current_page === meta.last_page ? { display: "none" } : null}
                                >
                                    <i className="fa fa-arrow-down me-2"></i>
                                    {LoadMoreComments}
                                </button>
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                    {/* load more comment loader end  */}
                </div>
            )}
            {/*Customers Comments Section End */}

            {/*Your Comment Section Start */}
            {apiService.accessToken !== 0 && (
                <div className="send-review">
                    <InputGroup className="mb-3">
                        <InputGroup.Text>
                            {/* select rate start  */}
                            <div className="bar-row">
                                {/* star-bar start */}
                                <ul className="bar">
                                    <li>
                                        <i
                                            onClick={() => {
                                                SetSelectedRate(1);
                                            }}
                                            className={selectedRate > 0 ? "fa fa-star" : "fa fa-star-o"}
                                        ></i>
                                    </li>
                                    <li>
                                        <i
                                            onClick={() => {
                                                SetSelectedRate(2);
                                            }}
                                            className={selectedRate > 1 ? "fa fa-star" : "fa fa-star-o"}
                                        ></i>
                                    </li>
                                    <li>
                                        <i
                                            onClick={() => {
                                                SetSelectedRate(3);
                                            }}
                                            className={selectedRate > 2 ? "fa fa-star" : "fa fa-star-o"}
                                        ></i>
                                    </li>
                                    <li>
                                        <i
                                            onClick={() => {
                                                SetSelectedRate(4);
                                            }}
                                            className={selectedRate > 3 ? "fa fa-star" : "fa fa-star-o"}
                                        ></i>
                                    </li>
                                    <li>
                                        <i
                                            onClick={() => {
                                                SetSelectedRate(5);
                                            }}
                                            className={selectedRate > 4 ? "fa fa-star" : "fa fa-star-o"}
                                        ></i>
                                    </li>
                                </ul>
                                {/* star-bar end */}
                                <div className="evaluator-number">
                                    <span>{selectedRate}.0</span>
                                </div>
                            </div>
                            {/* select rate end  */}
                        </InputGroup.Text>

                        {/* <span className="vertical-bar"></span> */}

                        {/* comment input start  */}
                        <FormControl placeholder={leaveComment} aria-label="Amount (to the nearest dollar)" onChange={({ target }) => setComment(target.value)} value={comment} />
                        {/* comment input end  */}

                        {/* submit comment button start  */}
                        <InputGroup.Text className="send-btn" onClick={() => (selectedRate ? addProductReview() : displayAlert(error, PleaseEnterRate, "error", "", 2000))}>
                            <img src="/images/Path 3879.svg" alt="send" />
                        </InputGroup.Text>
                        {/* submit comment button end  */}
                    </InputGroup>
                </div>
            )}

            {/*Your Comment Section End */}
        </div>
    );
};

// calculate average rate start
const calculation = (props) => {
    const totalRateAndTotalCountRate = props.reviewRates.reduce((acc, currentValue) => {
        return acc + currentValue.rate_count * currentValue.rate;
    }, 0);

    const totalCountRate = props.reviewRates.reduce((acc, currentValue) => {
        return acc + currentValue.rate_count;
    }, 0);

    const averageRate = totalRateAndTotalCountRate / totalCountRate;

    return averageRate;
};
// calculate average rate end

export default withRouter(Reviews);
