import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Global from "../../language/Global.json";
import { apiService } from "../../services/ApiService";
class AccountPageList extends Component {

  render() {
    const pathname = window.location.pathname.toLowerCase();
    const {
      i18n: { Profile: profileText, MyOrders, Addresses, TrackYourOrder },
    } = this.props;

    return (
      <div className="menu">
        <ul>
          {apiService.accessToken !== 0 && (
            <li
              style={{
                backgroundColor: `${
                  pathname === "/profile" ? "white" : "inherit"
                }`,
              }}
            >
              <Link to="/profile">
                <img src="/images/user.svg" alt="l" />
                <h6 href="/profile">{profileText}</h6>
              </Link>
            </li>
          )}
          <li
            style={{
              backgroundColor: `${
                pathname.slice(0, 7) === "/orders"
                  ? "white"
                  : pathname === "/invoice"
                  ? "white"
                  : "inherit"
              }`,
            }}
          >
            <Link to="/orders/1">
              <img src="/images/sent.svg" alt="l" />
              <h6>{MyOrders}</h6>
            </Link>
          </li>
          {apiService.accessToken !== 0 && (
            <li
              style={{
                backgroundColor: `${
                  pathname === "/addresses" ? "white" : "inherit"
                }`,
              }}
            >
              <Link to="/addresses">
                <img src="/images/map-book.svg" alt="l" />
                <h6>{Addresses}</h6>
              </Link>
            </li>
          )}

          <li
            style={{
              backgroundColor: `${
                pathname.slice(0, 15) === "/trackyourorder"
                  ? "white"
                  : "inherit"
              }`,
            }}
          >
            <Link to="/trackYourOrder/1">
              <img src="/images/delivery-truck.svg" alt="l" />
              <h6>{TrackYourOrder}</h6>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
});
export default connect(mapStateToProps)(AccountPageList);
