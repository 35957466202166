import React, { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Global from "../../../language/Global.json";
import { statusTypesColor } from "../../../utils/misc";
import { Button } from "react-bootstrap";
import { productService } from "../../../services/ProductService";
import { apiService } from "../../../services/ApiService";
import { userService } from "../../../services/UserService";
function OrderCard({ data }) {
  // const currentUser = useSelector(({ currentUser }) => currentUser);
  const language = useSelector(({ language }) => language);
  const country = useSelector(({ country }) => country);

  const i18n = language.langCode === "ltr" ? Global.en : Global.ar;
  const { OrderRef, Status, Total, DeliveredAt, DeliverTo, Items, ReOrder, Cancel } =
    i18n;

  async function submitCancelStatus() {
    const { success } = await userService.submitCancelStatus({
      order_id: data.id,
    });
    if (!success) {
      window.location.href = "/";
    }
    window.location.reload();
  }

  function handleClick() {
    if (window.confirm("Are you sure you want to Cancel?")) {
      submitCancelStatus();
    }
  }

  return (
    <div className="order">
      {/* Order first section start */}
      <div className="first">
        {/* order id start  */}
        <a
          href={`/invoice/${data?.order_reference}`}
          className="order__first__number"
        >
          {OrderRef} : {data.order_reference}
        </a>
        {/* order id end  */}

        {/* create date start  */}
        <h6 className="order__first__date">
          {moment(data?.created_at).format("yy-MM-DD")}
        </h6>
        {/* create date end  */}
      </div>
      {/* Order first section End */}

      {/* Order second section start */}
      <div className="second">
        <div className="second__total-item">
          {" "}
          {/* items count start  */}
          <div className="items">
            <span>{data?.items_count}</span> <span>{Items}</span>
          </div>
          {/* items count end  */}
          {/* total amount start  */}
          <div>
            {Total} : {country.currency} {data?.total_amount}
          </div>
          {/* total amount end  */}
        </div>
        {/* complete_date start  */}
        {data?.complete_date_time && (
          <div className="second__date">
            {DeliveredAt} :{data?.complete_date_time}
          </div>
        )}
        {/* complete_date end  */}

        {/* delivery address start  */}
        <div className="second__deliver-location">
          <span> {DeliverTo} : </span>
          <span>{data?.receiver_details?.address}</span>
        </div>
        {/* delivery address end  */}
      </div>
      {/* Order second section End */}

      {/* Order third section start */}
      <div className="third">
        {/* order status start  */}
        <div className="order__third__status">
          <span> {Status} </span>
          <span style={{ color: data.statusColor }}>
            {language.langCode === "ltr"
              ? data?.statusText
              : data?.statusText == "Pending"
              ? "قيد الانتظار"
              : data?.statusText == "Cancelled"
              ? "ملغي"
              : data?.statusText == "Accepted"
              ? "تم القبول"
              : data?.statusText == "On the Way"
              ? "في الطريق"
              : data?.statusText == "Arrived"
              ? "تم التوصيل"
              : data?.statusText == "Completed"
              ? "مكتمل"
              : null}
            {/* {console.log(data?.statusText)} */}
            <i className="fa fa-circle"></i>
          </span>
          {/* order status end  */}
        </div>

        <div className="cancelAndPrint">
          {/* Button Cancel */}
          {data?.statusText == "Pending" && (
            <Button className="button__Cancel" onClick={handleClick}>
              {Cancel}
            </Button>
          )}
          {/* order invoice button start  */}
          <Link
            className="order__third__invoice-reOrder"
            to={`/invoice/${data?.order_reference}`}
          >
            <img
              className="showInvoice-img"
              title="invoice"
              src="/images/invoice.svg"
              alt="search"
            />
          </Link>
          {/* order invoice button end  */}
        </div>

        {/* reOrder button start  */}
        {data?.can_reorder && (
          <button className="submit-button2">
            <i className="fa fa-undo"></i>
            {ReOrder}
          </button>
        )}
        {/* reOrder button end  */}
      </div>
      {/* Order third section End */}
    </div>
  );
}
export default OrderCard;
