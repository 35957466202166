import React, { Component } from "react";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setUser } from "../../../redux/actions-reducers/user";
import { Row, Col, Form } from "react-bootstrap";
import { productService } from "../../../services/ProductService";
import { apiService } from "../../../services/ApiService";
import { generalServices } from "../../../services/GeneralService";
import { Link } from "react-router-dom";

class CartProductBlock extends Component {
  render() {
    const {
      language,
      data,
      i18n: { Remove, moveToWishList },
      reFetchData,
      currentUser,
    } = this.props;

    return (
      <div className={`product  ${language === "rtl" && "product-arabic"}`}>
        <Row className="CartRow">
          <Col lg={3} xl={3} md={3} xs={3}>
            {/* product img start  */}
            {/* <a href={`/productDetails/ + ${data?.product.machine_name}`}>
                            <img src={apiService.imageLink + data.product?.main_image} alt="k" className="product__img" />
                        </a> */}

            <a href={`/productDetails/ + ${data?.product.machine_name}`}>
              {data.product_option.images.length > 0 ? (
                <img
                  src={
                    apiService.imageLink +
                    data.product_option?.images[0]?.image_thumb
                  }
                  alt="k"
                  className="product__img"
                />
              ) : (
                <img
                  src={apiService.imageLink + data.product?.main_image}
                  alt="k"
                  className="product__img"
                />
              )}
            </a>
            {/* product img end  */}
          </Col>

          <Col lg={6} xl={6} md={6} xs={9}>
            <div className="media-body">
              {/* product title start  */}
              <a href={`/productDetails/ + ${data?.product.machine_name}`}>
                <h5 className="mt-0 product__title">{data.product?.title}</h5>
              </a>
              {/* product title end  */}

              {/* product options start  */}
              <h6> {data.product_option?.values_names.join(",")} </h6>
              {/* product options end  */}

              <br />
              <div className="CartAction flex">
                {/* move to wishlist button start  */}
                {currentUser.favoritesId.includes(data?.product?.id) ? null : (
                  <div
                    onClick={() =>
                      this.addRemoveFavorites(data?.product?.id, reFetchData)
                    }
                  >
                    {/* <i className={currentUser.favoritesId.includes(data?.id) ? "fa fa-heart" : "fa fa-heart-o"} /> */}
                    <img
                      src={`/images/${
                        currentUser.favoritesId.includes(data?.product?.id)
                          ? "heartColor"
                          : "heart"
                      }.svg`}
                      alt="ss"
                    />
                    &nbsp;
                    {moveToWishList}
                  </div>
                )}
                {/* move to wishlist button end  */}

                {/* remove from cart button start  */}
                <div
                  onClick={() => {
                    this.addToCart(data?.product_option?.id, 0, reFetchData);
                  }}
                >
                  <img alt="Delete" src="/images/fi-rr-trash.svg" />
                  &nbsp; {Remove}
                </div>
                {/* remove from cart button end  */}
              </div>
            </div>
          </Col>
          {/* product footer start  */}
          <Col lg={3} xl={3} md={3} xs={12}>
            <div className="cart-product-footer">
              {/* product price start  */}
              <div className="product__price">
                <div className="product__price__new-price">
                  <span>
                    <sup>{this.props.country.currency}</sup>{" "}
                    {data?.product_option.price_after_discount}
                  </span>
                </div>
              </div>
              {/* product price end  */}

              {/* quantity product select start  */}
              <div
                className={`product__quantity  ${
                  language === "rtl" && "product__quantity__ar"
                }`}
              >
                <Form.Group controlId="formGridState">
                  <Form.Select
                    value={+data?.qty}
                    onChange={({ target: { value } }) => {
                      this.addToCart(
                        data?.product_option?.id,
                        +value,
                        reFetchData
                      );
                    }}
                  >
                    {Array.from(Array(data?.product_option?.qty).keys())?.map(
                      (_, index) => {
                        return <option value={index + 1}>{index + 1}</option>;
                      }
                    )}
                  </Form.Select>
                </Form.Group>
              </div>
              {/* quantity product select end  */}
            </div>
          </Col>
          {/* product footer end  */}
        </Row>
      </div>
    );
  }

  // get Icon Data start
  getIconData = async () => {
    const { data, success } = await generalServices.getIconData();
    if (!success) return;

    this.props.setUser({
      cart: data.cart_counter,
      fav: data.fav_counter,
      compare: data.compare_counter,
    });
  };
  // get Icon Data end

  // get Favorites IDs start
  getFavoritesIDs = async () => {
    const { data, success } = await generalServices.getFavoritesIDs();
    if (!success) return;

    this.props.setUser({
      favoritesId: data,
    });
  };
  // get Favorites IDs end

  // get Cart IDs start
  getCartIDs = async () => {
    const { data, success } = await generalServices.getCartIDs();
    if (!success) return;
    this.props.setUser({
      cartIds: data,
    });
  };
  // get Cart IDs end

  // add Remove Favorites function start
  addRemoveFavorites = async (id, reFetchData) => {
    const { success } = await productService.addOrRemoveFavorite(id);
    if (!success) return;
    this.addToCart(this.props.data?.product_option?.id, 0, reFetchData);
  };
  // add Remove Favorites function end

  // add To Cart function start
  addToCart = async (id, qty, reFetchData) => {
    const { success } = await productService.addToCart(id, qty);
    if (!success) return;

    await reFetchData();
    this.getFavoritesIDs();
    this.getCartIDs();

    this.getIconData();
  };
  // add To Cart function end
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
  country,
});
export default connect(mapStateToProps, { setUser })(CartProductBlock);
