import React, { Component } from "react";
import Select from "react-select";
import { ErrorMessage } from "./ErrorMessage";
import Label from "./Label";
import Global from "../../../src/language/Global.json";
import { connect } from "react-redux";
import { setLanguage } from "../../redux/actions-reducers/language";
class SelectInput extends Component {
    render() {
        const {
            label,
            tooltip,
            data = [],
            validate,
            name,
            value,
            isRequired,
            isMulti,
            onAddNew,
            i18n: { selecteText },
        } = this.props;

        return (
            <div className="selectBlock">
                {/* label start  */}
                <Label label={label} tooltip={tooltip} isRequired={isRequired} onAddNew={onAddNew} />
                {/* label end  */}

                {/* select input start  */}
                <Select className="basic-single mainSelect" classNamePrefix="select" options={data} name={name} value={value} onChange={this.handleChange} isMulti={isMulti} placeholder={selecteText} />
                {/* select input end  */}

                {validate && <ErrorMessage message={validate} isSelect />}
            </div>
        );
    }

    handleChange = (selectedOption) => {
        const { isMulti, maxLength } = this.props;

        isMulti ? selectedOption.length <= maxLength && this.props.onFieldChange(this.props.name, selectedOption) : this.props.onFieldChange(this.props.name, selectedOption);
    };
}

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
});
export default connect(mapStateToProps, { setLanguage })(SelectInput);
