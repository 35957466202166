import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-elastic-carousel";
// import Product from "./Product";
import { connect } from "react-redux";
import Global from "../../language/Global.json";
import { setLanguage } from "../../redux/actions-reducers/language";
import { apiService } from "../../services/ApiService";
import Skeleton from "react-loading-skeleton";
import { phoneScreen, tabletScreen } from "../../utils/misc";
import { Link } from "react-router-dom";
// import Brands from "../Pages/Brands/Brands";

class TopBrands extends Component {
  constructor(props) {
    super(props);
    this.goto = this.goto.bind(this);
  }
  goto() {
    this.carousel.goTo(Number(0));
  }

  state = {
    breakPoints: [
      // { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
      // { width: 992, itemsToShow: 5 },
      { width: 1, itemsToShow: 2, showArrows: false, pagination: true },
      { width: 425, itemsToShow: 4, showArrows: false, pagination: true },
      { width: 576, itemsToShow: 5 },
      { width: 768, itemsToShow: 5 },
      { width: 992, itemsToShow: 6 },
      { width: 1200, itemsToShow: 6 },
    ],
    initialActiveIndex: 0,
  };

  render() {
    const {
      language,
      data,
      title,
      url,
      i18n: { VIEWALL, TopBrands },
    } = this.props;
    return (
      <div className="top-brands">
        <Container>
          <div
            className={`categories-block   ${
              language === "rtl" && "categories-block-arabic"
            }`}
          >
            <div className="categories-block__header">
              {/* title start */}
              <h3>{title ? title : TopBrands}</h3>
              {/* title end */}

              <div className="hr" />
              {/* view all link start  */}
              <Link to="/brands" className="view-all">
                <span>{VIEWALL}</span>
                <img src="/images/arrow-small-right.svg" alt="l" />
              </Link>
              {/* view all link end  */}
            </div>

            {/* top brands list start  */}
            {data?.length < 1 ? (
              <Row>
                <Col>
                  <Skeleton height={tabletScreen ? 100 : 200} />
                </Col>
                <Col>
                  <Skeleton height={tabletScreen ? 100 : 200} />
                </Col>
                <Col>
                  <Skeleton height={tabletScreen ? 100 : 200} />
                </Col>
                <Col>
                  <Skeleton height={tabletScreen ? 100 : 200} />
                </Col>
              </Row>
            ) : data?.length < 4 && !phoneScreen ? (
              <Row className="no-margin p-1">
                {data?.map(({ id, logo }) => {
                  return (
                    <Col key={id} lg={2} md={3} xs={4}>
                      <div className="item-brand" key={id}>
                        <img src={apiService.imageLink + logo} alt="l" />
                      </div>
                    </Col>
                  );
                })}
              </Row>
            ) : (
              <Carousel
                itemsToShow={6}
                pagination={false}
                ref={(ref) => (this.carousel = ref)}
                disableArrowsOnEnd={true}
                breakPoints={this.state.breakPoints}
                isRTL={language === "rtl" && true}
              >
                {data?.map(({ id, logo, machine_name }) => {
                  return (
                    <Link
                      to={`${url ? url : "/BrandProducts/"}${machine_name}/1`}
                      className="item-brand"
                      key={id}
                    >
                      <img src={apiService.imageLink + logo} alt="l" />
                    </Link>
                  );
                })}
              </Carousel>
            )}
            {/* top brands list end  */}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
});
export default connect(mapStateToProps, { setLanguage })(TopBrands);
