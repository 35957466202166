import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { apiService } from "../../../services/ApiService";
import Global from "../../../language/Global.json"
import { useSelector } from "react-redux";


function Menu(props) {
  const {langCode} = useSelector((state) => state.language)
  const lang  = langCode === "rtl"? "ar" : "en";


  return (
    <div className="my-menu">
      <Container fluid>
        <Row>
          {/* img in left1 */}
          <Col xs={2} md={2} lg={2} xl={2}>
              {/* {props?.ads?.categories_in_top_bar_right?.ad_image && (
                  <div
                  className="menu2__sales__col1"

                    onClick={() => {
                      if (props.ads?.categories_in_top_bar_right?.can_click) {
                        this.visitAdsLink(
                          props.ads?.categories_in_top_bar_right?.link,
                          props.ads?.categories_in_top_bar_right?.id
                        );
                      }
                    }}
                  >
                    <img
                    className="Images__Menu"
                      src={
                        props.ads?.categories_in_top_bar_right?.ad_image
                          ? apiService.imageLink +
                            props.ads?.categories_in_top_bar_right?.ad_image
                          : "/images/placeholder.png"
                      }
                      alt="l"
                    />
                  </div>
              )} */}
          </Col>

          {/* img in left2 */}
          <Col xs={2} md={2} lg={2} xl={2}>
              {props?.ads?.categories_in_top_bar_left?.ad_image && (
                
                  <div
                  className="menu2__sales__col1"

                    onClick={() => {
                      if (props?.ads?.categories_in_top_bar_left?.can_click) {
                        this.visitAdsLink(
                          props?.ads?.categories_in_top_bar_left?.link,
                          props?.ads?.categories_in_top_bar_left?.id
                        );
                      }
                    }}
                  >
                    <img
                    className="Images__Menu"
                      src={
                        props.ads?.categories_in_top_bar_left?.ad_image
                          ? apiService.imageLink +
                            props.ads?.categories_in_top_bar_left?.ad_image
                          : "/images/placeholder.png"
                      }
                      alt="l"
                    />
                  </div>
              )}
          </Col>

          {/* menu for Categories & Sub-Categories */}
          <Col xs={6} md={6} lg={6} xl={6}>
            <div>
              <Row>
                <Col xs={12}>
                  <div className="grid-container">
                    {props?.subcategories?.map(
                      ({ id, name, machine_name, subcategories }) => {
                        return (
                          <div className="grid-col" key={id}>
                            <a
                              className="title d-block"
                              href={"/ProductsCategory/" + machine_name + "/1"}
                            >
                              <span className="text-capitalize">{name}</span>
                            </a>
                            {subcategories?.length ? ((subcategories?.length < 11) ? (
                              subcategories.map(
                                ({ id, name, machine_name }) => {
                                  return (
                                    <a
                                      className="d-block title2"
                                      href={
                                        "/ProductsCategory/" +
                                        machine_name +
                                        "/1"
                                      }
                                    >
                                      <span className="text-capitalize">
                                        {name}
                                      </span>
                                    </a>
                                  );
                                }
                              )
                            ) : (
                              <>
                                {subcategories
                                  .slice(0, 9)
                                  .map(({ id, name, machine_name }) => {
                                    return (
                                      <a
                                        className="d-block"
                                        href={
                                          "/ProductsCategory/" +
                                          machine_name +
                                          "/1"
                                        }
                                      >
                                        <span className="text-capitalize">
                                          {name}
                                        </span>
                                      </a>
                                    );
                                  })}
                                <a
                                  className="d-block"
                                  href={
                                    "/ProductsCategory/" + machine_name + "/1"
                                  }
                                >
                                  <span className="text-capitalize">
                                  {Global[lang]?.more}
                                  </span>
                                </a>
                              </>
                            )): ""}
                          </div>
                        );
                      }
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>

          {/* img in Right */}
          <Col xs={2} md={2} lg={2} xl={2}>
              {props?.ads?.categories_in_top_bar_right?.ad_image && (
                  <div
                   className="menu2__sales__col1 border-start border-gray"
                    onClick={() => {
                      if (props.ads?.categories_in_top_bar_right?.can_click) {
                        this.visitAdsLink(
                          props.ads?.categories_in_top_bar_right?.link,
                          props.ads?.categories_in_top_bar_right?.id
                        );
                      }
                    }}
                  >
                    <img className="Images__Menu"
                      src={
                        props.ads?.categories_in_top_bar_right?.ad_image
                          ? apiService.imageLink +
                            props.ads?.categories_in_top_bar_right?.ad_image
                          : "/images/placeholder.png"
                      }
                      alt="l"
                    />
                  </div>
              )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Menu;
