import React, { useState } from "react";
import { Col, Divider, Row, Table } from "antd";
import { useSelector } from "react-redux";
import Global from "../../../language/Global.json";
import { apiService } from "../../../services/ApiService";
import moment from "moment";
import { table as tablebootstrap } from "react-bootstrap";
import { generalServices } from "../../../services/GeneralService";

const PrintInvoice = React.forwardRef((props, ref) => {
  const language = useSelector(({ language }) => language);
  const currentUser = useSelector(({ currentUser }) => currentUser);
  const country = useSelector(({ country }) => country);
  const i18n = language.langCode === "ltr" ? Global.en : Global.ar;
  const [settings, setSettings] = React.useState({});

  const getFooter = async () => {
    const { success, data } = await generalServices.getFooter();
    if (!success) return;
    setSettings(data);
  };

  React.useEffect(() => {
    getFooter();
  }, []);

  const {
    Orders,
    OrdersTitle2,
    DeliverTo,
    OrderNum,
    Name,
    SafeerCo,
    DownloadInvoice,
    Date,
    Email,
    Phone,
    DeliveredOn,
    Username,
    days,
    BillTo,
    BillFrom,
    Company,
    Status,
    Pic,
    feesAmount,
    ItemName,
    Quntity,
    UnitPrice,
    Total,
    Note,
    taxesAmount,
    Discount,
    ItemsAmount,
    PaymentMethod,
    Invoice,
    statementNumber,
    Time,
    OrderRef,
  } = i18n;

  const [responsiveTable, setResponsiveTable] = useState(false);

  window.addEventListener("resize", () => {
    if (window.innerWidth === 786) {
      setResponsiveTable(true);
    }
  });

  return (
    <div ref={ref} dir={language.langCode} className="arabic-align">
      <Row>
        <Divider>
          {OrderRef} &nbsp; : &nbsp;{" "}
          {props.data?.order_information?.order_reference}
        </Divider>
      </Row>

      <Row gutter={24} style={{ marginTop: 32 }}>
        <Col span={12}>
          <h3>{BillTo}</h3>
          <hr />
          <table>
            <tr>
              <td>
                <strong>{Name} : &nbsp;&nbsp;</strong>
              </td>
              <td> {currentUser.name}</td>
            </tr>
          </table>
          <table>
            <tr>
              <td className="table-field">
                <strong>{DeliverTo} : &nbsp;&nbsp;</strong>
              </td>
              <td className="ms-3">
                {props?.data?.order_information?.receiver_details?.address}
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td className="table-field">
                <strong>
                  <strong>{Phone} : &nbsp;&nbsp;</strong>
                </strong>
              </td>
              <td className="ms-3">
                {props.data?.order_information.receiver_details?.phone}
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td className="table-field">
                <strong>{Email} : &nbsp;&nbsp;</strong>
              </td>
              <td className="pl-5">
                {props.data?.order_information.receiver_details?.email}
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td className="table-field">
                <strong>{PaymentMethod} : &nbsp;&nbsp;</strong>
              </td>
              <td className="ms-3">
                {props.data?.order_information.payment_method?.name}
              </td>
            </tr>
          </table>
          <table>
            {props.data?.order_information.statement_number > 0 ? (
              <tr>
                <td className="table-field">
                  <strong>{statementNumber} : &nbsp;&nbsp;</strong>
                </td>
                <td className="ms-3">
                  {props.data?.order_information.statement_number}
                </td>
              </tr>
            ) : null}
          </table>
          <table>
            <tr>
              <td className="table-field">
                <strong>{Date} : &nbsp;&nbsp;</strong>
              </td>
              <td className="ms-3">
                {moment(props.data?.order_information?.created_at).format(
                  "yy-MM-DD"
                )}
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td className="table-field">
                <strong>{Time} : &nbsp;&nbsp;</strong>
              </td>
              <td className="ms-3">
                {moment(props.data?.order_information?.created_at)
                  .utcOffset(0)
                  .format("hh:mm:ss a")}
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td className="table-field">
                <strong>{Status} : &nbsp;&nbsp;</strong>
              </td>
              <td className="ms-3">
                <span
                  style={{
                    color: props.data.order_information?.statusColor,
                  }}
                >
                  <span className="m-0 p-0" style={{ display: "inline-block" }}>
                    {language.langCode === "ltr"
                      ? props.data.order_information?.statusText
                      : props.data.order_information?.statusText == "Pending"
                      ? "قيد الانتظار"
                      : props.data.order_information?.statusText == "Cancelled"
                      ? "ملغي"
                      : props.data.order_information?.statusText == "Accepted"
                      ? "تم القبول"
                      : props.data.order_information?.statusText == "On the Way"
                      ? "في الطريق"
                      : props.data.order_information?.statusText == "Arrived"
                      ? "تم التوصيل"
                      : props.data.order_information?.statusText == "Completed"
                      ? "مكتمل"
                      : null}
                  </span>
                  <i className=" fa fa-circle ps-3"></i>
                </span>
              </td>
            </tr>
          </table>
        </Col>
        <Col span={12}>
          <h3>{BillFrom}</h3>
          <hr />
          <table>
            <tr>
              <td className="table-field">
                <strong>{Company} : &nbsp;&nbsp;</strong>
              </td>
              <td className="ms-3">{settings.billing_company_name}</td>
            </tr>
          </table>
          <table>
            <tr>
              <td className="table-field">
                <strong>{Phone} : &nbsp;&nbsp;</strong>
              </td>
              <td className="ms-3 direction-class">
                {settings.billing_company_phone}
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td className="table-field">
                <strong>{Email} : &nbsp;&nbsp;</strong>
              </td>
              <td className="ms-3">{settings.billing_company_email}</td>
            </tr>
          </table>
        </Col>
      </Row>

      {/* Table */}
      <Row gutter={24} style={{ marginTop: 48 }}>
        <Col span={24}>
          <Table
            className="invoice-table"
            dataSource={props.data?.items.map(
              ({
                product,
                qty,
                unit_price,
                total_price,
                option_values,
                id,
              }) => ({
                id: id,
                Pic: (
                  <img
                    width={100}
                    height={100}
                    src={
                      product.main_image
                        ? apiService.imageLink + product.main_image
                        : "/images/placeholder.png"
                    }
                    alt=""
                  />
                ),
                name: (
                  <span>
                    {product.title}
                    <br />
                    <span className="invoice-option">
                      {option_values.join(",")}
                    </span>
                  </span>
                ),
                quantity: "X" + " " + qty,
                price: unit_price + "  " + country.currency,
                total: <strong>{total_price + "  " + country.currency}</strong>,
              })
            )}
            pagination={false}
          >
            <Table.Column title={Pic} dataIndex="Pic" />
            <Table.Column title={ItemName} dataIndex="name" />
            <Table.Column title={Quntity} dataIndex="quantity" />
            <Table.Column title={UnitPrice} dataIndex="price" />
            <Table.Column title={Total} dataIndex="total" />
          </Table>
        </Col>
      </Row>

      <Row gutter={24} style={{ marginTop: 48 }}>
        <Col span={8} offset={16}>
          <tablebootstrap>
            <tr>
              <td className="NoLeftBorder">
                <div>
                  {/* items_amount start  */}
                  {props.data?.order_information.items_amount > 0 && (
                    <h3>{ItemsAmount} : &nbsp;&nbsp;</h3>
                  )}
                  {/* items_amount end  */}

                  {/* discount_amount start  */}
                  {props.data?.order_information.discount_amount > 0 && (
                    <h3>{Discount} : </h3>
                  )}
                  {/* <h4>{Discount} "Code :A25sal"</h4> */}
                  {/* discount_amount end  */}

                  {/* taxes_amount start  */}
                  {props.data?.order_information.taxes_amount > 0 && (
                    <h3>{taxesAmount}: </h3>
                  )}
                  {/* taxes_amount end  */}

                  {/* feesAmount start  */}
                  {props.data?.order_information.fees_amount > 0 && (
                    <h3>{feesAmount}: </h3>
                  )}
                  {/* feesAmount start  */}
                </div>
              </td>
              <td>
                {/* total items_amount start  */}
                {props.data?.order_information.items_amount > 0 && (
                  <h4>
                    {props.data?.order_information.items_amount}{" "}
                    {country.currency}
                  </h4>
                )}
                {/* total items_amount end  */}

                {/* discount_amount start  */}
                {props.data?.order_information.discount_amount > 0 && (
                  <h4>
                    {props.data?.order_information.discount_amount}{" "}
                    {country.currency}
                  </h4>
                )}
                {/* discount_amount end  */}

                {/* taxes_amount start  */}
                {props.data?.order_information.taxes_amount > 0 && (
                  <h4>
                    {props.data?.order_information.taxes_amount}{" "}
                    {country.currency}
                  </h4>
                )}
                {/* taxes_amount end  */}

                {/* fees_amount start  */}
                {props.data?.order_information.fees_amount > 0 && (
                  <h4>
                    {props.data?.order_information.fees_amount}{" "}
                    {country.currency}
                  </h4>
                )}
                {/* fees_amount end  */}
              </td>
            </tr>
            <tr>
              <h3>
                <strong>{Total} :</strong>
                &nbsp;&nbsp;
              </h3>
              <td colSpan="3">
                <h3>
                  <strong>
                    {props.data?.order_information.total_amount}{" "}
                    {country.currency}
                  </strong>
                </h3>
              </td>
            </tr>
          </tablebootstrap>
        </Col>
      </Row>
    </div>
  );
});

export default PrintInvoice;
