import { apiEndPoints } from "../api";
import { handleResponse } from "../utils/misc";
import { apiService } from "./ApiService";

class UserService {
    get guestId() {
        return localStorage.getItem("guestId");
    }

    async checkoutSubmit(checkOutData) {
        try {
            const data = await apiService
                .authenticated()
                .post(apiEndPoints.user.checkoutSubmit, { ...checkOutData, guest_id: apiService.accessToken === 0 ? this.guestId : 0 })
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getUserOrders(page, ordersData) {
        try {
            const data = await apiService
                .authenticated()
                .post(`${apiEndPoints.user.getUserOrders}?page=${page}`, { guest_id: apiService.accessToken === 0 ? this.guestId : 0, ...ordersData })
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getOrderInvoice(id) {
        try {
            const data = await apiService
                .authenticated()
                .get(`${apiEndPoints.user.getOrderInvoice}?guest_id=${apiService.accessToken === 0 ? this.guestId : 0}&order_reference=${id}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getUserInformation() {
        try {
            const data = await apiService
                .authenticated()
                .get(apiEndPoints.user.getUserInformation)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async updateGeneralInfo(userGeneralData) {
        try {
            const data = await apiService
                .authenticated()
                .post(apiEndPoints.user.updateGeneralInfo, userGeneralData)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }
    
    async updateSecurityInfo(updateSecurityInfo) {
        try {
            const data = await apiService
                .authenticated()
                .post(apiEndPoints.user.updateSecurityInfo, updateSecurityInfo)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async submitCancelStatus( ordersData) {
        try {
            const data = await apiService
                .authenticated()
                .post(`${apiEndPoints.user.submitCancelStatus}`, ordersData)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }
}

export const userService = new UserService();
