import React, { Component } from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Global from "../../language/Global.json";
import { setLanguage } from "../../redux/actions-reducers/language";
import { apiService } from "../../services/ApiService";
import { generalServices } from "../../services/GeneralService";
import { homeService } from "../../services/homeService";
import Cart from "../Pages/Cart/Cart";
import Compare from "../Pages/ComparePage/Compare";
import Favourite from "../Pages/Favourite/Favourite";
import Header from "./Header";

class HeaderMobile extends Component {
  state = {
    showCategory: false,
    showMegaCategory: false,
    menuOpen: false,
    allCategory: [],
    topCategory: [],
    countries: [],
    topBrand: [],
    tab: "home",
    categoriesLoader: true,
    selectedCategory: {},
  };

  onLanguageChange = async (name, langCode) => {
    this.setState(
      {
        currentLang: name,
      },
      () => {
        localStorage.setItem("langCode", langCode);
        this.props.setLanguage({ lang: name, langCode }).then(() => {
          window.location.reload();
        });
      }
    );
  };

  componentDidMount() {
    Promise.all([
      this.getCountries(),
      this.getCategories(0, 0, 0, 1, 1),
      this.getCategories(0, 0, 1, 1, 1),
    ]);
  }

  getCountries = async () => {
    const { data, success } = await generalServices.getAllCountries(0);
    if (!success) return;
    this.setState({
      countries: data[0],
    });
  };

  getCategories = async (parentId, isFeatured, topBar, showBrands, showSub) => {
    const { data, success } = await generalServices.getCategories(
      parentId,
      isFeatured,
      topBar,
      showBrands,
      showSub,
      this.props.country.id
    );
    if (!success) return;
    let name = topBar === 0 ? "allCategory" : "topCategory";
    this.setState({
      [name]: data,
      categoriesLoader: false,
    });
    if (topBar === 0) {
      this.setState({
        selectedCategory: data[0],
      });
    }
  };

  render() {
    const {
      language,
      currentUser,
      i18n: {
        Profile,
        Language,
        MyOrders,
        MyLocations,
        Items,
        Logout,
        Lang,
        Login,
        Home,
        Categories,
        Favourite: favoriteText,
        Account,
        TopCategories,
        TodaysDeals,
        BrandStore,
        TopBrands,
        ComparePage,
        CartMobile,
      },
    } = this.props;
    const { tab, allCategory, categoriesLoader, countries, selectedCategory } =
      this.state;
    return (
      <>
        <div
          className={`mobile-header ${tab !== "home" ? "active" : ""}  ${
            language === "rtl" ? "mobile-header-arabic" : ""
          }`}
          style={
            tab === "home"
              ? null
              : {
                  width: "100%",
                }
          }
        >
          <div className="mobile-header__back-header"></div>
          <div
            className="mobile-header__contents "
            style={{ display: tab !== "home" ? "block" : "none" }}
          >
            {tab === "cart" ? (
              <div>
                <Cart />
              </div>
            ) : tab === "categories" ? (
              <div
                className={`header-mobile__categories  ${
                  language === "rtl" && "header-mobile__categories-arabic"
                }`}
              >
                <Row className="h-100 mh-100">
                  <Col xs={4} className="h-100 mh-100">
                    <Row>
                      <ul className="top-categories">
                        {allCategory.map((item, index) => {
                          return (
                            <li
                              key={index}
                              onClick={() =>
                                this.setState({ selectedCategory: item })
                              }
                              className={
                                selectedCategory?.id == item.id &&
                                "mobile__category-active m-0"
                              }
                            >
                              {item.name}
                            </li>
                          );
                        })}
                      </ul>
                    </Row>
                  </Col>

                  <Col
                    xs={8}
                    className="h-100 mh-100 mobile__categories-accordion-col pb-5"
                  >
                    <div className="mobile__categories-accordion mh-100 m-0 pb-5">
                      <h5>{selectedCategory?.name}</h5>

                      <div className="mobile__ads">
                        {selectedCategory?.ads?.categories_in_top_bar_left
                          ?.ad_image && (
                          <div
                            onClick={() => {
                              if (
                                selectedCategory?.ads
                                  ?.categories_in_top_bar_left?.can_click
                              ) {
                                this.visitAdsLink(
                                  selectedCategory?.ads
                                    ?.categories_in_top_bar_left?.link,
                                  selectedCategory?.ads
                                    ?.categories_in_top_bar_left?.id
                                );
                              }
                            }}
                          >
                            <img
                              src={
                                selectedCategory?.ads
                                  ?.categories_in_top_bar_left?.ad_image
                                  ? apiService.imageLink +
                                    selectedCategory?.ads
                                      ?.categories_in_top_bar_left?.ad_image
                                  : "/images/placeholder.png"
                              }
                              alt="l"
                            />
                          </div>
                        )}
                        {/* single ads end  */}

                        {/* single ads start  */}
                        {selectedCategory?.ads?.categories_in_top_bar_right
                          ?.ad_image && (
                          <div
                            onClick={() => {
                              if (
                                selectedCategory?.ads
                                  ?.categories_in_top_bar_right?.can_click
                              ) {
                                this.visitAdsLink(
                                  selectedCategory?.ads
                                    ?.categories_in_top_bar_right?.link,
                                  selectedCategory?.ads
                                    ?.categories_in_top_bar_right?.id
                                );
                              }
                            }}
                          >
                            <img
                              src={
                                selectedCategory?.ads
                                  ?.categories_in_top_bar_right?.ad_image
                                  ? apiService.imageLink +
                                    selectedCategory?.ads
                                      ?.categories_in_top_bar_right?.ad_image
                                  : "/images/placeholder.png"
                              }
                              alt="l"
                            />
                          </div>
                        )}
                        {/* single ads end  */}
                      </div>
                      {/* selected category ads  end  */}

                      {/* selected category brands  start  */}
                      {/* {selectedCategory?.brands?.length > 0 && (
                        <>
                          <h5>{TopBrands}</h5>

                          <div className="mobile__brands-list">
                            {selectedCategory?.brands?.map(
                              ({ logo, machine_name, name, id }) => {
                                return (
                                  <a
                                    key={id}
                                    href={
                                      "/BrandProducts/" + machine_name + "/1"
                                    }
                                    className="single-brand p-3"
                                  >
                                    <img
                                      src={apiService.imageLink + logo}
                                      alt="l"
                                    />
                                  </a>
                                );
                              }
                            )}
                          </div>
                        </>
                      )} */}
                      {this.renderCategories()}
                    </div>
                  </Col>
                </Row>
              </div>
            ) : tab === "favourite" ? (
              <Favourite />
            ) : tab === "compare" ? (
              <Compare />
            ) : (
              <div className="header-mobile__account">
                <ul style={{ marginTop: "30px" }}>
                  {/* MyOrders link start  */}
                  <li>
                    <a href="/orders/1" className="p-1">
                      <img
                        src="/images/boxDropdown.svg"
                        alt="k"
                        className="m-2"
                      />
                      <p className="m-0 d-inline-block">{MyOrders}</p>
                    </a>
                  </li>
                  {/* MyOrders link end  */}

                  {/* Profile link start  */}
                  {apiService.accessToken === 0 ? null : (
                    <>
                      <li>
                        <a href="/addresses" className="p-1">
                          <img
                            src="/images/locationDropdown.svg"
                            alt="j"
                            className="m-2"
                          />
                          <p className="m-0 d-inline-block">{MyLocations}</p>
                        </a>
                      </li>

                      <li>
                        <a href="/Profile" className="p-1">
                          <img
                            src="/images/userDropdown.svg"
                            alt="k"
                            className="m-2"
                          />
                          <p className="m-0 d-inline-block">{Profile}</p>
                        </a>
                      </li>
                    </>
                  )}
                  {/* Profile link start  */}

                  {/* ComparePage link start  */}
                  <li>
                    <a href="/Compare" className="p-1">
                      <img
                        src="/images/compareiconmobile.svg"
                        alt="k"
                        className="m-2"
                      />
                      <p className="m-0 d-inline-block">{ComparePage}</p>
                    </a>
                  </li>
                  {/* ComparePage link start  */}

                  {/* Language  start  */}
                  <li>
                    <Accordion
                      defaultActiveKey="0"
                      className=" language-mobile-header"
                    >
                      <Accordion.Header className="p-0">
                        <img
                          src="/images/global2.svg"
                          alt="k"
                          className="m-2"
                        />
                        {Language}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <ul>
                          {countries?.languages?.map(({ name, direction }) => {
                            return (
                              <li>
                                <a
                                  onClick={() =>
                                    this.onLanguageChange(name, direction)
                                  }
                                >
                                  {name}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </Accordion.Body>
                    </Accordion>
                  </li>
                  {/* Language  end  */}
                  {/* Logout  start  */}

                  {apiService.accessToken === 0 ? (
                    <li>
                      <a className="p-1" href="/login">
                        <img
                          src="/images/user (13).svg"
                          alt="k"
                          className="m-2"
                        />
                        <p className="m-0 d-inline-block">{Login}</p>
                      </a>
                    </li>
                  ) : (
                    <li>
                      <a
                        href="/login"
                        onClick={() => {
                          localStorage.removeItem("safeerAccessToken");
                          localStorage.removeItem("persist:Safeer");
                        }}
                        className="p-1"
                      >
                        <img
                          src="/images/logoutDropdown.svg"
                          alt="k"
                          className="m-2"
                        />
                        <p className="m-0 d-inline-block">{Logout}</p>
                      </a>
                    </li>
                  )}
                  {/* Logout  end  */}
                </ul>
              </div>
            )}
          </div>

          {/* bottom tabs nav   */}
          <nav className="mobile-header__tabs">
            <ul>
              <li>
                <a href="/">
                  <img src="/images/home (2).svg" alt="search" />
                  {Home}
                </a>
              </li>

              <li
                onClick={() => {
                  if (tab === "categories") {
                    this.setState({
                      tab: "home",
                    });
                  } else {
                    this.setState({
                      tab: "categories",
                    });
                  }
                }}
              >
                <span>
                  <img src="/images/catigoery.svg" alt="search" />
                  {Categories}
                </span>
              </li>
              <li
                onClick={() => {
                  if (tab === "cart") {
                    this.setState({
                      tab: "home",
                    });
                  } else {
                    this.setState({
                      tab: "cart",
                    });
                  }
                }}
              >
                <span>
                  <div className="img-container">
                    <img src="/images/cartsvg.svg" alt="search" />
                    {currentUser?.cart > 0 && (<span>{currentUser?.cart}</span>)}
                  </div>
                  {CartMobile}
                </span>
              </li>

              <li
                onClick={() => {
                  if (tab === "favourite") {
                    this.setState({
                      tab: "home",
                    });
                  } else {
                    this.setState({
                      tab: "favourite",
                    });
                  }
                }}
              >
                <span>
                  <div className="img-container">
                    {" "}
                    <img src="/images/heart-line.svg" alt="favourite" />
                    {currentUser?.fav > 0 && (<span>{currentUser?.fav}</span>)}
                  </div>
                  {favoriteText}
                </span>
              </li>

              <li
                onClick={() => {
                  if (tab === "account") {
                    this.setState({
                      tab: "home",
                    });
                  } else {
                    this.setState({
                      tab: "account",
                    });
                  }
                }}
              >
                <a>
                  <img src="/images/acountsvg.svg" alt="search" />
                  {Account}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </>
    );
  }
  closeMenu() {
    this.setState({ menuOpen: false });
  }
  toggleMenu() {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  }
  renderCategories() {
    const { selectedCategory } = this.state;
    const {
      language,
      i18n: { TopCategories, Items },
    } = this.props;

    let firstLevel = selectedCategory?.subcategories?.filter(
      ({ subcategories }) => subcategories.length > 0
    );
    let secondLevel = selectedCategory?.subcategories?.filter(
      ({ subcategories }) => subcategories.length <= 0
    );

    return (
      <div>
        {firstLevel?.length > 0 && (
          <Accordion defaultActiveKey="0">
            {selectedCategory?.subcategories
              ?.filter(({ subcategories }) => subcategories.length > 0)
              .map(
                (
                  {
                    name,
                    subcategories,
                    id,
                    machine_name,
                    brands,
                    items_count,
                    image,
                  },
                  index
                ) => {
                  if (subcategories?.length > 0) {
                    return (
                      <Accordion.Item key={index} eventKey={id}>
                        <Accordion.Header>{name}</Accordion.Header>
                        <Accordion.Body className="px-2">
                          <Row>
                            {subcategories.map(({ name, image, items_count, machine_name }) => {
                              return (
                                <Col xs={4} className="mb-3 px-1">
                                  <a
                                    href={
                                      "/productsCategory/" + machine_name + "/1"
                                    }
                                    className={`mobile-categories__single-item w-100  ${
                                      language === "rtl" &&
                                      "home-categories__single-item-arabic"
                                    }`}
                                  >
                                    <Row>
                                      <Col xs={12}>
                                        <img
                                          src={apiService.imageLink + image}
                                          alt="l"
                                          className="w-100 m-0"
                                        />
                                      </Col>
                                      <Col xs={12}>
                                        <p>{name}</p>
                                        <span>
                                          {items_count} {Items} oijopioppo
                                        </span>
                                      </Col>
                                    </Row>
                                  </a>
                                </Col>
                              );
                            })}
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  }
                }
              )}
          </Accordion>
        )}

        {secondLevel?.length > 0 && (
          <Row className="mt-3 ">
            {selectedCategory?.subcategories
              ?.filter(({ subcategories }) => subcategories.length <= 0)
              .map(
                (
                  {
                    name,
                    subcategories,
                    id,
                    machine_name,
                    brands,
                    items_count,
                    image,
                  },
                  index
                ) => {
                  if (subcategories?.length <= 0) {
                    return (
                      <Col xs={4} className="mb-3">
                        <a
                          href={"/productsCategory/" + machine_name + "/1"}
                          className={`mobile-categories__single-item  ${
                            language === "rtl" &&
                            "home-categories__single-item-arabic"
                          }`}
                        >
                          <Row>
                            <Col xs={12}>
                              <img
                                src={apiService.imageLink + image}
                                alt="l"
                                className="w-100 m-0"
                              />
                            </Col>
                            <Col xs={12}>
                              <p>{name}</p>
                              <span>
                                {items_count} {Items}
                              </span>
                            </Col>
                          </Row>
                        </a>
                      </Col>
                    );
                  }
                }
              )}
          </Row>
        )}
      </div>
    );
  }

  visitAdsLink = async (url, id) => {
    const { data, success } = await homeService.visitAdsLink(id);
    if (!success) return;
    window.open(url, "target");
  };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
  country,
});
export default connect(mapStateToProps, { setLanguage })(HeaderMobile);
