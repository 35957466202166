import React, { Component } from "react";
import { Accordion } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";

class SubCategories extends Component {
  render() {
    const {
      language,
      subCats,
      i18n: { subCategories, Orders },
    } = this.props;
    return (
      <>
        <div
          className={`subCategories d-none d-md-block ${
            language === "rtl" && "subCategories-arabic"
          }`}
        >
          <div className="subCategories__accordion">
            <Accordion
              accordion={true}
              defaultActiveKey={"1"}
              expandMode={"multiple"}
              flush
            >
              <Accordion.Item eventKey="1">
                <Accordion.Header>{subCategories}</Accordion.Header>
                <Accordion.Body>
                  <ul className="">
                    {/* subcategory list start  */}
                    {subCats?.map((sub) => {
                      return (
                        <a
                          href={"/productsCategory/" + sub.machine_name + "/1"}
                        >
                          <li>
                            {language === "rtl" ? (
                              <i class="fa fa-long-arrow-left ms-2"></i>
                            ) : (
                              <i class="fa fa-long-arrow-right me-2"></i>
                            )}

                            {sub.name}
                          </li>
                        </a>
                      );
                    })}
                    {/* subcategory list end  */}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <div className="d-md-none">
          <ul className="d-flex m-0 p-0 gap-2 overflow-auto">
            {/* subcategory list start  */}
            {subCats?.map((sub) => {
              return (
                <li className="mb-3">
                  <a
                    href={"/productsCategory/" + sub.machine_name + "/1"}
                    className="btn btn-light text-nowrap"
                    style={{ backgroundColor: "#eaeaea" }}
                  >
                    {sub.name}{" "}
                  </a>
                </li>
              );
            })}
            {/* subcategory list end  */}
          </ul>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
});

export default connect(mapStateToProps)(SubCategories);
