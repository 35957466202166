import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import Skeleton from "react-loading-skeleton";
import { productService } from "../../../services/ProductService";
import { apiService } from "../../../services/ApiService";
import { tabletScreen } from "../../../utils/misc";
import CompareBlock from "../productDetails/CompareBlock";

class Compare extends Component {
    state = {
        fullView: false,
        cart: [],
        favorites: [],
        payment_methods: [],
        itemsPriceBeforeDiscount: 0,
        itemsPriceAfterDiscount: 0,
        adsData: [
            {
                image: "/images/Group55.png",
                link: "/",
                lg: 12,
                md: 12,
                sm: 12,
                xs: 12,
            },
        ],
        compareLoader: true,
        loaderPage: true,
        CompareProducts: [],
    };

    componentDidMount() {
        Promise.all([this.getUserProductCompared()]);
    }

    getUserProductCompared = async () => {
        // this.setState({
        //     compareLoader: true,
        // });
        const { data, success } = await productService.getUserProductCompared();
        if (!success) return;

        const arr = [];
        data.map(({ title, main_image, id, rate, option, subtitle, in_favorite, machine_name, in_cart }) => {
            arr.push({
                Name: title,
                id,
                image: apiService.imageLink + main_image,
                Rate: (
                    <div className="compare-rate flex">
                        <ul className="flex">
                            {[...Array(rate).keys()].map(() => {
                                return (
                                    <li>
                                        <i className="fa fa-star"></i>
                                    </li>
                                );
                            })}
                        </ul>
                        <span>{rate}</span>
                    </div>
                ),
                Price: (
                    <span className="compare-price">
                        {option.price_after_discount} {this.props.country.currency}
                    </span>
                ),
                option,
                subtitle,
                in_cart,
                machine_name,
                in_favorite,
                Memory: "1 TB",
                Processor: "Intel core i5",
                Ram: "8 GB",
                Cpu_speed: "4.6 GHz",
                Display_size: '18"',
                Display_resolution: "5.1.7 LP",
                Item_dimensions: "14.01*10.01*0.7 inch",
            });
        });

        this.setState({
            CompareProducts: arr,
            compareLoader: false,
        });
    };

    render() {
        const { compareLoader, CompareProducts } = this.state;
        const {
            i18n: { Items, CompareProduct, emptyCompare },
        } = this.props;

        return (
            <div className="Cart ">
                {/** Title and items count start */}
                <div className="CartTitle">
                    <Container>
                        <Row>
                            <Col>
                                <h2>
                                    {CompareProduct}
                                    <h4>
                                        {CompareProducts.length} {Items}
                                    </h4>
                                </h2>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/** Title and items count end */}

                {/* compare products table Start */}
                <Container className="my-4">
                    {compareLoader ? (
                        <Skeleton height={tabletScreen ? 300 : 600} className="my-4" />
                    ) : CompareProducts.length < 1 ? (
                        <Container>
                            <Row>
                                <Col md={12} className="w-100 d-flex justify-content-center align-items-center">
                                    <div className="empty-products">
                                        <img src="/images/compare-01.svg" alt="k" />
                                        <p> {emptyCompare} </p>{" "}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    ) : (
                        <CompareBlock data={CompareProducts} features={["Rate", "Price"]} reFetchData={() => this.getUserProductCompared()} withOutHeader />
                    )}
                </Container>
                {/* compare products table end */}
            </div>
        );
    }
}

const mapStateToProps = ({ language, currentUser, country }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
    country,
});

export default connect(mapStateToProps, { setLanguage })(Compare);
