import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { tabletScreen } from "../../../utils/misc";
import HorizontalProduct from "../../blocks/HorizontalProduct";
import Product from "../../blocks/Product";
import Skeleton from "react-loading-skeleton";

class TopBrands extends Component {
    constructor(props) {
        super(props);
        this.goto = this.goto.bind(this);
    }
    goto() {
        this.carousel.goTo(Number(0));
    }

    state = {
        breakPoints: [
            // { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
            // { width: 992, itemsToShow: 5 },
            { width: 1, itemsToShow: 3, showArrows: false, pagination: true },
            { width: 425, itemsToShow: 4, showArrows: false, pagination: true },
            { width: 576, itemsToShow: 5 },
            { width: 768, itemsToShow: 5 },
            { width: 992, itemsToShow: 6 },
            { width: 1200, itemsToShow: 6 },
        ],
        initialActiveIndex: 0,
    };

    render() {
        const {
            language,
            data,
            reFetchData,
            i18n: { VIEWALL, DailyDeal },
        } = this.props;
        return (
            <Container>
                <div className={`categories-block dailyDeal ${language === "rtl" && "categories-block-arabic"}`}>
                    <div className="categories-block__header">
                        <h3>{DailyDeal}</h3>
                        <div className="hr" />
                        <a href={`/GeneralProduct/DailyDeals`} className="view-all">
                            <span>{VIEWALL}</span>
                            <img src="/images/arrow-small-right.svg" alt="l" />
                        </a>
                    </div>
                    {/* daily deal products start  */}
                    {data?.length < 1 ? (
                        <Row className="g-3">
                            <Col xs={6}>
                                <Skeleton height={tabletScreen ? 100 : 250} />
                            </Col>
                            <Col xs={6}>
                                <Skeleton height={tabletScreen ? 100 : 250} />
                            </Col>
                            <Col>
                                <Skeleton height={tabletScreen ? 100 : 250} />
                            </Col>
                            <Col>
                                <Skeleton height={tabletScreen ? 100 : 250} />
                            </Col>
                            <Col>
                                <Skeleton height={tabletScreen ? 100 : 250} />
                            </Col>
                            <Col>
                                <Skeleton height={tabletScreen ? 100 : 250} />
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            {data?.map((item, index) => {
                                return index < 2 ? (
                                    <Col md={6}>
                                        <HorizontalProduct data={item} reFetchData={() => reFetchData()} />
                                    </Col>
                                ) : (
                                    index < 8 && (
                                        <Col lg={2} md={3} sm={4} xs={6}>
                                            <Product data={item} reFetchData={() => reFetchData()} />
                                        </Col>
                                    )
                                );
                            })}
                        </Row>
                    )}
                    {/* daily deal products end  */}
                </div>
            </Container>
        );
    }
}

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
});
export default connect(mapStateToProps, { setLanguage })(TopBrands);
